
import { getNeo4JUrl, getNeo4JToken } from "./Configuration";

function uploadModel(name, fsmType, fsmfile, statefile, infile, outfile, csvfile, isSfsm, setModel) {
    if ( fsmType ) {
        const jsonPackage = {
            "statements": [
                {
                    "statement": "CREATE(m:Model $props) RETURN m;",
                    "parameters": {
                        "props": {
                            "name": name,
                            "isSfsm": isSfsm,
                            "csv": "",
                            "fsm": fsmfile,
                            "state": statefile,
                            "in": infile,
                            "out": outfile
                        }
                    }
                }
            ]
        }
    
        fetch(getNeo4JUrl(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + getNeo4JToken()
            },
            body: JSON.stringify(jsonPackage)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error('While uploading model-file');
            }
        }).then(data => {
            return data;
        })
        .then((content) => {
            setModel(name, "Model upload success.");
            fetch(content["commit"], {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + getNeo4JToken()
                },
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error("During commit");
                }
            });
        })
        .catch(error => {
            setModel("Error", error);
        });

    }
    else {
        const jsonPackage = {
            "statements": [
                {
                    "statement": "CREATE(m:Model $props) RETURN m;",
                    "parameters": {
                        "props": {
                            "name": name,
                            "isSfsm": isSfsm,
                            "csv": csvfile,
                            "fsm": "",
                            "state": "",
                            "in": "",
                            "out": ""
                        }
                    }
                }
            ]
        }
    
        fetch(getNeo4JUrl(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + getNeo4JToken()
            },
            body: JSON.stringify(jsonPackage)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error('While uploading model-file');
            }
        }).then(data => {
            return data;
        })
        .then((content) => {
            setModel(name, "Model upload success.");
            fetch(content["commit"], {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + getNeo4JToken()
                },
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error('During commit');
                }
            });
        })
        .catch(error => {
            setModel("Error", error);
        });
    }
}

function sendHarnessAsBase64(base64Content, setHarness, harnessName, addLog) {
    const jsonPackage = {
        "statements": [
            {
                "statement": "CREATE(h:Harness $props) RETURN h;",
                "parameters": {
                    "props": {
                        "name": harnessName,
                        "content": base64Content.replace("data:application/octet-stream;base64,", "") // remove header
                    }
                }
            }
        ]
    }

    fetch(getNeo4JUrl(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + getNeo4JToken()
        },
        body: JSON.stringify(jsonPackage)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        }
        else {
            throw new Error('While uploading model-file');
        }
    }).then(data => {
        return data;
    })
    .then((content) => {
        setHarness(harnessName);
        addLog("Harness upload success.");
        fetch(content["commit"], {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + getNeo4JToken()
            },
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error("During commit");
            }
        });
    })
    .catch(error => {
        setHarness("Error");
        addLog(error);
    });
}


function uploadFaultDomainTextFile(fileContent, name, setFaultDomain, addLog) {
    const jsonPackage = {
        "statements": [
            {
                "statement": "CREATE(h:FaultDomain $props) RETURN h;",
                "parameters": {
                    "props": {
                        "name": name,
                        "content": fileContent
                    }
                }
            }
        ]
    }

    fetch(getNeo4JUrl(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + getNeo4JToken()
        },
        body: JSON.stringify(jsonPackage)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        }
        else {
            throw new Error('While uploading model-file');
        }
    }).then(data => {
        return data;
    })
    .then((content) => {
        setFaultDomain(name);
        addLog("Fault Domain upload success.")
        fetch(content["commit"], {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + getNeo4JToken()
            },
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error("During commit");
            }
        });
    })
    .catch(error => {
        setFaultDomain("Error");
        addLog(error)
    });
}


function getBase64(file, setHarness, harnessName, callback, addLog) {
    // A new file reader to read the file
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = function (error) {
        setHarness("error " + error);
    };
    reader.addEventListener("loadend", () => {
        callback(reader.result, setHarness, harnessName, addLog);
    })
}

function parseTextFile(file, setFaultDomain, faultDomainName, callback, addLog) {
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onerror = function (error) {
        setFaultDomain("error " + error);
    };
    reader.addEventListener("loadend", () => {
        callback(reader.result, faultDomainName, setFaultDomain, addLog);
    })
}

/**
 * Upload a harness binary.
 */
function uploadHarness(name, harnessInputRef, setHarness, addLog) {
    /*
     * Short explaination:
     * First, we create a base64-string from the binary file to 
     * handle data via HTTP. After that, we process this file and
     * sends it to the storage.
     */
    var data = new FormData(harnessInputRef.current);
    getBase64(data.get('uploadFile'), setHarness, name, sendHarnessAsBase64, addLog);
}

/**
 * Upload a harness binary.
 */
function uploadFaultDomain(name, faultDomainInputRef, setPotFaultDomain, addLog) {
    var data = new FormData(faultDomainInputRef.current);
    parseTextFile(data.get("faultDomain"), setPotFaultDomain, name, uploadFaultDomainTextFile, addLog);
}


function uploadFormula(name, content, setFormulaCallback) {
    const jsonPackage = {
        "statements": [
            {
                "statement": "CREATE(f:Formula $props) RETURN f;",
                "parameters": {
                    "props": {
                        "name": name,
                        "content": content,
                    }
                }
            }
        ]
    }

    fetch(getNeo4JUrl(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + getNeo4JToken()
        },
        body: JSON.stringify(jsonPackage)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        }
        else {
            throw new Error('Error durcing fetch.');
        }
    }).then(data => {
        return data;
    })
    .then((content) => {
        setFormulaCallback(name, "Successfully uploaded the Formula File");
        fetch(content["commit"], {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + getNeo4JToken()
            },
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error('While uploading formula file.');
            }
        });
    })
    .catch(error => {
        setFormulaCallback("Error", error);
    });
}


function uploadProperty(name, content, setPropertyCallback) {
    const jsonPackage = {
        "statements": [
            {
                "statement": "CREATE(f:Property $props) RETURN f;",
                "parameters": {
                    "props": {
                        "name": name,
                        "content": content,
                    }
                }
            }
        ]
    }

    fetch(getNeo4JUrl(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + getNeo4JToken()
        },
        body: JSON.stringify(jsonPackage)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        }
        else {
            throw new Error('Error durcing fetch.');
        }
    }).then(data => {
        return data;
    })
    .then((content) => {
        setPropertyCallback(name, "Successfully uploaded the property file");
        fetch(content["commit"], {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + getNeo4JToken()
            },
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error('While uploading property file.');
            }
        });
    })
    .catch(error => {
        setPropertyCallback("Error", error);
    });
}

// Export functions to other modules
export { uploadModel, uploadHarness, uploadFaultDomain, uploadFormula, uploadProperty }