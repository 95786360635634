import './ContentPage.css';
import React from "react"
import Row from './Components/Row';
import Column from './Components/Column';
import Container from './Components/Container';
import TabPage from './TabPage';
import Footer from './Footer';

/**
 * This Page contains the content underneath the navigation
 * bar.
 */
const ContentPage = () => {
    return (
        <div className="ContentPage">
            <Container className='container-fluit'>
                <Row>
                    <Column className='col-1'>
                        <img src="unilogo.jpg" width="100px" alt="Uni HB Logo"/>
                    </Column>
                    <Column className='col-md-11'>
                        <h2>AGBS Test Cloud</h2>
                    </Column>
                </Row>
                <Row>
                    <TabPage id="MainTabPage"></TabPage>
                </Row>
                <Footer>
                    
                </Footer>
            </Container>
        </div>
    );
};

export default ContentPage;