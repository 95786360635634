import React, { useState } from "react";
import Row from "../Components/Row";
import FileUploadColumn from "../Components/FileUploadColumn"
import TabNavBar from "../Components/TabNavBar";
import TabButton from "../Components/TabButton";
import TabContent from "../Components/TabContent";
import TabPane from "../Components/TabPane";
import Card from "../Components/Card";
import Column from "../Components/Column";
import Label from "../Components/Label";
import Button from "../Components/Button";
import { uploadHarness, uploadFaultDomain } from "../Actions/FileUpload";
import { getDateString } from "../Actions/Utils";

/**
 * @brief
 *  This is the render function for the File upload page for the 
 *  POT Page
 */
function POTFileUploadPage({ showNoFaultDomainModal, showPotWrongFileFormatModal, setPropertyFile, showNoHarnessModal, setHarness, setPotFaultDomain, addLog }) {

    // Reference to the selected harness binary
    const harnessInputRef = React.createRef();

    // Reference to the selected fault domain
    const faultDomainInputRef = React.createRef();

    // This is the state of the current page
    const [state, setState] = useState({
        tab: 0, // 1: Model, 2: Harness, 3: Examples
        selectedFaultDomain: "",
        selectedHarness: "",
        exampleModel: "",
        exampleHarness: "",
        exampleProperty: ""
    });

    /*
     * Update the current tab num in class state
     * to `tabNum` 
     */
    function updateTabStateTo(tabNum) {
        setState({
            ...state,
            tab: tabNum
        })
    }
    
   
    // -------------------------------------------------------------------------------------
    // Upload
    // -------------------------------------------------------------------------------------
    /**
     * Button callback to upload the model.
     */
    function onClickUploadFaultDomain() {

        if ( state.selectedFaultDomain === "" ) {
            showNoFaultDomainModal();
            return;
        }

        uploadFaultDomain(state.selectedFaultDomain, faultDomainInputRef, setPotFaultDomain, addLog);
    }

    /**
     * Callback to upload the harness binary
     */
    function onClickUploadHarness() {

        if ( state.selectedHarness === "" ) {
            showNoHarnessModal();
        }
        
        uploadHarness(state.selectedHarness , harnessInputRef, setHarness, addLog);
    }

    // -------------------------------------------------------------------------------------
    // change handler
    // -------------------------------------------------------------------------------------

    /**
     * Change handler for selecting harness
     */
    function onChangeSelectHarness(event) {
        let file = event.target.files[0];
        var uniqueName = file.name + "_" + getDateString();
        uniqueName = uniqueName.replaceAll('/', '-').replaceAll(',', '').replaceAll(' ', '_').replaceAll(':', '-').replaceAll('\n', '');

        setState({
            ...state,
            selectedHarness: uniqueName
        })
    }

    /**
     * Change handler for selecting a model folder
     */
     function onChangeSelectFaultDomain(event) {
        let file = event.target.files[0];
        if ( ! file.name.includes(".txt") ) {
            showPotWrongFileFormatModal();
            return;
        }
        var uniqueName = "FD_" + getDateString() + "_" + file.name;
        uniqueName = uniqueName.replaceAll('/', '-').replaceAll(',', '').replaceAll(' ', '_').replaceAll(':', '-').replaceAll('\n', '').replaceAll(".txt", "");

        setState({
            ...state,
            selectedFaultDomain: uniqueName
        })
    }


    // -------------------------------------------------------------------------------------
    // Select Example functions
    // -------------------------------------------------------------------------------------
    /**
     * Update the example Harness selected 
     */
    function updateExampleHarnessTo(selectedExampleHarness) {
        setState({
            ...state,
            exampleHarness: selectedExampleHarness
        })

        console.log("Set Example harness: " + selectedExampleHarness);

        setHarness(selectedExampleHarness)
    }

    function updateExamplePropertyTo(selectedExampleProperty) {
        setState({
            ...state,
            exampleProperty: selectedExampleProperty
        })

        console.log("Set Example property: " + selectedExampleProperty);

        //setHarness(selectedExampleHarness)
        setPropertyFile(selectedExampleProperty, "Using property '" + selectedExampleProperty +"'");
    }

    /*
     * Update the example model selected 
     */
    function updateExampleFaultDomainTo(selectedExampleFaultDomain) {
        setState({
            ...state,
            exampleFaultDomain: selectedExampleFaultDomain
        })

        console.log("Set Example Fault Domain: " + selectedExampleFaultDomain);

        setState({
            ...state,
            selectedFaultDomain: selectedExampleFaultDomain
        })

        setPotFaultDomain(selectedExampleFaultDomain);

        addLog("Using fault domain '" + selectedExampleFaultDomain + "'");
    }

    // -------------------------------------------------------------------------------------
    // Render function
    // -------------------------------------------------------------------------------------
    return (
        <> 
            <Row>
                <FileUploadColumn>
                    <h4>File Upload</h4>
                </FileUploadColumn>
            </Row>
            <Row>
                <FileUploadColumn>
                    <TabNavBar className="nav nav-tabs" id="pot-nav-tab-files">
                        <TabButton
                            className="nav-link active"
                            id="nav-fault-domain-tab"
                            target="#fault-domain-tab-pane"
                            ariaControl="fault-domain-tab-pane"
                            onClick={ () => updateTabStateTo(1) }>
                            FD
                        </TabButton>
                        {/* Use here system-wide unique names for target! */ }
                        <TabButton
                            className="nav-link"
                            id="nav-harness-tab"
                            target="#pot-harness-tab-pane"
                            ariaControl="pot-harness-tab-pane"
                            onClick={ () => updateTabStateTo(2) }>
                            Harness
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="nav-example-tab"
                            target="#pot-examples-tab-pane"
                            ariaControl="pot-examples-tab-pane"
                            onClick={ () => updateTabStateTo(3) }>
                            Examples
                        </TabButton>
                    </TabNavBar>

                    <TabContent id="rightTabContent">
                        <TabPane id="fault-domain-tab-pane" className="tab-pane active">
                            <Card headerName="Fault-Domain">
                                {/* This is the list for selecting input folder */}
                                <Row>
                                    <form id="uploadFormFaultDomain" encType="text/plain" ref={faultDomainInputRef} >
                                        <input 
                                            type="file"
                                            name="faultDomain"
                                            className="form-control fault-domain-file-select" 
                                            id="file-input-fault-domain-files" 
                                            onChange={ onChangeSelectFaultDomain }/>
                                    </form> 
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Label className="fault-domain-name-header">Using fault domain:</Label><br></br>
                                        <Label className="fault-domain-name">{ state.selectedFaultDomain }</Label>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Button
                                            id="UploadFaultDomainBtn"
                                            bootstrapClass="btn btn-primary bg-gradient"
                                            onClick={() => onClickUploadFaultDomain() }>
                                            Upload Fault Domain
                                        </Button>
                                    </Column>
                                </Row>
                            </Card>
                        </TabPane>

                        { /* Harness upload Tab */ }
                        <TabPane id="pot-harness-tab-pane" className="tab-pane">
                            <Card headerName="Upload Harness Binary">
                                <Row>
                                    <form id="uploadFormFsm"  
                                          encType="multipart/form-data"
                                          ref={ harnessInputRef }>
                                        <input 
                                            className="form-control harness-file-select"
                                            type="file" 
                                            name="uploadFile" 
                                            onChange={ onChangeSelectHarness }
                                            id="uploadFile"></input>
                                    </form>
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Label className="harness-file-name-header">Using harness:</Label><br></br>
                                        <Label className="harness-file-name">{ state.selectedHarness }</Label>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Button 
                                            bootstrapClass="btn btn-primary btn-gradient"
                                            id="uploadHarnessFaultDomain"
                                            onClick={() => onClickUploadHarness() }>
                                            Upload Harness
                                        </Button>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Button
                                            bootstrapClass="btn btn-primary btn-gradient get-harness-builder"
                                            onClick={ () => window.open('https://hub.docker.com/r/agbstestcloud/harness-builder', '_blank')}
                                            id="FsmGetHarnessBuilder">
                                            Get Harness-Builder
                                        </Button>
                                    </Column>
                                </Row>
                            </Card>
                        </TabPane>

                        { /* Examples Tab */ }
                        <TabPane id="pot-examples-tab-pane" className="tab-pane">

                            {/* Example Fault Domains */}
                            <Card headerName="Example Fault Domains">
                                <Row>
                                    <Column className="col-12">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Use</th>
                                                    <th scope="col">Download</th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>ABS Fault Domain</td>
                                                    <td>
                                                        <Button
                                                            bootstrapClass="btn btn-success bg-gradient"
                                                            id="btnUseExampleFaultDomain"
                                                            onClick={() => updateExampleFaultDomainTo("faultDomainAbs") }>
                                                            Use
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn btn-primary bg-gradient" 
                                                            type="button" 
                                                            href="resources/abs-faultdomain.zip">
                                                            Download
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Column>
                                </Row>
                            </Card>

                            {/* Example Harness */}
                            <Card headerName="Example Harness">
                                <Row>
                                    <Column className="col-12">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Use</th>
                                                    <th scope="col">Download</th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>ABS-Harness</td>
                                                    <td>
                                                        <Button 
                                                            id="btnUseExampleHarnessFsm" 
                                                            onClick={() => updateExampleHarnessTo("abs-harness") } 
                                                            bootstrapClass="btn btn-success bg-gradient">
                                                            Use
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn btn-primary bg-gradient" 
                                                            type="button" 
                                                            href="resources/ABS.zip">
                                                            Download
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Column>   
                                </Row>
                            </Card>
                            {/* Example proeprty */}
                            <Card headerName="Example Property">
                                <Row>
                                    <Column className="col-12">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Use</th>
                                                    <th scope="col">Download</th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>ABS-Property</td>
                                                    <td>
                                                        <Button 
                                                            id="btnUseExamplePropertyPot" 
                                                            onClick={() => updateExamplePropertyTo("abs-property") } 
                                                            bootstrapClass="btn btn-success bg-gradient">
                                                            Use
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn btn-primary bg-gradient" 
                                                            type="button" 
                                                            href="resources/property.zip">
                                                            Download
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Column>   
                                </Row>
                            </Card>
                        </TabPane>
                    </TabContent>
                </FileUploadColumn>
            </Row>
        </>
    );
}

export default POTFileUploadPage;
