import React from "react";

const SelectOption = ({ id, value, children }) => {
    return (
        <option id={ id } value={ value }>
            { children }
        </option>
    );
}

export default SelectOption;