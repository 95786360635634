import React from "react";

const Select = ({ id, children, value, onChange, className}) => {
    return (
        <select 
            className={ className } 
            id={ id }
            onChange={ onChange }
            value={ value }>
            { children }
        </select>
    );
}

export default Select;