/**
 * Check if a model with a name already exists in
 * the array 'arr'.
 */
function modelWithNameExists(arr, name) {
    for ( let i = 0; i < arr.length; i++ ) {
        if ( arr[i].name === name ) {
            return true;
        }
    }

    return false;
}

/**
 * Check if parsed CSV File is in SFSM Format
 * if these keywords are included.
 */
function isSFSMCsvFile(content) {
    return (content.includes('identifiers') && content.includes('init'));
}

/**
 * Check if a file with a suffix exists in the 
 * array 'arr'
 */
function fileWithSuffixExists(arr, filename) {
    for ( let i = 0; i < arr.length; i++ ) {
        if ( arr[i].name === filename ) {
            return true;
        }
    }

    return false;
}

/**
 * Check, if the file with the name 'filename' is a
 * CSV File.
 */
function isCsvFile(filename) {
    var splittedName = filename.split('.');
    var len = splittedName.length;

    return splittedName.length > 1 && 
        splittedName[len - 1] != null && 
        ["csv"].includes(splittedName[len - 1]);
}

/**
 * Check, if the file 'filename' is 
 * part of the FSM Format
 */
function isPartOfFsmFile(filename) {
    var splittedName = filename.split('.');
    var len = splittedName.length;

    return splittedName.length > 1 && 
        splittedName[len - 1] != null && 
        ["fsm", "state", "in", "out"].includes(splittedName[len - 1]);
}

/**
 * Returns the name of the file without
 * suffix.
 */
function getNameOfFile(filename) {
    var splittedName = filename.split('.');
    var len = splittedName.length;
    
    if ( len < 1 ) {
        return "";
    }
        
    if ( len === 1 ) {
        return splittedName[0];
    }

    return splittedName[0];
}

/**
 * Get suffix of filename
 */
function getSuffixOfFile(filename) {
    var splittedName = filename.split('.');
    var len = splittedName.length;
        
    if ( len < 1 ) {
        return "";
    }
        
    if ( len === 1 ) {
        return splittedName[0];
    }

    return splittedName[len - 1];
}

export { modelWithNameExists, isSFSMCsvFile, fileWithSuffixExists, isCsvFile, isPartOfFsmFile, getNameOfFile, getSuffixOfFile }
