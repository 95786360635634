import React from "react"; 

const TabPane = ({ className, id, children }) => {
    return (
        <div id={ id } className={ className }>
            { children }
        </div>
    );
};


export default TabPane;