
import { getFsmTcgenBaseUri, getSFsmTcgenBaseUri, getHarnessExeBaseUri, getSfsmModelToSvgBaseUri, getFsmModelToSvgBaseUri, getPotUrl } from "./Configuration"

async function GetServerStatus(fsmLogReference) {

    // FSM TC-Generation Service
    await fetch(getFsmTcgenBaseUri() + 'service-status', {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        fsmLogReference.current.addLog(data.message);
    })
    .catch(error => {
        fsmLogReference.current.addLog("Error: Test-Cloud FSM-Test-Case-Generator Service is not available!");
    });

    // SFSM TC-Generation Service
    await fetch(getSFsmTcgenBaseUri() + 'service-status', {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        fsmLogReference.current.addLog(data.message);
    })
    .catch(error => {
        fsmLogReference.current.addLog("Error: Test-Cloud SFSM-Test-Case-Generator Service is not available!");
    });

    // Harness-Execution Service
    await fetch(getHarnessExeBaseUri() + 'service-status', {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        // if data received, do what
        fsmLogReference.current.addLog(data.message);
    })
    .catch(error => {
        fsmLogReference.current.addLog("Error: Test-Cloud Harness-Executor-Service is not available!");
    });

    // SFSM Model2SVG Service
    await fetch(getSfsmModelToSvgBaseUri() + 'service-status', {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        // if data received, do what
        fsmLogReference.current.addLog(data.message);
    })
    .catch(error => {
        fsmLogReference.current.addLog("Error: Test-Cloud SFSM Model-to-SVG Service is not available!");
    });

    // FSM Model2SVG Service
    await fetch(getFsmModelToSvgBaseUri() + 'service-status', {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        // if data received, do what
        fsmLogReference.current.addLog(data.message);
    })
    .catch(error => {
        fsmLogReference.current.addLog("Error: Test-Cloud FSM Model-to-SVG Service is not available!");
    });

    // FSM POT-TEsting Service
    await fetch(getPotUrl() + 'service-status', {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        // if data received, do what
        fsmLogReference.current.addLog(data.message);
    })
    .catch(error => {
        fsmLogReference.current.addLog("Error: Test-Cloud Property-Oriented Testing Service is not available!");
    });
}

export { GetServerStatus }