import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { getNeo4JToken, getNeo4JUrl } from "../Actions/Configuration";
import Column from "./Column";
import Row from "./Row";

const TestCloudModal = ({ text, show, title, onClose }) => {
    return (
        <Modal show={ show } onHide={ onClose }>
            <Modal.Header closeButton>
            <Modal.Title>{ title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>{ text }</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={ onClose }>
                Ok
            </Button>
            </Modal.Footer>
        </Modal>
    );
}

/**
 * The Modal 'class' for showing a file in
 * Test Management Tab
 */
function ShowFileModal({ name, ObjectClassName, fieldName, show, onClose }) {

    /**
     * State of the Modal.
     */
    const [state, setState] = React.useState({
        fileContent: "",
        fsmFileContent: "",
        stateFileContent: "",
        inFileContent: "",
        outFileContent: "",
        dataReceived: false,
        isFsmModel: false
    });

    function fetchData() {
        state.fileContent = "";
        state.isFsmModel = false;
        
        // The request Package
        const jsonPackage = {
            "statements": [
                {
                    "statement": "MATCH(x:" + ObjectClassName + ") where x.name = \"" + name + "\" RETURN x;",
                    "parameters": {
                        "props": {
                        }
                    }
                }
            ]
        }

        // Fetch Data
        fetch(getNeo4JUrl(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + getNeo4JToken()
            },
            body: JSON.stringify(jsonPackage)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error('While fetching data.');
            }
        }).then(data => {
            return data;
        })
        .then((content) => {
            fetch(content["commit"], {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + getNeo4JToken()
                },
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error("During commit");
                }
            });
            
            content["results"].forEach((result) => {
                if ( ! result["data"].length ) {
                    state.fileContent = "Error, file not found";
                    setState({
                        ...state,
                        dataReceived: true
                    })

                    return;
                }
                result["data"].forEach((element) => {
                    if ( ! element["row"].length ) {
                        state.fileContent = "Error, file not found";
                        setState({
                            ...state,
                            dataReceived: true
                        })

                        return;
                    }

                    element["row"].forEach((object) => {
                        if ( fieldName === "csv" && object[fieldName] === "" ) {
                            state.fsmFileContent = object["fsm"];
                            state.stateFileContent = object["state"];
                            state.inFileContent = object["in"];
                            state.outFileContent = object["out"];
                            state.isFsmModel = true;
                        }
                        else {
                            state.fileContent = object[fieldName];
                        }
                        
                        setState({
                            ...state,
                            dataReceived: true
                        })
                    })
                })
            })
        })
        .catch(error => {
            setState({
                text: error,
                dataReceived: true
            })
        });
    }

    /**
     * Use effect for showing modal: When modal is 
     * displayed, fetch data
     */
    useEffect(() => {
        if ( show ) {
            fetchData();
        }
        else {
            setState({
                ...state,
                dataReceived: false
            })
        }
    }, [show]);  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Render the form.
     */
    return (
        <Modal show={ show } 
               onHide={ onClose }
               dialogClassName="show-file-modal">
            <Modal.Header closeButton>
            <Modal.Title>
                File View
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { ! state.dataReceived && 
                    (<>
                        <p>Loading file ... </p>
                        <Spinner className="test-cloud-spinner" animation={ "border" } size="lg" variant="primary" role="status" />
                    </>)
                }
                { state.dataReceived && ! state.isFsmModel &&
                    (<>
                        <Column>
                            <h5>{ name }.{ (fieldName === "csv") ? "csv" : "txt"}</h5>
                            <textarea readOnly className="form-control" rows="20" defaultValue={ String(state.fileContent) } />
                        </Column>
                    </>)
                }

                { state.dataReceived && state.isFsmModel &&
                    (<>
                        <Row>
                            <Column>
                                <h5>{ name }.fsm</h5>
                                <textarea readOnly className="form-control" rows="20" defaultValue={ String(state.fsmFileContent) } />
                            </Column>
                            <Column>
                                <h5>{ name }.state</h5>
                                <textarea readOnly className="form-control" rows="20" defaultValue={ String(state.stateFileContent) } />
                            </Column>
                            <Column>
                                <h5>{ name }.in</h5>
                                <textarea readOnly className="form-control" rows="20" defaultValue={ String(state.inFileContent) } />
                            </Column>
                            <Column>
                                <h5>{ name }.out</h5>
                                <textarea readOnly className="form-control" rows="20" defaultValue={ String(state.outFileContent) } />
                            </Column>
                        </Row>
                    </>)
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={ onClose }>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export { TestCloudModal, ShowFileModal };