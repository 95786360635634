import React, { useContext } from "react";
import Row from "../Components/Row";
import Card from "../Components/Card";
import Label from "../Components/Label";
import ParameterColumn from "../Components/ParameterColumn";
import { GlobalContext } from "../GlobalContext";

function SFSMMbtParametersPage() {
    const globalContext = useContext(GlobalContext);

    /**
     * Return page state in JSX syntax
     */
    return (
        <>
            <Row>
                <ParameterColumn>
                    <h4>SFSM-Parameters</h4>
                </ParameterColumn>
            </Row>
            <Row>
                <ParameterColumn>
                    <Card headerName="Using Model">
                        <Label id="currentUsingModelSFsm" className="ParameterLabel"> 
                            { globalContext.sfsmUploadedModel ? globalContext.sfsmUploadedModel : "No model selected."} 
                        </Label>
                    </Card>
                </ParameterColumn>
            </Row>
            <Row>
                <ParameterColumn>
                    <Card headerName="Using Test Suite">
                        <Label id="currentUsingTestSuiteSFsm" className="ParameterLabel"> 
                        { globalContext.sfsmTestSuiteName ? globalContext.sfsmTestSuiteName : "No test-suite available."} 
                        </Label>
                    </Card>
                </ParameterColumn>
            </Row>
            <Row>
                <ParameterColumn>
                    <Card headerName="Using Harness">
                        <Label id="currentUsingHarnessSFsm" className="ParameterLabel"> 
                            { globalContext.sfsmHarness ? globalContext.sfsmHarness : "No harness uploaded."} 
                        </Label>
                    </Card>
                </ParameterColumn>
            </Row>

        </>
    );
}

export default SFSMMbtParametersPage;