import React from "react";

const Input = ({ id, children, value, type, className, defaultValue, style, onChange }) => {
    return(
        <input
            id={ id }
            type={ type ? type : "text" }
            className={ className ? className : "form-control" }
            defaultValue={ defaultValue }
            style={ style }
            onChange={ onChange }
            value={ value }>
            { children }
        </input>
    );
}

export default Input;