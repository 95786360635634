import "./style.css"
import React, { forwardRef, useState, useImperativeHandle, useContext, useEffect }  from "react";
import Column from "../Components/Column";
import Row from "../Components/Row";
import FSMParametersPage from "./FSMParametersPage";
import FSMCenterPage from "./FSMCenterPage";
import FSMFileUploadPage from "./FSMFileUploadPage";
import Button from "../Components/Button";
import { GlobalContext } from "../GlobalContext";
import Card from "../Components/Card";
import { getFsmModelToSvgBaseUri } from "../Actions/Configuration";
import Spinner from "react-bootstrap/esm/Spinner";

import { FsmContext } from "./Context";
import { getLogText } from "../Actions/Utils";

function FSMPage(props, ref) {

    const globalContext = useContext(GlobalContext);
    var theTimer = 0;
    /** 
     * Expose the page-state and 
     * the function to add text to the log
     * to the layer above
     */ 
    useImperativeHandle(ref, 
        () => ({
            /**
             * Function to return the current page state
             */
            getState: () => {
                return state;
            },

            /**
             * Function to add a text to the log.
             * (change page state from DOM node above)
             */
            addLog: async (text) => {
                let x = getLogText(state.log, text);
                
                setState({
                    ...state, 
                    log: x
                })
            }
        })
    );

    /**
     * The main state-object of the FSM Page
     */
    const [state, setState] = useState({
        log: "",
        uploadedHarness: "",
        method: "w",
        handle: "cself",
        additionalStates: "0"
    })

    /**
     * Clears the log
     */
    function clearLog() {
        setState({
            ...state,
            log: ""
        })
    }

    /**
     * Adds a text to the log-output
     */
    function addLog(text) {
        let x = getLogText(state.log, text);

        setState({
            ...state, 
            log: x
        })
    }
    
    /**
     * State for generating the SVG file
     */
    const [svgFileState, setSvgFileState] = useState({
        modelName: "",
        start: false,
        finished: false
    });

    useEffect(() => {
        if ( svgFileState.start ) {
            generateSvgFileFsm();
        }
    }, [svgFileState.start]);   // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Sets the Model name of the uploaded model
     */
    function setModel(modelName, text) {
        let x = getLogText(state.log, text);

        props.setfsmModel(modelName);

        setState({
            ...state,
            log: x
        })
    }

    /**
     * Setter for test-generation method
     */
    function setMethod(method) {
        setState({
            ...state,
            method: method
        })
    }

    /**
     * Setter for handle non-full-specified FSMs
     */
    function setHandle(handle) {
        setState({
            ...state,
            handle: handle
        })
    }

    /**
     * Setter for additional states
     */
    function setAdditionalStates(states) {
        setState({
            ...state,
            additionalStates: states
        })
    }

    /**
     * Sets the name of the uploaded Harness
     */
    function setHarness(harnessName) {
        if ( harnessName.includes("error") ) {
            let x = getLogText(state.log, ("Error setting harness: error during harness upload: " + harnessName) );
            setState({
                ...state,
                uploadedHarness: "error",
                log: x
            })

            props.setFsmHarness("error");
            return;
        }

        let x = getLogText(state.log, "Using harness '" + harnessName + "'");

        setState({
            ...state,
            uploadedHarness: harnessName,
            log: x
        })

        props.setFsmHarness(harnessName);
    }

    /**
     * Get status of SVG file generation
     */
    function getStatus() {
        fetch(getFsmModelToSvgBaseUri() + globalContext.fsmUploadedModel + "/status", {
            method: 'GET'
        })
        .then(response => response.json())
        .then(data => {
            if ( data.terminated ) {
                clearTimeout(theTimer);

                fetch(getFsmModelToSvgBaseUri() + globalContext.fsmUploadedModel + "/svg-file", {
                    method: 'GET'
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.terminated ) {
                        clearTimeout(theTimer);
                        var sfsm_svg = document.getElementById('fsm_svg');
                        var svgString = data.message;
                        var decoded = unescape(encodeURIComponent(svgString));
                        var base64 = btoa(decoded);
                        var imgSource = `data:image/svg+xml;base64,${base64}`;
                        sfsm_svg.src = imgSource;
                        sfsm_svg.style = "width: 100%; height: 100%;";
                        
                        addLog("Picture of Model '" + globalContext.fsmUploadedModel + "' created. Picture is shown below.");
                        setSvgFileState({
                            ...svgFileState,
                            start: false,
                            finished: true,
                            spinnerOn: false
                        })
                    }
                    else {
                        addLog(data.message);
                    }
            
                })
                .catch(error => {
                    addLog(error);
                    setSvgFileState({
                        ...svgFileState,
                        start: false,
                        finished: true,
                        spinnerOn: false
                    })
                });
            }
            else {
                addLog(data.message);
            }

        })
        .catch(error => {
            addLog(error);
            setSvgFileState({
                ...svgFileState,
                start: false,
                finished: true,
                spinnerOn: false
            })
        });
    }

    /**
     * Invoke SVG-File generation.
     */
    function generateSvgFileFsm() {
        fetch(getFsmModelToSvgBaseUri() + globalContext.fsmUploadedModel + "/generate", {
            method: 'POST'
        })
        .then(response => response.json())
        .then(data => {
            addLog(data.message);
            theTimer = setInterval(getStatus, 500);
        })
        .catch(error => {
            addLog(error);
        });
    }

    /**
     * Returnes the page-state in JSX Syntax
     */
    return (
        <div className="FSMPage">
            <FsmContext.Provider value={ state }>
                <Row>
                    <Column className="col-md-3">
                        {/* The left column shows the parameters of the TC-Generation */}
                        <FSMParametersPage 
                            setMethod={ setMethod }
                            setHandle={ setHandle }
                            setAdditionalStates={ setAdditionalStates }
                            storeFsmTestSuiteToPv={ props.storeFsmTestSuiteToPv }>
                        </FSMParametersPage>
                    </Column>
                    
                    <Column className="col-md-6">
                        {/* The center column is for showing the output */}
                        <FSMCenterPage
                            clearLog = { clearLog }>
                        </FSMCenterPage>
                    </Column>

                    <Column className="col-md-3">
                        {/* The right column is for uplaoding files */}
                        <FSMFileUploadPage
                            setModel={ setModel }
                            parseModelFiles={ props.parseModelFiles }
                            setHarness={ setHarness }
                            addLog={addLog}>
                        </FSMFileUploadPage>
                    </Column>
                </Row>
                <Row className="row show-picture-row">
                    <Column className="col-md-12">
                        <Card headerName="Show Picture of the FSM">
                            <Button
                                bootstrapClass="btn btn-primary btn-generate-picture"
                                onClick={() => {
                                        if ( globalContext.fsmUploadedModel === "" ) {
                                            props.showNoModelModal();
                                        }
                                        else {
                                            setSvgFileState({
                                                ...svgFileState,
                                                start: true,
                                                finished: false,
                                                spinnerOn: true
                                            })
                                        }
                                    }
                                }>
                                Generate Picture
                            </Button>
                            <Spinner 
                                className="fsm-spinner"
                                animation={ svgFileState.spinnerOn ? "border" : ""} 
                                size="md"
                                variant="primary"
                                role="status" />

                            <div id="svgContainer">
                                <img id="fsm_svg" src="" alt=""></img>
                            </div>
                        </Card>
                    </Column>
                </Row>
            </FsmContext.Provider>
        </div>
    );
}


export default forwardRef(FSMPage);
