import Column from './Components/Column';
import Row from './Components/Row';

function Footer() {
    return (
        <>
            <Row className="bg-light footer rounded">
                <Column className="col-12">
                    <Row>
                        <Column className="col-6 footer-column">
                            <h6>INFORMATION</h6>
                            <p align="justify">The <b>Test-Cloud</b> of the research group operating systems and distributed systems (AGBS) 
                            at the Department of Mathematics and Computer Science at the University of Bremen provides 
                            services for model-based testing (MBT). In particular, the test-cloud supports automated test 
                            case generation from (deterministic or non-deterministic) finite state machine (FSM) and 
                            symbolic finite state machine (SFSM) models. For FSM models, well-known complete test 
                            generation algorithms have been implemented, currently comprising the W-, the Wp-, the H-, 
                            and the SPYH-Method.
                            </p>
                        </Column>
                        <Column className="col-4 footer-column">
                            <h6>CONTACT</h6>
                            <p>
                                University of Bremen<br/>
                                Research Group Operating Systems, Distributed Systems<br/>
                                Bibliothekstraße 1<br/>
                                D-28359 Bremen<br/>
                                <a href='http://www.informatik.uni-bremen.de/agbs/'>AGBS Website</a>
                            </p>
                        </Column>
                    </Row>
                </Column>
            </Row>
        </>
    )
}

export default Footer;