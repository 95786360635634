import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainPage from './MainPage';
import reportWebVitals from './reportWebVitals';
import {Link, Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import Documentation from './pages/Documentation';
import About from './pages/About';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <div>
      <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
          <div className="container-fluid">
          <a className="navbar-brand" href="index.html">AGBS Test-Cloud</a>
            <button className="navbar-toggler" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#navbarNav" 
                  aria-controls="navbarNav" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
              <li className="nav-item">
                  <Link className='test-cloud-nav-link' to="/">Home</Link>
              </li>
              <li className="nav-item">
                  <Link className='test-cloud-nav-link' to="/documentation">Documentation</Link>
              </li>
              <li className="nav-item">
                  <Link className='test-cloud-nav-link' to="/about">About</Link>
              </li>
              </ul>
          </div>
        </div>
      </nav>
    </div>
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/documentation" element={<Documentation />} />
      <Route path="/about" element={<About />} />
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
