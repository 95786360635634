import React from "react";

const TabButton = ({ className, id, onClick, target, ariaControl, children}) => {
    return (
        <button 
            className={ className }
            onClick={ onClick }
            id={ id } 
            data-bs-toggle="tab" 
            data-bs-target={ target } 
            type="button" 
            role="tab" 
            aria-controls={ ariaControl }
            aria-selected="true">
            { children }
        </button>
    );
}

export default TabButton;