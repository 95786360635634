import "./style.css"
import React, { forwardRef, useState, useImperativeHandle, useEffect, useContext }  from "react";
import Row from "../Components/Row";
import Column from "../Components/Column";
import Card from "../Components/Card";
import TabNavBar from "../Components/TabNavBar";
import TabButton from "../Components/TabButton";
import TabContent from "../Components/TabContent";
import TabPane from "../Components/TabPane";
import Button from 'react-bootstrap/Button';
import { GlobalContext } from '../GlobalContext'
import { Dropdown, Menu } from 'antd';
import { ShowFileModal, TestCloudModal } from '../Components/Modal'
import { getNeo4JToken, getNeo4JUrl } from "../Actions/Configuration";
import Spinner from "react-bootstrap/esm/Spinner";

function TestManagementPage(props, ref) {

    const globalContext = useContext(GlobalContext);

    /** 
     * Expose the page-state and 
     * the function to add text to the log
     * to the layer above
     */ 
    useImperativeHandle(ref, 
        () => ({
            /**
             * Function to return the current page state
             */
            getState: () => {
                return state;
            },

            setPageOpened: () => {
                setState({
                    ...state,
                    pageOpen: true
                })
            }
        })
    );

    const pageLoaded = React.useRef(false);

    /**
     * Main Page state
     */
    const [state, setState] = useState({
        pageOpen: false,
        models: [],
        formulas: [],
        formulas_bak: [],
        sutSoftware: [], 
        sutWrapper: [],
        testSuites: [],
        harnessBinaries: [],
        verdicts: [],
        selectedModel: "",
        selectedFormula: "",
        selectedSutSoftware: "",
        selectedSutWrapper: "",
        selectedTestSuite: "",
        selectedHarnessBinary: "",
        selectedVerdict: "",
        modelFilter: "",
        formulaFilter: "",
        sutSoftwareFilter: "",
        sutWrapperFilter: "",
        testSuiteFilter: "",
        harnessBinaryFilter: "",
        verdictFilter: ""
    });

    const [contextMenuOn, setContextMenuOn] = useState({
        selectedFile: "",
        showDisplayModelFileModal: false,
        showDisplayFormulaFileModal: false,
        showDisplayTestSuiteFileModal: false,
        showDisplayVerdictFileModal: false
    })

    const[fetchDataError, setFetchDataError] = useState({
        text: "",
        showErrorDuringDataFetchModal: false
    });

    const [dataFetchedState, setDataFetchedState] = useState({
        modelDataFetched: false,
        formulaDataFetched: false,
        testStuiteDataFetched: false,
        harnessDataFetched: false,
        verdictDataFetched: false,
        sutSoftwareDataFetched: false,
        sutWrapperDataFetched: false,
    })

    const [spinnerState, setSpinnerState] = useState(false);

    // -----------------------------------
    // Functions to fetch data
    // -----------------------------------

    useEffect(() => {
        if ( pageLoaded.current ) {
            if ( state.pageOpen ) {
                fetchDataFromDatabase();
            }
        }

        pageLoaded.current = true;
    }, [state.pageOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * TODO: Do a refresh, if some events happed...
     */
    useEffect(() => {
    }, [globalContext.sfsmTestSuiteGenerated, globalContext.fsmTestSuiteGenerated])


    useEffect(() => {
        if ( dataFetchedState.modelDataFetched &&
            dataFetchedState.formulaDataFetched &&
            dataFetchedState.harnessDataFetched &&
            dataFetchedState.testStuiteDataFetched &&
            dataFetchedState.verdictDataFetched &&
            dataFetchedState.sutSoftwareDataFetched &&
            dataFetchedState.sutWrapperDataFetched ) {
            
            setSpinnerState(false);

            if ( fetchDataError.text !== "") {
                
                setFetchDataError({
                    ...fetchDataError,
                    showErrorDuringDataFetchModal: true
                })

                setDataFetchedState({
                    ...dataFetchedState,
                    modelDataFetched: false,
                    formulaDataFetched: false,
                    testStuiteDataFetched: false,
                    harnessDataFetched: false,
                    verdictDataFetched: false,
                    sutSoftwareDataFetched: false,
                    sutWrapperDataFetched: false
                })
            }
        }
    }, [dataFetchedState, fetchDataError]);  // eslint-disable-line react-hooks/exhaustive-deps


    /**
     * Fetch relationship data for a relationship from a source-node.
     */
    async function getRelationshipEndpoint(src, relationName, callbackForResult, setFetchDataError) {
        const jsonPackage = {
            "statements": [
                {
                    "statement": "MATCH((x)-[r:" + relationName + "]-(y)) where x.name = \"" + src + "\" return y;",
                    "parameters": {
                        "props": {
                        }
                    }
                }
            ]
        }

        
        fetch(getNeo4JUrl(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + getNeo4JToken()
            },
            body: JSON.stringify(jsonPackage)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error('While fetching data.');
            }
        }).then(data => {
            return data;
        })
        .then((content) => {
            fetch(content["commit"], {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + getNeo4JToken()
                },
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error("During commit");
                }
            });
            
            content["results"].forEach((result) => {
                result["data"].forEach((element) => {
                    element["row"].forEach((object) => {
                        callbackForResult(object);
                    })
                })
            })
        })
        .catch(error => {
            setFetchDataError({
                text: error,
                showErrorDuringDataFetchModal: true
            })
        });
    }
    
    // Get Test Suite, relative to the model
    async function getTestSuitesOfModel(model, callback, setFetchDataError) {
        await getRelationshipEndpoint(model, "GENERATED_FROM", callback, setFetchDataError);
    }

    // Get Verdict, relative to the model
    async function getVerdictsOfModel(model, callback, setFetchDataError) {
        await getRelationshipEndpoint(model, "REFERENCE_MODEL_IS", callback, setFetchDataError);
    }

    // Get the model of the selected test suite
    async function getModelOfTestSuites(testSuite, callback, setFetchDataError) {
        await getRelationshipEndpoint(testSuite, "GENERATED_FROM", callback, setFetchDataError);
    }

    // Get the verdict of the selected test suite
    async function getVerdictOfTestSuite(testSuite, callback, setFetchDataError) {
        await getRelationshipEndpoint(testSuite, "RESULT_OF_APPLICATION_OF", callback, setFetchDataError);
    }

    // Get the test suites of a verdict
    async function getTestSuiteOfVerdict(verdict, callback, setFetchDataError) {
        await getRelationshipEndpoint(verdict, "RESULT_OF_APPLICATION_OF", callback, setFetchDataError);
    }
    
    // Get the models of a verdict
    async function getModelOfVerdict(verdict, callback, setFetchDataError) {
        await getRelationshipEndpoint(verdict, "REFERENCE_MODEL_IS", callback, setFetchDataError);
    }

    // Get harness of a verdict
    async function getHarnessOfVerdict(verdict, callback, setFetchDataError) {
        await getRelationshipEndpoint(verdict, "RESULT_OF_TEST_OF", callback, setFetchDataError);
    }

    // Select formula by test-suite
    async function getFormulaOfTestSuite(testSuite, callback, setFetchDataError) {
        await getRelationshipEndpoint(testSuite, "TEST_PROPERTY_MODELLED_BY", callback, setFetchDataError);
    }

    async function getTestSuiteOfFormula(formula, callback, setFetchDataError) {
        await getRelationshipEndpoint(formula, "TEST_PROPERTY_MODELLED_BY", callback, setFetchDataError);
    }

    async function getVerdictOfHarness(harness, callback, setFetchDataError) {
        await getRelationshipEndpoint(harness, "RESULT_OF_TEST_OF", callback, setFetchDataError);
    }


    // Select handler
    async function onSelectModel(name) {
        if ( state.selectedModel === name ) {
            state.selectedModel = "";
            onClickRefreshContent();
        }
        else {
            // Filter state
            await setFetchDataStart();
            await clearModelDependencies(name);
            await getTestSuitesOfModel(name, addTestSuiteCallback, setDataFetchedState);
            await getVerdictsOfModel(name, addVerdictCallback, setFetchDataError);
            await setFetchDataComplete();
            
            state.models.forEach((model) => {
                if ( model.name === name ) {
                    if ( model.isSfsm ) {
                        props.setModels("", name);
                        if ( state.formulas_bak.length ) {
                            state.formulas = state.formulas_bak;
                            state.formulas_bak = [];
                        }
                    }
                    else {
                        props.setModels(name, "");
                        state.formulas_bak = state.formulas;
                        state.formulas = [];
                    }
                }
            })
        }

        setState({
            ...state
        })
    }

    async function onSelectFormula(name) {
        if ( state.selectedFormula === name ) {
            state.selectedFormula = "";
            onClickRefreshContent();
        }
        else {
            await setFetchDataStart();
            await clearFormulaDependencies(name);
            await getTestSuiteOfFormula(name, addTestSuiteCallback, setFetchDataError);
            await setFetchDataComplete();
            props.setSfsmFormulaFile(name);
        }

        setState({
            ...state,
        })
    }

    function onSelectSutSoftware(name) {
        setState({
            ...state,
            selectedSutSoftware: name
        })
    }

    function onSelectSutWrapper(name) {
        setState({
            ...state,
            selectedSutWrapper: name
        })
    }

    async function onSelectTestSuite(name) {
        if ( state.selectedTestSuite === name ) {
            state.selectedTestSuite = "";
            onClickRefreshContent();
        }
        else {
            await setFetchDataStart();
            await clearTestSuiteDependencies(name);
            await getModelOfTestSuites(name, addModelFileCallback, setDataFetchedState);
            await getVerdictOfTestSuite(name, addVerdictCallback, setFetchDataError);
            await getFormulaOfTestSuite(name, addFormulaCallback, setFetchDataError);
            await setFetchDataComplete();

            // Set right Test sutie (fsm or sfsm). To do this, check reference model in DB
            const jsonPackage = {
                "statements": [
                    {
                        "statement": "MATCH((ts)-[r:GENERATED_FROM]-(model)) where ts.name = \"" + name + "\" return model;",
                        "parameters": {
                            "props": {
                            }
                        }
                    }
                ]
            }

            fetch(getNeo4JUrl(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + getNeo4JToken()
                },
                body: JSON.stringify(jsonPackage)
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error('While fetching data.');
                }
            }).then(data => {
                return data;
            })
            .then((content) => {
                fetch(content["commit"], {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Basic ' + getNeo4JToken()
                    },
                })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else {
                        throw new Error("During commit");
                    }
                });
                
                content["results"].forEach((result) => {
                    result["data"].forEach((element) => {
                        element["row"].forEach((object) => {
                            if ( object["isSfsm"] ) {
                                props.setTestSuite("", name);
                                return;
                            }
                            else {
                                props.setTestSuite(name, "");
                                return;
                            }
                        })
                    })
                })
            })
            .catch(error => {
                setFetchDataError({
                    text: error,
                    showErrorDuringDataFetchModal: true
                })
            });
        }

        setState({
            ...state,
        })
    }

    async function onSelectHarnessBinary(name) {
        if ( state.selectedHarnessBinary === name ) {
            state.selectedHarnessBinary = "";
            onClickRefreshContent();
        }
        else {
            await setFetchDataStart();
            await clearHarnessDependencies(name);
            await getVerdictOfHarness(name, addVerdictCallback, setFetchDataError);
            await setFetchDataComplete();
            props.setHarness(name, name);
        }

        setState({
            ...state
        })
    }

    async function onSelectVerdict(name) {
        if ( state.selectedVerdict === name ) {
            state.selectedVerdict = "";
            onClickRefreshContent();
        }
        else {
            await setFetchDataStart();
            await clearVerdictDependencies(name);
            await getTestSuiteOfVerdict(name, addTestSuiteCallback, setFetchDataError);
            await getModelOfVerdict(name, addModelFileCallback, setFetchDataError);
            await getHarnessOfVerdict(name, addHarnessCallback, setFetchDataError);
            await setFetchDataComplete();
        }

        setState({
            ...state
        })
    }

    /**
     * Refresh content
     */
    async function onClickRefreshContent() {
        await setFetchDataStart();
        await props.clearValues();
        await clearData();
        fetchDataFromDatabase();
    }

    async function clearData() {
        state.models = [];
        state.testSuites = [];
        state.formulas = [];
        state.testSuites = [];
        state.harnessBinaries = [];
        state.verdicts = [];
        state.sutSoftware = [];
        state.sutWrapper = [];
        state.selectedModel = "";
        state.selectedFormula = "";
        state.selectedSutSoftware = "";
        state.selectedSutWrapper = "";
        state.selectedTestSuite = "";
        state.selectedHarnessBinary = "";
        state.selectedVerdict = "";
        state.modelFilter = "";
        state.formulaFilter = "";
        state.sutSoftwareFilter = "";
        state.sutWrapperFilter = "";
        state.testSuiteFilter = "";
        state.harnessBinaryFilter = "";
        state.verdictFilter = "";
    }
    
    async function clearModelDependencies(modelName) {
        state.selectedModel = modelName;
        state.testSuites = [];
        state.verdicts = [];
    }

    async function clearTestSuiteDependencies(testSuiteName) {
        state.selectedTestSuite = testSuiteName;
        state.models = [];
        state.verdicts = [];
        state.formulas = [];
    }

    async function clearVerdictDependencies(verdictName) {
        state.selectedVerdict = verdictName;
        state.models = [];
        state.testSuites = [];
        state.harnessBinaries = [];
    }

    async function clearHarnessDependencies(harnessName) {
        state.selectedHarnessBinary = harnessName;
        state.verdicts = [];
    }

    async function clearFormulaDependencies(formulaName) {
        state.selectedFormula = formulaName;
        state.testSuites = [];
    }

    /**
     * Click on context menu
     */
    function onClickContextMenu(event, name) {
        event.preventDefault();

        var selectedisAModel = false;
        state.models.forEach((model) => {
            if ( model.name === name ) {
                if ( model.csv ) {
                    selectedisAModel = true;

                    setContextMenuOn({
                        ...contextMenuOn,
                        selectedFile: name
                    });
                }
                else {
                    selectedisAModel = true;

                    setContextMenuOn({
                        ...contextMenuOn,
                        selectedFile: name
                    })
                }  
            }
        });

        if ( ! selectedisAModel ) {
            // all other files (formula, verdict and test-suite are txt files.)
         
            setContextMenuOn({
                ...contextMenuOn,
                selectedFile: name
            })
        }
    }

    /**
     * This function is a callback of the onChange 
     * for the search input field. The main purpose is
     * to filter all lists in state on the filter-value.
     * The filter-value is the typed text from the
     * search input field.
     */
    function applyModelFilter(filterValue) {
        setState({
            ...state,
            modelFilter: filterValue
        })
    }

    function applyFormulaFilter(filterValue) {
        setState({
            ...state,
            formulaFilter: filterValue
        })
    }

    function applySutSoftwareFilter(filterValue) {
        setState({
            ...state,
            sutSoftwareFilter: filterValue
        })
    }

    function applySutWrapperFilter(filterValue) {
        setState({
            ...state,
            sutWrapperFilter: filterValue
        })
    }

    function applyTestSuiteFilter(filterValue) {
        setState({
            ...state,
            testSuiteFilter: filterValue
        })
    }

    function applyHarnessBinaryFilter(filterValue) {
        setState({
            ...state,
            harnessBinaryFilter: filterValue
        })
    }

    function applyVerdictFilter(filterValue) {
        setState({
            ...state,
            verdictFilter: filterValue
        })
    }

    async function setFetchDataComplete() {
        setSpinnerState(false);
    }

    async function setFetchDataStart() {
        setSpinnerState(false);
    }

    /**
     * Fetch data from Neo4J
     */
    async function fetchDataFromDatabase() {
        await setFetchDataStart();
        await fetchData(addModelFileCallback, setFetchDataError, "Model");
        await fetchData(addFormulaCallback, setFetchDataError, "Formula");
        await fetchData(addTestSuiteCallback, setFetchDataError, "TestSuite");
        await fetchHarnessData(addHarnessCallback, setFetchDataError, "Harness");
        await fetchData(addVerdictCallback, setFetchDataError, "Verdict");
        await setFetchDataComplete();
    }

    function addModelFileCallback(object) {
        if ( ! state.models.includes({ name: object["name"] }) ) {
            state.models.push({ name: object["name"], csv: (object["csv"] !== ""), isSfsm: object["isSfsm"] });
            
            // Update
            setState({
                ...state
            })
        }

        return;  
    }

    function addFormulaCallback(object) {
        if ( ! state.formulas.includes(object["name"]) ) {
            state.formulas.push(object["name"]);

            setState({
                ...state
            })
        }
        return;
    }

    function addTestSuiteCallback(object) {
        if ( ! state.testSuites.includes(object["name"]) ) {
            state.testSuites.push(object["name"]);

            setState({
                ...state
            })
        }
    }

    function addHarnessCallback(object) {
        if ( ! state.harnessBinaries.includes(object) ) {
            state.harnessBinaries.push(object);

            setState({
                ...state
            })
        }
        
        return;
    }

    function addVerdictCallback(object) {
        if ( ! state.verdicts.includes(object["name"]) ) {
            state.verdicts.push(object["name"]);

            setState({
                ...state
            })
        }

        return;
    }

    /*
    function addSutSoftwareCallback(object) {
        if ( ! state.sutSoftware.includes(object["name"]) ) {
            state.sutSoftware.push(object["name"]);

            setState({
                ...state
            })
        }
        
        return;
    }

    function addSutWrapperCallback(object) {
        if ( ! state.sutWrapper.includes(object["name"])) {
            state.sutWrapper.push(object["name"]);

            setState({
                ...state
            })
        }
        
        return;
    }
    */

    
    /**
     * Fetch data from NEO4J.
     * 
     * @param callback
     *  Add element to list callback to show the fetched object
     * @param setFetchDataError
     *  Throw an Error
     * @param bucket
     *  Name of Table
     */
    async function fetchData(callback, setFetchDataError, ObjectClassName) {
        const jsonPackage = {
            "statements": [
                {
                    "statement": "MATCH(x:" + ObjectClassName + ") RETURN x;",
                    "parameters": {
                        "props": {
                        }
                    }
                }
            ]
        }

        
        fetch(getNeo4JUrl(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + getNeo4JToken()
            },
            body: JSON.stringify(jsonPackage)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error('While fetching data.');
            }
        }).then(data => {
            return data;
        })
        .then((content) => {
            fetch(content["commit"], {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + getNeo4JToken()
                },
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error("During commit");
                }
            });
            
            content["results"].forEach((result) => {
                result["data"].forEach((element) => {
                    element["row"].forEach((object) => {
                        callback(object);
                    })
                })
            })
        })
        .catch(error => {
            setFetchDataError({
                text: error,
                showErrorDuringDataFetchModal: true
            })
        });
    }
    /**
     * Fetch data from NEO4J.
     * 
     * @param callback
     *  Add element to list callback to show the fetched object
     * @param setFetchDataError
     *  Throw an Error
     * @param bucket
     *  Name of Table
     */
    async function fetchHarnessData(callback, setFetchDataError, ObjectClassName) {
        const jsonPackage = {
            "statements": [
                {
                    "statement": "MATCH(x:" + ObjectClassName + ") RETURN x.name;",
                    "parameters": {
                        "props": {
                        }
                    }
                }
            ]
        }

        
        fetch(getNeo4JUrl(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + getNeo4JToken()
            },
            body: JSON.stringify(jsonPackage)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error('While fetching data.');
            }
        }).then(data => {
            return data;
        })
        .then((content) => {
            fetch(content["commit"], {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + getNeo4JToken()
                },
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error("During commit");
                }
            });
            
            content["results"].forEach((result) => {
                result["data"].forEach((element) => {
                    element["row"].forEach((object) => {
                        callback(object);
                    })
                })
            })
        })
        .catch(error => {
            setFetchDataError({
                text: error,
                showErrorDuringDataFetchModal: true
            })
        });
    }

    const ShowModelMenu = (
        <Menu
            items={[
                {
                    label: "View Model"
                },
            ]}
            onClick={ () => {
                setContextMenuOn({
                    ...contextMenuOn,
                    showDisplayModelFileModal: true
                })
            } }>

        </Menu>);

    const ShowFormulaMenu = (
        <Menu
            items={[
                {
                    label: "View Formula"
                },
            ]}
            onClick={ () => {
                setContextMenuOn({
                    ...contextMenuOn,
                    showDisplayFormulaFileModal: true
                })
            } }>
    
        </Menu>);

    const ShowTestSuiteMenu = (
    <Menu
        items={[
            {
                label: "View Test-Suite"
            },
        ]}
        onClick={ () => {
            setContextMenuOn({
                ...contextMenuOn,
                showDisplayTestSuiteFileModal: true
            })
        } }>

    </Menu>);

    const ShowVerdictMenu = (
        <Menu
            items={[
                {
                    label: "View Verdict"
                },
            ]}
            onClick={ () => {
                setContextMenuOn({
                    ...contextMenuOn,
                    showDisplayVerdictFileModal: true
                })
            } }>
    
        </Menu>);
    

    /**
     * Main render function: return the rendered HTML 
     */
    return (
        <div className="TestManagementPage">
            <ShowFileModal
                show={ contextMenuOn.showDisplayModelFileModal }
                onClose={ () => { setContextMenuOn({ ...contextMenuOn, showDisplayModelFileModal: false }) } }
                ObjectClassName="Model"
                fieldName="csv"
                name={ contextMenuOn.selectedFile }>
            </ShowFileModal>
            <ShowFileModal
                show={ contextMenuOn.showDisplayFormulaFileModal }
                onClose={ () => { setContextMenuOn({ ...contextMenuOn, showDisplayFormulaFileModal: false }) } }
                ObjectClassName="Formula"
                fieldName="content"
                name={ contextMenuOn.selectedFile }>
            </ShowFileModal>
            <ShowFileModal
                show={ contextMenuOn.showDisplayTestSuiteFileModal }
                onClose={ () => { setContextMenuOn({ ...contextMenuOn, showDisplayTestSuiteFileModal: false }) } }
                ObjectClassName="TestSuite"
                fieldName="content"
                name={ contextMenuOn.selectedFile }>
            </ShowFileModal>
            <ShowFileModal
                show={ contextMenuOn.showDisplayVerdictFileModal }
                onClose={ () => { setContextMenuOn({ ...contextMenuOn, showDisplayVerdictFileModal: false }) } }
                ObjectClassName="Verdict"
                fieldName="content"
                name={ contextMenuOn.selectedFile }>
            </ShowFileModal>

            <TestCloudModal
                text={ fetchDataError.text }
                title="Error while fetching data!"
                show={ fetchDataError.showErrorDuringDataFetchModal }
                onClose={ () => {
                    setFetchDataError({ ...fetchDataError, text: "", showErrorDuringDataFetchModal: false });
                }}>
            </TestCloudModal>

            <Row>
                { /* Column for Model, Formulas, SUT Software and SUT Wrapper */ }
                <Column className="col-4">
                    { /* Tab Buttons */ }
                    <TabNavBar className="nav nav-tabs" id="tm-model-sut-tabs">
                        <TabButton
                            className="nav-link active"
                            id="nav-model-tab"
                            target="#tm-model-tab-pane"
                            ariaControl="tm-model-tab-pane">
                            Model
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="nav-sut-tab"
                            target="#tm-sut-tab-pane"
                            ariaControl="tm-sut-tab-pane">
                            SUT
                        </TabButton>
                    </TabNavBar>

                    { /* Tab Content */ }
                    <TabContent id="tm-model-sut-tab-content">
                        { /* Tab for model */ }
                        <TabPane id="tm-model-tab-pane" className="tab-pane active">
                            <Card headerName="Models" bodyClass="card-body tm-card">
                                <Row className="row search-row">
                                    <Column className="col-2">
                                    Filter:
                                    </Column>
                                    <Column className="col-6">
                                        <input 
                                            className="form-control filter-input" 
                                            type="text" 
                                            placeholder="filter..." 
                                            onChange={ (event) => applyModelFilter(event.target.value) }
                                            value={ state.modelFilter } />
                                    </Column>
                                </Row>
                                <Column className="content-column">
                                    <Dropdown overlay={ ShowModelMenu } trigger={["contextMenu"]}>
                                        <table className="table table-sm table-hover" >
                                            <tbody>
                                            { // eslint-disable-next-line
                                                state.models.filter((v) => {
                                                    if ( String(v.name).includes(state.modelFilter) ) {
                                                        return v;
                                                    }
                                                }).map((entry) =>
                                                    <tr key={ entry.name }>
                                                        <td key={ entry.name }
                                                            onClick={ () => onSelectModel( String(entry.name) ) }
                                                            style={(state.selectedModel === String(entry.name)) ? { backgroundColor: '#FCEDDA' } : {} }
                                                            onContextMenu={ (event) => onClickContextMenu(event, String(entry.name)) }>
                                                            { entry.name }
                                                        </td>
                                                    </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    </Dropdown>
                                </Column>
                            </Card>
                            <Card headerName="Formulas" bodyClass="card-body tm-card">
                                <Row className="row search-row">
                                    <Column className="col-2">
                                    Filter:
                                    </Column>
                                    <Column className="col-6">
                                        <input 
                                            className="form-control filter-input" 
                                            type="text" 
                                            placeholder="filter..." 
                                            onChange={ (event) => applyFormulaFilter(event.target.value) }
                                            value={ state.formulaFilter } />
                                    </Column>
                                </Row>
                                <Column className="content-column">
                                    <Dropdown overlay={ ShowFormulaMenu } trigger={["contextMenu"]}>
                                        <table className="table table-sm table-hover" >
                                            <tbody>
                                            { // eslint-disable-next-line
                                                state.formulas.filter((v) => {
                                                    if ( String(v).includes(state.formulaFilter) ) {
                                                        return v;
                                                    }
                                                }).map((entry) => 
                                                    <tr key={ entry }>
                                                        <td key={ entry }
                                                            onClick={ () => onSelectFormula( String(entry) ) }
                                                            style={(state.selectedFormula === String(entry)) ? { backgroundColor: '#FCEDDA' } : {} }
                                                            onContextMenu={ (event) => onClickContextMenu(event, String(entry)) }>
                                                            { entry }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </Dropdown>
                                </Column>
                            </Card>
                        </TabPane>

                        { /* Tab for sut */ }
                        <TabPane id="tm-sut-tab-pane" className="tab-pane">
                            <Card headerName="SUT Software" bodyClass="card-body tm-card">
                                <Row className="row search-row">
                                    <Column className="col-2">
                                    Filter:
                                    </Column>
                                    <Column className="col-6">
                                        <input 
                                            className="form-control filter-input" 
                                            type="text" 
                                            placeholder="filter..." 
                                            onChange={ (event) => applySutSoftwareFilter(event.target.value) }
                                            value={ state.sutSoftwareFilter } />
                                    </Column>
                                </Row>
                                <Column className="content-column">
                                        <table className="table table-sm table-hover" >
                                            <tbody>
                                            { // eslint-disable-next-line
                                                state.sutSoftware.filter((v) => {
                                                    if ( String(v).includes(state.sutSoftwareFilter) ) {
                                                        return v;
                                                    }
                                                }).map((entry) => 
                                                    <tr key={ entry }>
                                                        <td key={ entry }
                                                            onClick={ () => onSelectSutSoftware( String(entry) ) }
                                                            style={(state.selectedSutSoftware === String(entry)) ? { backgroundColor: '#FCEDDA' } : {} }>
                                                            { entry }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                </Column>
                            </Card>
                            <Card headerName="SUT Wrapper" bodyClass="card-body tm-card">
                                <Row className="row search-row">
                                    <Column className="col-2">
                                    Filter:
                                    </Column>
                                    <Column className="col-6">
                                        <input 
                                            className="form-control filter-input" 
                                            type="text" 
                                            placeholder="filter..." 
                                            onChange={ (event) => applySutWrapperFilter(event.target.value) }
                                            value={state.sutSoftwareFilter } />
                                    </Column>
                                </Row>
                                <Column className="content-column">
                                        <table className="table table-sm table-hover" >
                                            <tbody>
                                            { // eslint-disable-next-line
                                                state.sutWrapper.filter((v) => {
                                                    if ( String(v).includes(state.sutWrapperFilter) ) {
                                                        return v;
                                                    }
                                                }).map((entry) => 
                                                    <tr key={ entry }>
                                                        <td key={ entry }
                                                            onClick={ () => onSelectSutWrapper( String(entry) ) }
                                                            style={(state.selectedSutWrapper === String(entry)) ? { backgroundColor: '#FCEDDA' } : {} }>
                                                            { entry }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                </Column>
                            </Card>
                        </TabPane>
                    </TabContent>
                </Column>
                
                { /* Column for Test suites and Harness binaries */ }
                <Column className="col-4">
                    <Row>
                        <Column className="col-12">
                            <Button 
                                id="btnRefresh"
                                variant="success"
                                onClick={ () => onClickRefreshContent() }>
                                Refresh Artifacts
                            </Button>
                            <Button 
                                id="btnReset"
                                variant="danger"
                                onClick={ () => onClickRefreshContent() }>
                                Reset
                            </Button>
                            <Spinner 
                                className="test-cloud--tm-spinner"
                                animation={
                                    spinnerState ? "border" : ""
                                } 
                                size="md"
                                variant="primary"
                                role="status">
                                
                            </Spinner>
                        </Column>
                    </Row>
                    <div className="center-cards">
                        <Card headerName="Test-Suites" bodyClass="card-body tm-card">
                            <Row className="row search-row">
                                <Column className="col-2">
                                Filter:
                                </Column>
                                <Column className="col-6">
                                    <input 
                                        className="form-control filter-input" 
                                        type="text" 
                                        placeholder="filter..." 
                                        onChange={ (event) => applyTestSuiteFilter(event.target.value) }
                                        value={ state.testSuiteFilter } />
                                </Column>
                            </Row>
                            <Column className="content-column">
                                <Dropdown overlay={ ShowTestSuiteMenu } trigger={["contextMenu"]}>
                                    <table className="table table-sm table-hover" >
                                        <tbody>
                                        { // eslint-disable-next-line
                                            state.testSuites.filter((v) => {
                                                if ( String(v).includes(state.testSuiteFilter) ) {
                                                    return v;
                                                }
                                            }).map((entry) => 
                                            <tr key={ entry }>
                                                <td key={ entry }
                                                    onClick={ () => onSelectTestSuite( String(entry) ) }
                                                    style={(state.selectedTestSuite === String(entry)) ? { backgroundColor: '#FCEDDA' } : {} }
                                                    onContextMenu={ (event) => onClickContextMenu(event, String(entry)) }>
                                                    { entry }
                                                </td>
                                            </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </Dropdown>
                            </Column>
                        </Card>
                        <Card headerName="Harness-Binaries" bodyClass="card-body tm-card">
                            <Row className="row search-row">
                                <Column className="col-2">
                                Filter:
                                </Column>
                                <Column className="col-6">
                                    <input 
                                        className="form-control filter-input" 
                                        type="text" 
                                        placeholder="filter..." 
                                        onChange={ (event) => applyHarnessBinaryFilter(event.target.value) }
                                        value={ state.harnessBinaryFilter } />
                                </Column>
                            </Row>
                            <Column className="content-column">
                                    <table className="table table-sm table-hover" >
                                        <tbody>
                                        { // eslint-disable-next-line
                                            state.harnessBinaries.filter((v) => {
                                                if ( String(v).includes(state.harnessBinaryFilter) ) {
                                                    return v;
                                                }
                                            }).map((entry) => 
                                            <tr key={ entry }>
                                                <td key={ entry }
                                                    onClick={ () => onSelectHarnessBinary( String(entry) ) }
                                                    style={(state.selectedHarnessBinary === String(entry)) ? { backgroundColor: '#FCEDDA' } : {} }>
                                                    { entry }
                                                </td>
                                            </tr>)
                                        }
                                        </tbody>
                                    </table>
                            </Column>
                        </Card>
                    </div>
                </Column>

                { /* Column for Test Execution Verdicts */ }
                <Column className="col-4">
                    <div className="right-cards">
                        <Card headerName="Verdicts" style={{ height: "662px"}}>
                            <Row className="row search-row">
                                <Column className="col-2">
                                Filter:
                                </Column>
                                <Column className="col-6">
                                    <input 
                                        className="form-control filter-input" 
                                        type="text" 
                                        placeholder="filter..." 
                                        onChange={ (event) => applyVerdictFilter(event.target.value) }
                                        value={ state.verdictFilter } />
                                </Column>
                            </Row>
                            <Column className="verdict-content-column">
                                <Dropdown overlay={ ShowVerdictMenu } trigger={["contextMenu"]}>
                                    <table className="table table-sm table-hover" >
                                        <tbody>
                                        { // eslint-disable-next-line
                                            state.verdicts.filter((v) => {
                                                if ( String(v).includes(state.verdictFilter) ) {
                                                    return v;
                                                }
                                            }).map((entry) => 
                                            <tr key={ entry }>
                                                <td key={ entry }
                                                    onClick={ () => onSelectVerdict( String(entry) ) }
                                                    style={(state.selectedVerdict === String(entry)) ? { backgroundColor: '#FCEDDA' } : {} }
                                                    onContextMenu={ (event) => onClickContextMenu(event, String(entry)) }>
                                                    { entry }
                                                </td>
                                            </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </Dropdown>
                            </Column>
                        </Card>
                    </div>
                </Column>
            </Row>
        </div>
    );
}

export default forwardRef(TestManagementPage);