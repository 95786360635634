import React from "react";

const Checkbox = ({ id, className, checked, onChange, children }) => {
    return (
        <input 
            type="checkbox" 
            className={ className }
            id={ id }
            checked= { checked }
            onChange= { onChange }>
            { children }
        </input>
    );
}

export default Checkbox;