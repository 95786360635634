import "./style.css"
import React, { forwardRef, useState, useImperativeHandle }  from "react";
import Column from "../Components/Column";
import Row from "../Components/Row";
import POTParametersPage from "./POTParametersPage";
import POTCenterPage from "./POTCenterPage";
import POTFileUploadPage from "./POTFileUploadPage";
import { getDateString } from "../Actions/Utils";
import { getNameOfFile } from "../Utils";

import { POTContext } from "./Context";
import { getLogText } from "../Actions/Utils";

function POTPage(props, ref) {

    /** 
     * Expose the page-state and 
     * the function to add text to the log
     * to the layer above
     */ 
    useImperativeHandle(ref, 
        () => ({
            /**
             * Function to return the current page state
             */
            getState: () => {
                return state;
            },

            /**
             * Function to add a text to the log.
             * (change page state from DOM node above)
             */
            addLog: async (text) => {
                let x = getLogText(state.log, text);
                
                setState({
                    ...state, 
                    log: x
                })
            }
        })
    );

    /**
     * The main state-object of the POT Page
     */
    const [state, setState] = useState({
        log: "",
        uploadedProperty: "",
        uploadedHarness: "",
        properties: []
    })

    /**
     * Clears the log
     */
    function clearLog() {
        setState({
            ...state,
            log: ""
        })
    }

    /**
     * Adds a text to the log-output
     */
    function addLog(text) {
        let x = getLogText(state.log, text);

        setState({
            ...state, 
            log: x
        })
    }

    /**
     * Setter for formula file
     */
    function setPropertyFile(propertyFile, text) {
        let x = getLogText(state.log, text);

        if ( text === "" ) {
            setState({
                ...state,
            })
    
        }
        else {
            setState({
                ...state,
                log: x
            })   
        }

        props.setPotPropertyFile(propertyFile);
    }

    /**
     * Adds a formula file to memory
     */
    function addPropertyFile(name, realName, content) {        
        var uniqueName = name + "-" + getDateString();
        uniqueName = uniqueName.replaceAll('/', '-').replaceAll(',', '').replaceAll(' ', '_').replaceAll(':', '-').replaceAll('\n', '');
        state.properties.forEach((elem) => {
            if ( elem.name === name ) {
                let errorText = "Error, property with name " + name + " already exists.";
                let x = getLogText(state.log, errorText);
                setState({
                    ...state,
                    log: x
                })
                return;
            }
        });

        state.properties.push({ name: name, fileName: realName, uniqueName: uniqueName, content: content, checked: false });

        setState({
            ...state
        })
    }

    /**
     * Processes a formula file
     */
    function processPropertyFile(name, realName, fileHandle, callback) {
        const reader = new FileReader();
        reader.readAsText(fileHandle);
        reader.onload = () => {
            callback(name, realName, reader.result);
        };
    }

    /**
     * Parse all formula files
     */
    function parsePropertyFiles(files) {
        if ( files.length > 1000 ) {
            console.log("Error, to many files open");
            return;
        }

        state.properties = [];

        files.forEach((elem) => {
            let name = getNameOfFile(elem.name);
            processPropertyFile("Prop_" + name, elem.name, elem, addPropertyFile);
        });
    }

    /**
     * Sets the name of the uploaded Harness
     */
    function setHarness(harnessName) {
        if ( harnessName.includes("error") ) {
            let x = getLogText(state.log, ("Error setting harness: error during harness upload: " + harnessName) );
            setState({
                ...state,
                uploadedHarness: "error",
                log: x
            })

            props.setPotHarness("error");
            return;
        }

        let x = getLogText(state.log, "Using harness '" + harnessName + "'");

        setState({
            ...state,
            uploadedHarness: harnessName,
            log: x
        })

        props.setPotHarness(harnessName);
    }
    
    // ----------------------------------------------------------------------
    // Render function
    // ----------------------------------------------------------------------
    return (
        <div className="POTPage">
            <POTContext.Provider value={ state }>
                <Row>
                   <Column className="col-md-3">
                        <POTParametersPage 
                            showNoProperty={ props.showNoProperty }
                            showPotWrongFileFormatModal={ props.showPotWrongFileFormatModal }
                            parsePropertyFiles={ parsePropertyFiles }
                            setPropertyFile={ setPropertyFile }></POTParametersPage>
                   </Column>
                   <Column className="col-md-6">
                        <POTCenterPage clearLog={clearLog}></POTCenterPage>
                   </Column>
                   <Column className="col-md-3">
                        <POTFileUploadPage 
                            showNoFaultDomainModal={ props.showNoFaultDomainModal }
                            showPotWrongFileFormatModal={ props.showPotWrongFileFormatModal }
                            showNoHarnessModal={ props.showNoHarnessModal }
                            setHarness={ setHarness }
                            setPropertyFile={ setPropertyFile }
                            addLog={ addLog }
                            setPotFaultDomain={ props.setPotFaultDomain}></POTFileUploadPage>
                   </Column>
                </Row>
            </POTContext.Provider>
        </div>
    );
}


export default forwardRef(POTPage);
