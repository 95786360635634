import React from "react";
import Column from "./Column";

const FileUploadPage = ({ children }) => {
    return (
        <>
            <Column className="col-2"></Column>
            <Column className="col-9">
                { children }
            </Column>
        </>
    );
}

export default FileUploadPage;