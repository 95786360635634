import React, { useContext, useState } from "react";
import Column from "../Components/Column";
import Row from "../Components/Row";
import Card from "../Components/Card";
import Label from "../Components/Label";
import ParameterColumn from "../Components/ParameterColumn";

import { POTContext } from "./Context";
import { GlobalContext } from "../GlobalContext";
import Checkbox from "../Components/Checkbox";
import Button from "../Components/Button";
import { uploadProperty } from "../Actions/FileUpload";

/**
 * @brief
 *  This is the Left Page of the POT-Page.
 */
function POTParametersPage({ showNoProperty, showPotWrongFileFormatModal, parsePropertyFiles, setPropertyFile }) {
    // Contexts
    const ctxt = useContext(POTContext);
    const globalContext = useContext(GlobalContext)

    const [state, setState] = useState ({
        selectedProperty: ""
    });



    // ----------------------------------------------------------------------
    // Callback functions
    // ----------------------------------------------------------------------
    function onSelectPropertyFolder(event) {
        let filesOfFolder = [].slice.call(event.target.files); // to array
        parsePropertyFiles(filesOfFolder);
    }

    /**
     * Callback of button to upload a property file.
     */
    function onClickUploadProperty() {
        // get here checked elem object from file parsing class
        ctxt.properties.forEach( (elem) => {
            if ( elem.checked ) {

                if ( ! elem.fileName.includes(".prop") ) {
                    showPotWrongFileFormatModal()
                    return;
                }
                uploadProperty(elem.uniqueName, elem.content, setPropertyFile);
            }
        });
    }

    // ----------------------------------------------------------------------
    // Change event handler
    // ----------------------------------------------------------------------
    /**
     * Change handler, if one clicked a formula file to upload
     */
    function onChangePropertyCheckbox(cbName) {
        ctxt.properties.forEach((elem) => {
            if ( elem.name !== cbName.entry.name ) {
                elem.checked = false;
            } 
            else {
                elem.checked = true;
            }
        })

        // Update state
        setState({
            ...state,
            selectedProperty: cbName.entry.name // very weired, but works
        })
    }

    // ----------------------------------------------------------------------
    // Render function
    // ----------------------------------------------------------------------
    /**
     * Return page state in JSX syntax
     */
    return (
        <>
            { /* Title Row */ }
            <Row>
                <ParameterColumn>
                    <h4>POT-Parameters</h4>
                </ParameterColumn>
            </Row>

            { /* Show current fault-domain */ }
            <Row>
                <ParameterColumn>
                    <Card headerName="Selected Artifacts">
                        <Row>
                            <Label id="currentUsingFaultDomain" className="ParameterLabel"> 
                                { globalContext.potFaultDomain ? "Fault Domain: " + globalContext.potFaultDomain : "No fault domain selected."} 
                            </Label>
                        </Row>

                        <Row>
                            <Label id="currentUsingHarness" className="ParameterLabel pot-label"> 
                                { globalContext.potHarness ? "Harness: " + globalContext.potHarness : "No harness selected."} 
                            </Label>
                        </Row>

                        <Row>
                            <Label id="currentUsingProperty" className="ParameterLabel pot-label"> 
                                { globalContext.potSelectedProperty ? "Property: " + globalContext.potSelectedProperty : "No property selected."} 
                            </Label>
                        </Row>
                    </Card>
                </ParameterColumn>
            </Row>


            { /* Upload and select a fault domain */ }
            <Row>
                <ParameterColumn>
                    {/* Parameters Column */}
                    <Card headerName="Property Folder">
                        {/* This is the list for selecting input folder */}
                        <Row>
                            <input 
                                type="file"
                                className="form-control property-file-select" 
                                id="file-input-property-files" 
                                onChange={ onSelectPropertyFolder }
                                webkitdirectory="true" 
                                multiple />
                        </Row>
                        <Row>
                            <Column className="col-8 select-property-form">
                                Select Property
                            </Column>
                        </Row>
                        <Row>
                            {/* This is the list that shows the proeprty-names */}
                            <Column className="col-12">
                                <div className="sidebar-offcanvas" id="sidebar" role="navigation">
                                    <div className="well sidebar-nav">
                                        <div className="form-check nav2 form-inline pot-property-list" id="propertyList" style={{height: '500px'}}>
                                            <table className="table table-sm table-hover" >
                                                <tbody>
                                                    { 
                                                        ctxt.properties.map( (entry) =>
                                                        <tr key={ entry.name }>
                                                            <td>
                                                                <div className="form-check list-items" key={ entry.name }>
                                                                    <Checkbox 
                                                                        className="model-checkbox" 
                                                                        id={ entry.name } 
                                                                        onChange={() => onChangePropertyCheckbox( { entry } ) }
                                                                        checked={ entry.checked ? true : false }>
                                                                    </Checkbox>
                                                                    <Label className="checkbox-inline nav3">
                                                                        { entry.name }
                                                                    </Label>
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Column> 
                        </Row>
                        <Row>
                            <Column className="col-12">
                                <Button
                                    id="UploadPropertyBtn"
                                    bootstrapClass="btn btn-primary bg-gradient"
                                    onClick={() => onClickUploadProperty() }>
                                    Upload Property
                                </Button>
                            </Column>
                        </Row>
                    </Card>
                </ParameterColumn>
            </Row>
        </>
    );
}

export default POTParametersPage;
