import './MainPage.css';
import React from "react"
import ContentPage from "./pages/ContentPage";

/**
 * This is the Main Website page for presentation.
 */
function MainPage() {
  return (
    <div className="MainPage">
      <ContentPage></ContentPage>
    </div>
  );
}

export default MainPage;
