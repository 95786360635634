import { getHarnessExeBaseUri } from "./Configuration";

var theTimer = 0;

function getStatus(pageReference, testExecutionVerdictName, enableControls, setHarnessExecuted) {
    fetch(getHarnessExeBaseUri() + testExecutionVerdictName + "/status", {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        if ( data.terminated ) {
            clearTimeout(theTimer);
            enableControls();
            setHarnessExecuted();
        } 

        pageReference.addLog(data.message);
    })
    .catch(error => {
        pageReference.addLog(error);
        enableControls();
    });
}

function ExecuteTests(pageReference, modelName, harnessName, testExecutionVerdictName, testSuiteName, disableControls, enableControls, setHarnessExecuted) {
    // If the test-suite was selected from TM-Page, the name contains the Model Name.
    // when we want to execute tests, we must remove this name, so that the backend can find the file correctly
    testSuiteName = testSuiteName.replace(modelName+"_", "")
    
    // call fetch to POST FSM-Harness-Execution
    fetch(getHarnessExeBaseUri() 
          + modelName 
          + '/execute/' 
          + harnessName
          + '/' + testSuiteName
          + '/' + testExecutionVerdictName, {
        method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
        // if we received a valid job_id, than the job was accepted
        if ( ! data.error ) {
            pageReference.addLog(data.message);
            disableControls();
            theTimer = setInterval(getStatus, 500, pageReference, testExecutionVerdictName, enableControls, setHarnessExecuted);
        } else {
            // An error occurred
            enableControls();
            pageReference.addLog(data.message);
        }
    })
    .catch(error => {
        pageReference.addLog(error);
        enableControls();
    });

}

function CancelHarnessExecutionJob(pageReference, testExecutionVerdictName) {
    // POST a cancel-job resource
    fetch(getHarnessExeBaseUri() + testExecutionVerdictName + "/cancel", {
        method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
        if ( ! data.error && data.terminated ) {
            pageReference.addLog(data.message);
        }
    })
    .catch(error => {
        pageReference.addLog(error)
    });

    clearTimeout(theTimer);
}

function StoreVerdictToPersistentVolume(pageReference, harnessName, modelName, testSuiteName, testExecutionVerdictName) {
    // If the test-suite was selected from TM-Page, the name contains the Model Name.
    // when we want to execute tests, we must remove this name, so that the backend can find the file correctly
    testSuiteName = testSuiteName.replace(modelName+"_", "")
    
    // copy verdict-summary to PV
    fetch(getHarnessExeBaseUri() 
          + modelName 
          + '/verdict-to-pv/' 
          + harnessName
          + '/' + testSuiteName 
          + '/' + testExecutionVerdictName, {
        method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
        pageReference.addLog(data.message);
    })
    .catch(error => pageReference.addLog(error));
}

function DownloadTestExecutionVerdict(pageReference, modelName, testExecutionVerdictName) {
    fetch(getHarnessExeBaseUri() + testExecutionVerdictName + "/verdict", {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        if ( data.error ) {
            pageReference.addLog(data.message);
        } else {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(String(data.message)));
            element.setAttribute('download', modelName + "verdict.txt");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    })
    .catch(error => pageReference.addLog(error));
}

// Export functions
export { ExecuteTests, CancelHarnessExecutionJob, StoreVerdictToPersistentVolume, DownloadTestExecutionVerdict }