import React, { useContext, useEffect } from "react"
import Column from "../Components/Column";
import Row from "../Components/Row";
import Button from 'react-bootstrap/Button';
import Pre from "../Components/Pre"

import "./Context"
import { SFsmContext } from "./Context";

/**
 * React component
 */
function SFSMCenterPage({ clearLog }) {

    const ctxt = useContext(SFsmContext);

    /* 
     * On click event for clearing the log window
     */
    function onClickClearLog() {
        clearLog();
    }

    /**
     * Every time the form gets updated, the center
     * pre is scrolled down do bottom.
     */
    useEffect(() => {
        var x = document.getElementById("textVerdictSFsm")
        x.scrollTop = x.scrollHeight;
    });

    /* 
     * Main render function for the center page.
     */
    return (
        <>
            <Row>
                <Column className="col-4">
                    <h4>Output:</h4>
                </Column>
            </Row>
            <Row className="sfsmCenterRow">
                <Column className="col-12">
                        <Pre 
                            className="sfsm-log-pre" 
                            id="textVerdictSFsm">
                            { ctxt.log }
                        </Pre>
                </Column>
            </Row>
            <Row>
                <Column className="col-4">
                    <Button 
                        id="btnClearOutputFsm" 
                        onClick={ () => onClickClearLog() }
                        className="sfsm-center-clear-button"
                        variant="secondary">
                        Clear Output
                    </Button>
                </Column>
            </Row>
        </>
    );
}

export default SFSMCenterPage;