import React from "react"

const TabContent = ({ id, children }) => {
    return(
        <div className="tab-content" id={ id }>
            { children }
        </div>
    );
};

export default TabContent;