import React from "react"

const Column = ({className, style, children}) => {
    return (
        <div className={className ? className : "col"} style={ style }>
            { children }
        </div>
    );
};


export default Column;