import React from "react";

const Card = ({ headerName, style, id, children, bodyClass }) => {
    return (
        <div className="card" id={ id } style={ style }>
            <div className="card-header">
                { headerName }
            </div>
            <div className={ bodyClass ? bodyClass : "card-body"}>
                { children }
            </div>
        </div>
    );
};

export default Card;