import Column from './Components/Column';
import Row from './Components/Row';
import './ContentPage.css';
import './Documentation.css';
import Footer from './Footer';
import React, { useEffect, useState } from 'react'
import markdownFile from './libsfsmtest-readme.md'
import MarkdownView from 'react-showdown';

function Documentation() {

    const [state, setState] = useState({
        markdownFileContent: ""
    })

    const loadMarkdown = () => {
        fetch(markdownFile)
        .then(response => {
            return response.text();
        })
        .then(data => {
            if ( state.markdownFileContent === "") {
                setState({
                    markdownFileContent: data
                })
            }
        });
    }

    useEffect(() => {
        loadMarkdown()
    });

    return(
    <div>
        <div className='DocumentationPage'>
            <Row className="row header-row">
                <Column className='col-1'>
                    <img src="unilogo.jpg" width="100px" alt="Uni HB Logo"/>
                </Column>
                <Column className='col-md-11'>
                    <h2>AGBS Test Cloud - Documentation</h2>
                </Column>
            </Row>
            <Row className="row main-doc-row">
                <Column className="col-4">
                    <h3>Table of Contents</h3>
                    <div id="my-example" className="list-group">
                        <a className="list-group-item list-group-item-primary list-group-item-action" href="#intro">Introduction</a>
                        <a className="list-group-item list-group-item-action" href="#fsm-tcgen">Test-Case Generation for Finite State Machines</a>
                        <a className="list-group-item list-group-item-action" href="#sfsm-tcgen">Test-Case Generation for Symbolic Finite State Machines</a>
                        <a className="list-group-item list-group-item-action" href="#pot-ml">Machine Learning Based Property Oriented Testing</a>
                        <a className="list-group-item list-group-item-action" href="#fsm-tcexe">Test-Case Application Against a Test Harness for FSMs</a>
                        <a className="list-group-item list-group-item-action" href="#sfsm-tcexe">Test-Case Application Against a Test Harness for Symbolic FSMs</a>
                        <a className="list-group-item list-group-item-action" href="#fsm-harness-building">Test Harness-Building</a>
                        <a className="list-group-item list-group-item-danger list-group-item-action" href="#ref">References</a>
                    </div>

                    <div id="example-list" className="list-group example-listing">
                        <a className="list-group-item list-group-item-primary list-group-item-action" href="#examples">Examples</a>
                        <a className="list-group-item list-group-item-action" href="#ex1">Example of Generating Test-Cases for a Garage-Door-Controller (FSM)</a>
                        <a className="list-group-item list-group-item-action" href="#ex2">Example of Generating a test harness from C++-Sources for a FSM-Implementation</a>
                        <a className="list-group-item list-group-item-action" href="#ex3">Example of Executing a Test-Suite Against a FSM-Based Test Harness</a>
                        <a className="list-group-item list-group-item-action" href="#sfsm-tc-gen">Example of Generating a Test-Suite for symbolic Finite State Machines</a>
                        <a className="list-group-item list-group-item-action" href="#sfsm-harness-gen">Example of Generating a Test Harness From C++-Sources for a Symbolic FSM-Implementation</a>
                        <a className="list-group-item list-group-item-action" href="#sfsm-tcexe">Example of Executing a Test-Suite Against a Symbolic FSM-Based Test Harness</a>
                    </div>
                </Column>
                <Column className="col-7">
                    
                    { /* ==================================================================================================== */ }
                    <h4 id="intro">Introduction</h4>
                    <p align="justify">
                        The <b>Test-Cloud</b> of the research group <b>operating systems and distributed systems (AGBS)</b> 
                        at the Department of Mathematics and Computer Science at the
                        University of Bremen provides services for model-based testing (MBT). In particular,
                        the test-cloud supports automated test case generation from (deterministic or non-deterministic)  finite state machine (FSM) and symbolic 
                        finite state machine (SFSM) models. For FSM models, well-known complete test generation algorithms have been implemented, currently comprising 
                        the W-, the Wp-, the H-, and the SPYH-Method [1]. For SFSM models, new complete test generation methods have been developed in our research group [2]; 
                        these have been implemented and are available in the Test-Cloud as well.
                    </p>
                    
                    <p>
                        Moreover, the Test-Cloud allows to execute a generated test suite against an
                        implementation realised as a C/C++ library. The execution requires to link the SUT source code to a test
                        harness executing the test suite generated before and calling the SUT in each test step. The SUT reactions are
                        checked by the harness against the expected behaviour specified by the reference model (FSM or SFSM).
                        The test harness produces a test execution log where inputs to the SUT and the outputs observed are
                        documented. Deviations from expected (modelled) behaviour lead to failing test cases, and the
                        erroneous SUT outputs are identified.
                    </p>
                    
                    <p>
                        In the following sections, each component of the Test-Cloud will be explained.
                    </p>
                    
                    { /* ==================================================================================================== */ }
                    <h4 id="fsm-tcgen">Test-Case Generation for Finite State Machines</h4>
                    <p align="justify">
                        Complete Test-Generation algorithms exist since decades for Finite State Machines.
                        The Test-Cloud service backends apply the software library
                        <a href="https://bitbucket.org/JanPeleska/libfsmtest/src/master/">libfsmtest</a> [3]
                        developed in our research group
                        for test generation according to the W-, Wp-, H- and the SPYH-Method.
                        For generating a complete test suite, you
                        only have to upload a valid FSM model and specify parameters like Test-Case-Generation Method,
                        additional states, and how to handle FSMs that are not completely specified. After that, you can push the
                        'Generate Tests' Button to generate tests. The Test-Cloud automatically generates tests for the given
                        model with the use of the libfsmtest. When finished, a short summary is displayed in
                        the centre of the page. If you want to download the test suite, just push the 'Download Test-Suite'
                        Button, and the download will start immediately.
                    </p>
                    
                    <p>
                        A full documentation for testing against FSM models with libfsmtest can be found
                        <a href="https://bitbucket.org/JanPeleska/libfsmtest/src/master/doc/libfsmtest.pdf">here</a>. This
                        documentation also includes a description of the input-format (FSM-Format and CSV-Format), as well as a
                        documentation for the test harness.
                        The underlying theory has been described in the lecture notes 
                        <a href="http://www.informatik.uni-bremen.de/agbs/jp/papers/test-automation-huang-peleska.pdf">Test Automation</a>, which are publicly available as well.
                    </p>

                    { /* ==================================================================================================== */ }
                    <h4 id="sfsm-tcgen">Test-Case Generation for Symbolic Finite State Machines</h4>
                    <p align="justify">
                        A novel testing theory demonstrates how complete test suites can be generated from symbolic finite state
                        machines [2]. The Test-Cloud implements that approach in a way that users only need to upload
                        symbolic finite state machines in a valid file-format and   specify the number of additional
                        states that the implemented System under Test can have (in comparison to the model). Pushing the
                        button 'Generate Tests' while being in the SFSM-Test-Generation-Tab generates a complete test suite
                        for such models. When the generation process is terminated, a short test suite generation
                        summary is displayed in the centre
                        of the page. If you want to download the full test suite, just push the 'Download Test-Suite' button while
                        in the SFSM-Test-Generation-Tab.
                    </p>

                    <Row className="row">
                        <Column className="col-12">
                            <MarkdownView
                                markdown={state.markdownFileContent}
                                options={{ tables: true, emoji: true, headerLevelStart: 3 }}>

                                </MarkdownView>
                        </Column>
                    </Row>

                    { /* ==================================================================================================== */ }
                    <h4 id="pot-ml">Machine Learning Based Property Oriented Module Testing</h4>
                    <p align="justify">
                        In this section, our property-oriented grey box checking tool [6, 7], based on the 
                        <i> black box checking</i> approach outlined by Peled et. al. [4], is described. Given an 
                        LTL property &phi;, tests are executed to learn the true behavior of the implementation 
                        under test (IuT). The learnt behavior is expressed as a <i> symbolic finite state machine</i> (SFSM). 
                        While trying to learn the true behavior of the IuT, property violations are checked by a monitor 
                        verifying the reactions of the IuT to test case inputs. Additionally, violations are checked 
                        during model checking of the learnt model. If the language equivalence of the IuT and the learnt 
                        model is proven by applying complete testing methods, the verification campaign terminates. <br/>

                        For model learning, the learning algorithm from Vaandrager et. al. [5] is applied, which can be used 
                        to learn a <i> finite state machine</i> (FSM) with inputs and outputs, commonly referred to as <i>Mealy machines</i>. 
                        Since the domain of input/output variables of a software module is often quite large (<tt>float</tt>, 
                        <tt>double</tt>, <tt>64-bit int</tt>), input/output values are partitioned to make learning feasible. 
                        Through abstraction, the learning  algorithm can then learn finitely many control states, guard conditions 
                        and output expressions labelling the transitions in the <i>symbolic finite state machine</i> (SFSM) representing 
                        the true behaviour of the IuT. It's worth noting that identical input/output value pairs <i>(x,y)</i> may not 
                        identify guards in the IuT in a unique way, so that guards must be expressed <i>symbolically</i> by first-order 
                        expressions specifying control decisions and output expressions without enumerating all possible values of 
                        the input/output variable types.<br/>

                        Our property-oriented grey box checking tools implements three fundamental phases:<br/>

                        <Column>
                            <Row className="row phase-row">
                                <p><i>Setup</i></p>
                                <p>
                                    In the first phase, the symbolic alphabet is computed from guard conditions and output expressions 
                                    derived from the IuT. Gray box knowledge of the IuT is leveraged in order to abstract from possible 
                                    infinite input and output domain to finitely many equivalence classes. Next, the property monitor 
                                    is constructed based on the negation of the property &phi;, denoted by &not;&phi;.
                                </p>
                            </Row>

                            <Row className="row phase-row">
                                <p><i>Fuzzer-Guided Exploration</i></p>
                                <p>
                                    In the second phase, coverage guided fuzzing is executed in order to rapidly identify a large number 
                                    of distinct states of the IuT. The well-known <tt>libfuzzer</tt> library is utilised for this purpose. 
                                    Fuzzing is used to create a meaningful observation tree from the input/outputs behavior observed of 
                                    the IuT to speed up the learning phase in the subsequent step. The input from fuzzer is used to randomly 
                                    select an input equivalence class from the precomputed set of equivalence classes. While applying a 
                                    valuation of the selected equivalence class to the IuT, the property monitor checks IuT reactions and 
                                    uncovers violations of safety properties by identifying a finite accepting trace fulfilling &not;&phi;. 
                                    From the input/output behavior of the IuT, an observation tree is created.
                                </p>
                            </Row>

                            <Row className="row phase-row">
                                <p><i>Learning Phase</i></p>
                                <p>
                                    In the third phase, learning is performed by repeatedly generating FSM hypotheses over the IuT's input 
                                    and output alphabet and checking whether the refined FSM hypotheses correspond to the behavior of the 
                                    IuT. For learning, the L# learning algorithm developed by Vaandrager et al. [5] is used to learn a 
                                    finite state machine from observation by considering the apartness of observed traces. If an FSM 
                                    hypothesis was found, model checking is performed to check for property violations. If no violation 
                                    was found, the tools proceeds to test whether the FSM hypothesis is equivalent to the IuT using 
                                    the complete H-Method test strategy for the FSM hypothesis. If the hypothesis FSM is equivalent 
                                    to the IuT, <tt>PASS</tt> is returned and the program terminates. If <tt>FAIL</tt> is returned, 
                                    the counterexample is utilised to generate a new hypothesis FSM.
                                </p>
                            </Row>
                        </Column>
                    </p>

                    <h4 id="pot-ml-cs">Case Study: Brake Controller</h4>
                    <p align="justify">
                        Consider the C function <tt>Brake()</tt> as shown in Listing 1, which implements the fictitious example of an 
                        automated braking function that could be applied in autonomous cars or trains. As input parameter, <tt>Brake()</tt> gets 
                        the actual vehicle speed <tt>x</tt> of type <tt>float</tt> and produces a brake force <tt>y</tt> as output (denoted as 
                        pointer parameter) of type <tt>float</tt>. The maximum possible vehicle speed is 400[km/h], so that x &isin; [0, 400]. <br/>

                        The actual vehicle speed <i>should not</i> exceed <tt>vmax = 200[km/h]</tt>. In the initial control state <tt>s0</tt>, as 
                        long as the vehicle speed <tt>x &lt; vmax</tt>, the brake force output y &isin;&#8477;<sub>	&#8805;0</sub> is set to 
                        0 (line 11). If the vehicle speed exceeds <tt>vmax</tt>, a brake force is applied, calculated according to Equation [1] and 
                        the function transits to control state <tt>s2</tt>. Considering the maximum possible vehicle speed x &isin;[0, 400], the 
                        maximum brake force that could be applied is <i>4</i>, so that y &isin; [0, 4].

                        <Row className="row equation-row">
                            <Column className="col-12">
                                <img src="resources/eq1.png" className="img-fluid" alt="Responsive image"></img> { /* eslint-disable-line jsx-a11y/img-redundant-alt */ }
                            </Column>
                            <Column className="col-12">
                                Equation 1: Calculate brake force <tt>y</tt>.
                            </Column>
                        </Row>

                        <br/>

                        In control state <tt>s0</tt>, if <tt>x</tt> is exactly <tt>vmax</tt>, the function <tt>brakeOnVmax()</tt> checks, if a brake 
                        force calculated by function <tt>calcBrakeForceVmax()</tt> should be applied. Without specifying the detailed 
                        code of the sub-function <tt>calcBrakeForceVmax()</tt>, we assume that it always returns a value in range [0.9, 1.1]. 
                        If function <tt>brakeOnVmax()</tt> returns true, a brake force calculated by function <tt>calcBrakeForceVmax()</tt> is 
                        applied and the <tt>Brake()</tt> function switches to control state <tt>s1</tt>. If function <tt>brakeOnVmax()</tt> returns 
                        false, the function remains in state <tt>s0</tt>. The <tt>Brake()</tt> function remains in control state <tt>s1</tt>, as long 
                        as the vehicle speed coincides exactly  with the limit <tt>vmax</tt>. A brake force is applied, which is calculated by 
                        function <tt>calcBrakeForceVmax()</tt>. If the vehicle speed is <tt>x&lt;vmax</tt>, no brake force is applied (<tt>y = 0</tt>) 
                        and the function switches to control state <tt>s0</tt>. If the vehicle speed exceeds <tt>vmax</tt>, the brake force is 
                        calculated according to Equation [1] and the function transits to control state <tt>s2</tt>. The function stays 
                        in control state <tt>s2</tt> while <tt>x &#8805; vmax-delta</tt>. Otherwise, the function transits to control state 
                        <tt> s0</tt> and no brake force is applied again.

                        <Row className="row listing-row">
                            <Column className="col-12">
                                <pre>
                                    <code>
                                        extern&nbsp;bool&nbsp;brakeOnVmax(void);<br/>
                                        extern&nbsp;float&nbsp;calcBrakeForceVmax(void);<br/>
                                        const&nbsp;float&nbsp;c&nbsp;=&nbsp;100.0,&nbsp;vmax&nbsp;=&nbsp;200.0,&nbsp;delta&nbsp;=&nbsp;10.0;<br/>
                                        typedef&nbsp;enum&nbsp;&#123;s0,&nbsp;s1,&nbsp;s2&#125;&nbsp;state_t;<br/>
                                        <br/>
                                        void&nbsp;Brake(float&nbsp;x,&nbsp;float*&nbsp;y)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;static&nbsp;state_t&nbsp;s&nbsp;=&nbsp;s0;&nbsp;//&nbsp;Internal&nbsp;state&nbsp;of&nbsp;Brake()<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;switch&nbsp;(s)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;case&nbsp;s0&nbsp;:&nbsp;if&nbsp;(&nbsp;x&nbsp;&lt;&nbsp;vmax&nbsp;)&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;0;&nbsp;s&nbsp;=&nbsp;s0;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;if&nbsp;(&nbsp;x&nbsp;&gt;&nbsp;vmax&nbsp;)&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;x/c;&nbsp;s&nbsp;=&nbsp;s2;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;if&nbsp;(&nbsp;brakeOnVmax()&nbsp;)&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;calcBrakeForceVmax();&nbsp;s&nbsp;=&nbsp;s1;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;0;&nbsp;s&nbsp;=&nbsp;s0;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;case&nbsp;s1:&nbsp;&nbsp;if&nbsp;(&nbsp;x&nbsp;&lt;&nbsp;vmax&nbsp;)&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;0;&nbsp;s&nbsp;=&nbsp;s0;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;if&nbsp;(&nbsp;x&nbsp;&gt;&nbsp;vmax&nbsp;)&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;x/c;&nbsp;s&nbsp;=&nbsp;s2;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;calcBrakeForceVmax();&nbsp;s&nbsp;=&nbsp;s1;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;case&nbsp;s2:&nbsp;&nbsp;if&nbsp;(&nbsp;x&nbsp;&lt;&nbsp;vmax&nbsp;-&nbsp;delta&nbsp;)&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;0;&nbsp;s&nbsp;=&nbsp;s0;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;&nbsp;&#123;&nbsp;*y&nbsp;=&nbsp;x/c;&nbsp;s&nbsp;=&nbsp;s2;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &#125;
                                    </code>
                                </pre>
                                <h5 className='listing-caption'>Listing 1: C code of a braking function.</h5>
                            </Column>
                        </Row>

                        For module test, the original code of functions <tt>brakeOnVmax()</tt> and <tt>calcBrakeForceVmax()</tt> is not required. 
                        Instead, they are introduced as function stubs in the testing environment. To control the outputs of the function 
                        <tt>brakeOnVmax()</tt>, the boolean input <tt>aux1</tt> is introduced that can be set to <tt>true</tt> or <tt>false</tt> by the 
                        testing environment. Similarly, to control the output of the function <tt>calcBrakeForceVmax()</tt>, the auxiliary float 
                        input variable <tt>aux2</tt> with range [0.9, 1.1] is introduced. The implemented function stubs are displayed in 
                        Listing 2.

                        <Row className="row listing-row">
                            <Column className="col-12">
                                <pre>
                                    <code>
                                    &#047;&#047;&nbsp;globals&nbsp;to&nbsp;be&nbsp;set&nbsp;by&nbsp;the&nbsp;test&nbsp;wrapper&nbsp;as<br/>
                                    &#047;&#047;&nbsp;auxiliary&nbsp;inputs<br/>
                                    bool&nbsp;xaux1;<br/>
                                    float&nbsp;xaux2;<br/>
                                    <br/>
                                    &#047;&#047;&nbsp;Function&nbsp;stub&nbsp;for&nbsp;brakeOnVmax()<br/>
                                    bool&nbsp;brakeOnVmax(void)&nbsp;&#123;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;return&nbsp;xaux1;<br/>
                                    &#125;<br/>
                                    &#047;&#047;&nbsp;Function&nbsp;stub&nbsp;for&nbsp;calcBrakeForceVmax()<br/>
                                    float&nbsp;calcBrakeForceVmax(void)&nbsp;&#123;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;return&nbsp;xaux2;<br/>
                                    &#125;
                                    </code>
                                </pre>
                                <h5 className='listing-caption'>Listing 2: Stub file for the sub-functions of <tt>Brake{}</tt> shown in Listing 1.</h5>
                            </Column>
                        </Row>
                    </p>

                    <h5 id="pot-ml-sf"><b>Symbol File Construction</b></h5>
                    <p align="justify">
                        Listing 3 shows the <i>symbol file</i> of the <tt>Brake()</tt> function. The symbol file provides the test 
                        system with crucial information about the IuT. While currently, the symbol file must be manually created, 
                        automation can be achieved with the use of static analysis tools.<br/>

                        The symbol file's section <tt>identifiers</tt>, input and output variables, as well as constant variables 
                        are introduced. For the <tt>Brake()</tt> function, vehicle speed input <tt>x</tt>, as well as the auxiliary 
                        variables <tt>aux1 aux2</tt> for the stubbed functions are denoted. Here, constant variables, like <tt>vmax </tt> 
                        (maximum allowed speed), <tt>c</tt> (divisor for the brake force calculation) and <tt>delta</tt> (braking threshold) 
                        are denoted as inputs as well. Moreover, the output variable y of the <tt>Brake()</tt> function is introduced. <br/>
                        
                        For input, invariants are defined in the symbol file's <tt>invariants</tt> section. The defined variable range of input 
                        variable <tt>x</tt> and input <tt>aux1</tt> is set to the appropriate range [0, 400] and [0.9, 1.1], respectively. 
                        Constant values are assigned in form <tt>(= &lt;symbol&gt; &lt;value&gt;)</tt>. <br/>

                        All guard conditions over the input symbols in the program's control flow graph are described in the symbol 
                        file's section <tt>inputsymbols</tt>. For the braking function, all conditions over the input symbols in the 
                        <tt> if</tt>-statements are described here, as well as possible values for the auxiliary variables <tt>aux1</tt> 
                        and <tt>aux2</tt> in order to guide the SMT solver to select various elements of <tt>aux1</tt> and <tt>aux2</tt>. 
                        Note that conditions over internal state variables must not be specified here, since the internal state 
                        is automatically identified during learning phase. All output expressions of the IuT must be described in 
                        symbol file's section <tt>outputsymbols</tt> to denote each output variable assignment that the program 
                        <i> could</i> produce. Finally, an estimation of the upper bound of the number of distinguishable internal 
                        control states of the IuT must be specified in section <tt>#states</tt> of the symbol file. This is required 
                        to ensure completeness of the test suites used during the learning process.

                        <Row className="row listing-row">
                            <Column className="col-12">
                                <pre>
                                    <code>
                                        identifiers<br/>
                                        input&nbsp;real&nbsp;x<br/>
                                        input&nbsp;real&nbsp;xaux1<br/>
                                        input&nbsp;bool&nbsp;xaux2<br/>
                                        output&nbsp;real&nbsp;y<br/>
                                        input&nbsp;real&nbsp;vmax<br/>
                                        input&nbsp;real&nbsp;c<br/>
                                        input&nbsp;real&nbsp;delta<br/>
                                        <br/>
                                        invariants<br/>
                                        (and&nbsp;(&gt;=&nbsp;x&nbsp;0)&nbsp;(&lt;=&nbsp;x&nbsp;400))<br/>
                                        (and&nbsp;(&gt;=&nbsp;xaux1&nbsp;0.9)&nbsp;(&lt;=&nbsp;xaux1&nbsp;1.1))<br/>
                                        (=&nbsp;vmax&nbsp;200.0)<br/>
                                        (=&nbsp;c&nbsp;100.0)<br/>
                                        (=&nbsp;delta&nbsp;10.0)<br/>
                                        <br/>
                                        inputsymbols<br/>
                                        (&lt;&nbsp;x&nbsp;vmax)<br/>
                                        (&gt;&nbsp;x&nbsp;vmax)<br/>
                                        (&lt;&nbsp;x&nbsp;(-&nbsp;vmax&nbsp;delta))<br/>
                                        (=&nbsp;xaux2&nbsp;true)<br/>
                                        (=&nbsp;xaux2&nbsp;false)<br/>
                                        (and&nbsp;(&gt;=&nbsp;xaux1&nbsp;0.9)&nbsp;(&lt;=&nbsp;xaux1&nbsp;1.1))<br/>
                                        <br/>
                                        outputsymbols<br/>
                                        (=&nbsp;y&nbsp;0)<br/>
                                        (=&nbsp;y&nbsp;(/&nbsp;x&nbsp;c))<br/>
                                        (=&nbsp;y&nbsp;xaux1)<br/>
                                        <br/>
                                        #states<br/>
                                        3
                                    </code>
                                </pre>
                                <h5 className='lsiting-caption'>Listing 3: Symbol File of a braking function.</h5>
                            </Column>
                        </Row>
                    </p>

                    <h5 id="pot-ml-sf"><b>Property Definition</b></h5>
                    <p align="justify">
                        <Row>
                            <Row>
                                <Column className="col-1">
                                    <Row className="row-1">
                                        <b>R1.</b>
                                    </Row>
                                </Column>
                                <Column>
                                    <Row>
                                        <p>As long as the value of <tt>x</tt> does not exceed threshold <tt>vmax</tt>, any braking intervention will apply a force that is less or equal to 1.1.</p>
                                    </Row>
                                </Column>
                            </Row>
                            <Row>
                                <Column className="col-1">
                                    <Row className="row-1">
                                        <b>R2.</b>
                                    </Row>
                                </Column>
                                <Column>
                                    <Row>
                                        <p>Whenever a brake force greater or equal than 1.9 is applied, this will also be the case in the next step, unless the actual speed is decreased below <tt>vmax</tt>-<tt>delta</tt>.</p>
                                    </Row>
                                </Column>
                            </Row>
                        </Row>

                        In LTL, these requirements are formalised by the formulas

                        <Row className="row equation-row">
                            <Column className="col-12">
                                <img src="resources/properties.png" className="img-fluid" alt="LTL properties"></img> { /* eslint-disable-line jsx-a11y/img-redundant-alt */ }
                            </Column>
                        </Row>
                            <br/>
                        The two properties &phi;<sub>1</sub> and &phi;<sub>2</sub> are specied in SMT-Lib syntax, as shown in Listing 4 and Listing 5, respectively.
                        
                        <Row className="row listing-row">
                            <Column className="col-12">
                                <pre>
                                    <code>
                                        (W&nbsp;(&lt;=&nbsp;y&nbsp;1.1)&nbsp;(&gt;&nbsp;x&nbsp;vmax))
                                    </code>
                                </pre>
                                <h5 className='listing-caption'>Listing 4: Content of property file for property &phi;<sub>1</sub>.</h5>
                            </Column>
                        </Row>

                        <Row className="row listing-row">
                            <Column className="col-12">
                                <pre>
                                    <code>
                                    (G&nbsp;(implies&nbsp;(&gt;=&nbsp;y&nbsp;1.9)&nbsp;(X&nbsp;(or&nbsp;(&gt;=&nbsp;y&nbsp;1.9)&nbsp;(&lt;&nbsp;x&nbsp;(-&nbsp;vmax&nbsp;delta))))))
                                    </code>
                                </pre>
                                <h5 className='listing-caption'>Listing 5: Content of property file for property &phi;<sub>2</sub>.</h5>
                            </Column>
                        </Row>
                    </p>

                    <h5 id="pot-ml-sf"><b>Test Wrapper</b></h5>
                    <p align="justify">
                        A <tt>test wrapper</tt> implements the interface between the testing environment and the IuT. Its main purpose 
                        is to map input symbol valuations to concrete IuT inputs and map concrete IuT outputs to symbolic outputs. 
                        In the test wrapper, it is required to implement functions <tt>sut_init()</tt>, <tt>sut_reset()</tt> and <tt>sut()</tt>. 
                        Function <tt>sut_init()</tt> (Listing 6) should initialise the IuT and function 
                         <tt> sut_reset()</tt> (Listing 7) should reset the IuT to the unique initial state. 
                        The function <tt>sut()</tt> (Listing 8) is used by the test engine to apply a tuple of input 
                        symbols to the IuT (map from valuation to concrete inputs) and observe the IuT output to map the concrete output 
                        back into symbolic form.

                        <Row className="row listing-row">
                            <Column className="col-12">
                                <pre>
                                    <code>
                                    void&nbsp;sut_init()&nbsp;&#123;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;reset();<br/>
                                    &#125;<br/>
                                    </code>
                                </pre>
                                <h5 className='listing-caption'>Listing 6: Test wrapper function <tt>sut_init()</tt>.</h5>
                            </Column>
                        </Row>

                        <Row className="row listing-row">
                            <Column className="col-12">
                                <pre>
                                    <code>
                                    void&nbsp;sut_reset()&nbsp;&#123;<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;reset();<br/>
                                    &#125;<br/>
                                    </code>
                                </pre>
                                <h5 className='listing-caption'>Listing 7: Test wrapper function <tt>sut_reset()</tt>.</h5>
                            </Column>
                        </Row>

                        <Row className="row listing-row">
                            <Column className="col-12">
                                <pre>
                                    <code>
                                        std::map&lt;SutVariable,&nbsp;Value&gt;&nbsp;sut(libfsmtest::SFSMs::Valuation&nbsp;const&nbsp;&input)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;float&nbsp;x&nbsp;=&nbsp;0;<br/>
                                        <br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;for(auto&nbsp;const&nbsp;&valuationElement&nbsp;:&nbsp;input)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;auto&nbsp;const&nbsp;&[identifier,&nbsp;value]&nbsp;=&nbsp;valuationElement;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;auto&nbsp;const&nbsp;&[name,&nbsp;type]&nbsp;=&nbsp;identifier;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if(type.getLocation()&nbsp;!=&nbsp;libfsmtest::SFSMs::IdentifierType::LocationInModel::INPUT)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;continue;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;std::visit([&,&name=name](auto&nbsp;const&nbsp;&val)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if&nbsp;(&nbsp;name&nbsp;==&nbsp;&quot;x&quot;&nbsp;)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;x&nbsp;=&nbsp;val;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;if&nbsp;(&nbsp;name&nbsp;==&nbsp;&quot;xaux1&quot;&nbsp;)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xaux1&nbsp;=&nbsp;val;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;if&nbsp;(&nbsp;name&nbsp;==&nbsp;&quot;xaux2&quot;&nbsp;)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//&nbsp;here&nbsp;for&nbsp;C,&nbsp;bool&nbsp;to&nbsp;int<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xaux2&nbsp;=&nbsp;val&nbsp;?&nbsp;1&nbsp;:&nbsp;0;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;if&nbsp;(&nbsp;name&nbsp;==&nbsp;&quot;vmax&quot;&nbsp;)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vmax&nbsp;=&nbsp;val;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;if&nbsp;(&nbsp;name&nbsp;==&nbsp;&quot;c&quot;&nbsp;)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c&nbsp;=&nbsp;val;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;if&nbsp;(&nbsp;name&nbsp;==&nbsp;&quot;delta&quot;&nbsp;)&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;delta&nbsp;=&nbsp;val;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else&nbsp;&#123;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;throw&nbsp;std::runtime_error(&quot;Invalid&nbsp;input:&nbsp;&quot;&nbsp;+&nbsp;name&nbsp;+&nbsp;&quot;&nbsp;=&nbsp;&quot;&nbsp;+&nbsp;std::to_string(val));<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,&nbsp;getValueInType(valuationElement));<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                                        <br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;/*&nbsp;step&nbsp;SUT&nbsp;*/<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;float&nbsp;y;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Brake(x,&nbsp;&y);<br/>
                                        <br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;/*&nbsp;Outputs&nbsp;*/<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;std::map&lt;SutVariable,&nbsp;Value&gt;&nbsp;sfsmOutputs;<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;sfsmOutputs[&quot;y&quot;]&nbsp;=&nbsp;std::to_string(y);<br/>
                                        <br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;return&nbsp;sfsmOutputs;<br/>
                                        &#125;<br/>
                                    </code>
                                </pre>
                                <h5 className='listing-caption'>Listing 8: Test wrapper function <tt>sut()</tt>.</h5>
                            </Column>
                        </Row>
                    </p>

                    <h5 id="pot-ml-sf"><b>Building Test Harness</b></h5>
                    <p align="justify">
                        For building a complete harness, it is necessary to build this in a Test-Cloud-compatible way: the 
                        Test-Cloud uses Ubuntu Linux 22.04. For this platform the harness has to be compiled and linked. <br/>

                        In general, the Test-Cloud Linux version is not the operating system that is installed on your 
                        local host computer. Therefore, a docker image is provided that enables you to build a compatible
                        harness-binary which can be uploaded to the Test-Cloud. To work with docker images on your local 
                        computer, Docker Desktop <a href='https://www.docker.com/products/docker-desktop/'>Docker Desktop</a> 
                        needs to be installed. Then you can download the docker image from the Docker Hub Docker Registry, 
                        as described below. After that you need to map the directory containing the implemented IuT, a 
                        wrapper file <tt>sut_wrapper.cpp</tt>, the harness <tt>testharness.cpp</tt> and the appropriate 
                        Makefile to link and build the harness and the IuT, to the docker container. The Makefile is then 
                        executed in the container to create a harness-binary for the target platform in the cloud. 
                        This harness-binary can be uploaded to the Test-Cloud. <br/>

                        For this <tt>Brake()</tt> example, the example project can be downloaded here: 
                        <a href='examples/brake-case-study.zip'>Brake Project</a>. <br/>
                        
                        <div className='pot-step-description'>
                            The following steps describe the workflow:<br/>

                            <ol>
                                <li><b>Install Docker Desktop</b>: 
                                    Ensure Docker Desktop is installed on your local computer. You can download it from here:
                                    <a href='https://www.docker.com/products/docker-desktop/'>Docker Desktop</a>.
                                </li>

                                <li><b>Download Docker Image</b>:
                                    Pull the docker container from docker hub. Run the following command in your terminal:<br/>
                                    <Row className="row">
                                        <Column className="col-12">
                                            <pre>
                                                <code>
                                                    $ docker pull agbstestcloud/harness-builder:latest
                                                </code>
                                            </pre>
                                        </Column>
                                    </Row>
                                </li>

                                <li><b>Run and Map Directory to Docker Container</b>:
                                    Run the following command in your terminal:
                                    <Row className="row">
                                        <Column className="col-12">
                                            <pre>
                                                <code>
                                                $ docker run --volume &lt;path-to-project-folder&gt;:/workspace:rw agbstestcloud/harness-builder:latest
                                                </code>
                                            </pre>
                                        </Column>
                                    </Row>
                                </li>

                                <li> <b>Build the Harness</b>
                                    Execute <tt>make</tt> command inside the docker container:
                                    <Row className="row">
                                        <Column className="col-12">
                                            <pre>
                                                <code>
                                                $ make
                                                </code>
                                            </pre>
                                        </Column>
                                    </Row>
                                </li>
                            </ol>
                        </div>

                        Please make sure that the correct Makefile is used so that the IuT is build with the correct 
                        build flags <tt>-fsanitize=fuzzer</tt> and with the <tt>clang</tt> compiler.
                    </p>

                    <h5 id="pot-ml-sf"><b>Upload and Execute Test on the Test Cloud</b></h5>
                    <p align="justify">
                        On the main page of the <a href='https://fsmtestcloud.informatik.uni-bremen.de/'>Test Cloud</a>, go to tab 
                        <i> POT-Testing</i>. On the right hand side you can see the upload forms to upload the harness and the symbol 
                        file (here called <i>fault domain</i>). Please upload the compiled test harness to the test cloud via tab 
                        <i> Harness</i> and upload the fault domain via tab <i>FD</i>. The upload is started by pressing button 
                        <i> Upload Harness</i> and <i> Upload Fault Domain</i>, respectively. In the same tab group, there exists a 
                        example-tab containing example projects. To upload property files, navigate to the menu on the left hand 
                        side in the POT-Testing tab and select a directory from your host computer containing property files. 
                        Finally, upload a selected property by pressing button <i> Upload Property</i>.<br/>

                        The property check is started, if the button <i> Execute Harness</i> in the top center is pressed. 
                        The text field in the center displays relevant status information and the final verdict. The final test 
                        log can be downloaded by pressing the button <i> Download Verdict</i>. If the test execution appears to 
                        be stuck, it can be interrupted by pressing button <i> Kill Job</i>.
                    </p>







                    
                    { /* ==================================================================================================== */ }
                    <h4 id="fsm-tcexe">Test-Case Application Against a Test Harness for FSMs</h4>
                    <p align="justify">
                        Test suites generated in the Test-Cloud can also be executed against SUTs implementing the
                        FSM reference model. For this purpose, a <b>test harness</b> is required, executing the test suite against the
                        SUT and checking the observed results against the expected results derived from the model
                        (<b>test oracle</b> function). Moreover, the harness refines FSM input events to concrete SUT inputs before
                        calling the SUT and abstracts SUT outputs back to FSM output events before checking the SUT output
                        against the output expected according to the FSM model.
                        A   harness is provided by the libfsmtest [3]. This harness provides basic
                        interfaces to the SUT and a template 'sut_wrapper.cpp' for implementing input refinement and output
                        abstraction. This template has to be instantiated for every new SUT interface. Harness, wrapper, and SUT code
                        are compiled and bound to create an executable (called again harness). This harness reads the
                        test suite test case by test case. Each case is executed by calling the SUT with each input contained
                        in the test case and checking each output for consistency with the FSM reference model.
                        The libfsmtest harness executes the whole test suite against the SUT. In the Test-Cloud,  a modified
                        harness is used that applies only a range of test cases of the Test-Suite against the SUT,
                        such that parallel test case-execution is
                        possible by running several harness instances in parallel, controlled by a load balancing service.
                        Therefor, a large test suite execution against a system under test is
                        much faster in the cloud, when compared to an  execution on a single computer.
                    </p>
                
                    <p align="justify">
                        To perform a test harness execution with a generated test suite against a system under test,
                        it is first necessary to upload a Test-Cloud compatible harness binary. This is achieved by
                        pressing the 'Browse for Harness' button. After selecting the harness and pushing
                        the 'Upload test harness' button,
                        the harness-binary will be uploaded to the cluster's persistent volume.  If a test suite is available and
                        the test harness upload was successful, you can press 'Execute Tests' while being in the FSM-Verification-Tab.
                        Now the testcase execution job starts. If the test case execution terminates, a summarising verdict is 
                        shown in the centre at the output-log of the website. You can also download the whole test-verdict by 
                        pushing the button 'Download Verdict' while being in 'FSM-Verification' Tab. The download will start 
                        immediately.
                    </p>

                    { /* ==================================================================================================== */ }
                    <h4 id="fsm-harness-building">Test Harness Building</h4>
                        <p align="justify">
                            For building a complete harness with a SUT implementing a reference FSM, it is necessary to build
                            this in a Test-Cloud-compatible way. The Test-Cloud uses Ubuntu Linux 22.04. For this platform the harness has to be compiled and linked.
                        </p>
                        
                        <p align="justify">
                            In general, the Test-Cloud  Linux version is  not the operating system that is installed on your local host computer. Therefore, a docker image is provided that enables you to build
                            a compatible harness-binary which can be uploaded to the Test-Cloud.
                            To work with docker images on your local computer,  <a href="https://www.docker.com/products/docker-desktop/">Docker Desktop</a> needs to be installed.
                            
                            Then you can download the
                            docker image from the Docker Hub Docker Registry, as described below. After that you need to map the directory containing the implemented
                            SUT, a wrapper file <code>sut_wrapper.cpp</code>, the harness <code>harness.cpp</code> and a <code>Makefile</code> to link and build the harness and the SUT, to the docker
                            container. The Makefile is then executed in the container to create a harness-binary for
                            the target platform in the cloud. This harness-binary can be uploaded to the Test-Cloud.
                        </p>

                    <br />
                        The following steps describe the workflow:
                    <br />
                        
                    <ol>
                        <li>
                            Pull the latest Docker image from Docker Hub Docker-Registry.<br />
                            <pre><code>$ docker pull agbstestcloud/harness-builder:latest</code></pre>
                        </li>
                        
                        <li>
                            <p align="justify">
                                Place the SUT source code, the <code>harness.cpp</code> file, the <code>sut_wrapper.cpp</code> file and the Makefile into a directory
                                with absolute path <code>&lt;path-to-sut-folder&gt;</code>.
                            </p>  
                        </li>
                        
                        <li>
                            <p align="justify">
                                Run the Docker container as described below. This command will map your source code directory 
                                <code>&lt;path-to-sut-folder&gt;</code> to the container directory <code>/workspace</code>, so that the SUT 
                                code can be accessed from the docker container, when compiling and linking the harness. The container gets read and write access 
                                to directory <code>&lt;path-to-sut-folder&gt;</code>. Therefore, it can place the created harness program into this directory as well. The <code>&lt;path-to-makefile&gt;</code>
                                points to the folder where the Makefile exists relative to the <code>&lt;path-to-sut-folder&gt;</code>. If the SUT directory includes a Makefile,
                                the <code>&lt;path-to-makefile&gt;</code> is <code>.</code>.
                            </p>
                            <pre><code>$ docker run --volume &lt;path-to-sut-folder&gt;:/workspace:rw agbstestcloud/harness-builder:latest &lt;path-to-makefile&gt;</code></pre>
                        </li>
                        
                        <li>
                            In the <code>&lt;path-to-sut-folder&gt;</code> (see step 2), the generated harness executable 'harness' can be found in your host file system. This executable is then 
                            uploaded to the Test-Cloud.
                        </li>
                    </ol>

                    { /* ==================================================================================================== */ }
                    <h5>Start the Harness-Builder in Interactive-Mode</h5> 
                        <p align="justify">
                            It is also possible to start the docker-container interactively to change files inside the container. A bash-prompt can be 
                            opened inside the docker-container as described below.
                        </p>
                        <pre><code>$ docker run -i -t --entrypoint "/bin/bash" --volume &lt;path-to-sut-directory&gt;:/workspace:rw agbstestcloud/harness-builder:latest</code></pre>
                        <p align="justify">
                            The docker-flags <code>-i</code> runs the docker-container in interactive-mode and the <code>-t</code> opens a new tty for 
                            the user into the container. The flag <code>--entrypoint "/bin/bash"</code> overrides the main container entrypoint to 
                            <code>/bin/bash</code>, so the interactive session starts at the beginning a new bash-prompt. After executing the command 
                            above, a new bash prompt is shown as described below.
                        </p>
                        <pre>
                            <code>
                                ####################################################<br/>
                                Welcome to the AGBS Harness-Builder <br/>
                                
                                You are: hbu (TLA for harness-builder-user) <br/>
                                Password for hbu is 'hbu' <br/>
                                Home: /home/hbu <br/>
                                SUT-Workspace: /workspace<br/>
                                Libraries: /libs<br/>
                                -&gt; /libs/libfsmtest<br/>
                                -&gt; /libs/libsfsmtest <br/>
                                Available Editors: <br/>
                                -&gt; vim <br/>
                                -&gt; GNU nano <br/>
                                -&gt; GNU Emacs <br/>
                                Build-Suite: <br/>
                                -&gt; GCC <br/>
                                -&gt; G++ <br/>
                                -&gt; Clang <br/>
                                -&gt; make <br/>
                                -&gt; cmake <br/>
                                If you want to install new software:  <br/>
                                -&gt; $ sudo apk add &lt;package&gt; (hbu password is 'hbu')<br/>
                                ####################################################<br/>
                                hbu@harness-builder /workspace$<br/>
                            </code>
                        </pre>
                
                        <p align="justify">
                            A short welcome-message is shown to give a brief overview of useful information of the container. For example is shown, 
                            where the libraries are located and which editors are installed. The current working directory of bash-prompt is the 
                            <code>/workspace</code> directory where the SUT-Directory from the host was mapped to.
                        </p>
                    
                    { /* ==================================================================================================== */ }
                    <h3 id="examples">Examples</h3>
                    <h4 id="ex1">Example of Generating Test-Cases for a Garage-Door-Controller (FSM)</h4>
                        <p align="justify">
                            In the 'FSM-Verification'-Tab there exists a examples section on the right hand side which contains a 
                            Model named GDC (abbr. for Garage door Controller). This model is depicted in the following image:
                        </p>

                        <Row className="row gdc-image-row">
                            <Column className="col-12">
                                <img src="resources/gdc.png" className="img-fluid" alt="Responsive image"></img> { /* eslint-disable-line jsx-a11y/img-redundant-alt */ }
                            </Column>
                        </Row> 
                        
                        <br/>The Model-Code is the following, separated into four files (FSM-Format): <br/> <br/>
                        <Row>
                            <Column className="col-3">
                                <h5>GDC.fsm</h5>
                                <pre>
                                    <code>
                                        0 0 1 4<br/>
                                        0 1 0 0<br/>
                                        0 2 0 0<br/>
                                        0 3 0 0<br/>
                                        1 0 2 5<br/>
                                        1 1 0 1<br/>
                                        1 2 0 1<br/>
                                        1 3 0 1<br/>
                                        2 0 1 4<br/>
                                        2 1 0 2<br/>
                                        2 2 0 2<br/>
                                        2 3 0 2<br/>
                                        3 0 2 5<br/>
                                        3 1 0 3<br/>
                                        3 2 0 3<br/>
                                        3 3 0 3<br/>
                                        4 0 3 2<br/>
                                        4 1 3 1<br/>
                                        4 2 0 4<br/>
                                        4 3 4 5<br/>
                                        5 0 3 3<br/>
                                        5 1 0 5<br/>
                                        5 2 3 0<br/>
                                        5 3 0 5<br/>
                                    </code>
                                </pre>
                            </Column>
                            <Column className="col-3">
                                <h5>GDC.state</h5>
                                <pre>
                                    <code>
                                        DoorUp<br/>
                                        DoorDown<br/>
                                        Doorstoppedgoingdown<br/>
                                        Doorstoppedgoingup<br/>
                                        Doorclosing<br/>
                                        Dooropening<br/>
                                    </code>
                                </pre>
                            </Column>
                            <Column className="col-3">
                                <h5>GDC.in</h5>
                                <pre>
                                    <code>
                                        e1<br/>
                                        e2<br/>
                                        e3<br/>
                                        e4<br/>
                                    </code>
                                </pre>
                            </Column>
                            <Column className="col-3">
                                <h5>GDC.out</h5>
                                <pre>
                                    <code>
                                        null<br/>
                                        a1<br/>
                                        a2<br/>
                                        a3<br/>
                                        a4<br/>
                                    </code>
                                </pre>
                            </Column>
                        </Row>

                        <p>
                            The Garage-Door-Controller is a simple deterministic FSM-Model. The <code>GDC.fsm</code> file describes the main
                            behaviour of the FSM. Each row is separated into four columns. Each row describes one transition relation in the form
                            Source(-State)-Input-Output-Target(-State). Each column describes a index to a element in the corresponding State/In/Out file.
                            The first transition <code>0 0 1 4</code> then stands for 
                            <br/><br/>
                            <i>The transition-relation goes from state <code>DoorUp</code> to state <code>Doorclosing</code>
                            with input <code>e1</code> and output <code>a1</code>.</i> <br/><br/>
                            
                            By clicking 'Use' in the examples 
                            section, the GDC-Model is selected for further usage. Now we can specify a Test-Case-Generation 
                            Algorithm to generate tests (left hand side in the first box). For this case, the H-Method is chosen. 
                            After that, we can specify a method for handling non-full-specified FSMs 'handle'. 'cself' is selected 
                            to create self loops for states that do not support outgoing transitions for each input. We assume that 
                            the SUT has exactly the same amount of states like the model, so we select '0' for additional states. 
                            Now, we can generate tests by pushing 'Generate Tests' on the top line. If generating tests finished, 
                            a short summary of the test case-generation is shown in the log-output in the centre of the page:
                        </p>

                        <Column className="col-6">
                            <h4>Output</h4>
                            <pre>
                                <code>
                                    [12:08:18]: Test generation completed.<br/>
                                    Number of test cases: 13<br/>
                                    Total length        : 48<br/>
                                    Test case file      : /tmp/test suite.txt<br/>
                                    Reference model     : deterministic<br/>
                                </code>
                            </pre>
                        </Column>

                        Finally we can download the whole test suite by clicking 'Download Test-Suite'. The downloaded file
                        contains the following content:<br/><br/>

                        <Column className="col-4">
                            <h4>Test-Suite</h4>
                            <pre>
                                <code>
                                    e1, e1, e1<br/>
                                    e1, e2, e1, e1, e1<br/>
                                    e1, e2, e2, e1<br/>
                                    e1, e2, e3, e1<br/>
                                    e1, e2, e4, e1<br/>
                                    e1, e3, e1, e1<br/>
                                    e1, e4, e1, e1<br/>
                                    e1, e4, e2, e1, e1<br/>
                                    e1, e4, e3, e1<br/>
                                    e1, e4, e4, e1, e1<br/>
                                    e2, e1<br/>
                                    e3, e1<br/>
                                    e4, e1<br/>
                                </code>
                            </pre>
                        </Column>

                        { /* ==================================================================================================== */ }
                        <h4 id="ex2">Example of Generating a Test Harness From C++-Sources for a FSM-Implementation</h4>
                        <p align="justify">
                            To build a harness-binary from scratch, you need a valid harness.cpp, a sut_wrapper.cpp and an implementation of your SUT in C++. Moreover, 
                            you need a Makefile to compile the harness and your SUT. Our example-project for the SUT of the well-known 
                            <a href="examples/gdc-model.zip">Garage-Door-Controller</a>
                            has the directory structure as described below.The example-project can be downloaded 
                            <a href="examples/gdc.zip">here</a>.
                        </p>
                        
                        <Column className="col-4">
                            <pre>
                                <code>
                                    .<br/>
                                    ├── gdclib.cpp<br/>
                                    ├── gdclib.hpp<br/>
                                    ├── harness.cpp<br/>
                                    ├── Makefile<br/>
                                    └── sut_wrapper.cpp<br/>
                                </code>
                            </pre>
                        </Column>

                        <p>
                            The <code>gdclib.cpp</code> and the <code>gdclib.hpp</code> files implements the Garage-Door-Controller as SUT. The <code>harness.cpp</code> file 
                            is the specific harness file from the libfsmtest for the test-cloud, which can be downloaded 
                            <a href="https://gitlab.informatik.uni-bremen.de/fbrning/libfsmtest-test-cloud.git">here</a>. This file implements a procedure reading 
                            the model and the test-suite and executes each test-case against the SUT. Moreover, a sample <code>sut_wrapper.cpp</code> is also contained 
                            in that repository, which serves as a wrapper between the harness and the SUT.
                        </p>

                        <p align="justify">
                            The implementation of the Garage-Door-Controller is split into a <code>cpp</code> file and a <code>hpp</code> file. The 
                            listing below describes the implementation of the <code>gdclib.hpp</code>.
                        </p>

                        <Column className="col-12">
                            <pre>
                                <code>#include &lt;string&gt;<br/><br/>

                                typedef enum &#123;<br/>
                                &emsp;up,<br/>
                                &emsp;moving_down,<br/>
                                &emsp;suspended_down,<br/>
                                &emsp;down,<br/>
                                &emsp;moving_up,<br/>
                                &emsp;suspended_up<br/>
                                &#125; gdc_states_t;<br/><br/>
                                
                                typedef enum &#123;<br/>
                                &emsp;e1,<br/>
                                &emsp;e2,<br/>
                                &emsp;e3,<br/>
                                &emsp;e4<br/>
                                &#125; gdc_inputs_t;<br/><br/>
                                
                                typedef enum &#123;<br/>
                                &emsp;nop,<br/>
                                &emsp;a1,<br/>
                                &emsp;a2,<br/>
                                &emsp;a3,<br/>
                                &emsp;a4<br/>
                                &#125; gdc_outputs_t;<br/><br/>
                                
                                extern void gdc_reset();<br/>
                                extern gdc_outputs_t gdc(gdc_inputs_t x);<br/>
                                </code>
                            </pre>
                        </Column>

                <p align="justify">The implementation of the Garage-Door-Controller in C++ is described in the listing below. This listing is the content of the <code>gdclib.cpp</code>.</p>

                <Column className="col-12">
                    <pre>
                        <code>
                        #include &quot;gdclib.hpp&quot;<br/><br/>

                        static gdc_states_t myState = up;<br/><br/>

                        static gdc_outputs_t handleUp(gdc_inputs_t x) &#123;<br/><br/>
                            
                        &emsp;switch ( x ) &#123;<br/><br/>
                                    
                        &emsp;&emsp;case e1: myState = moving_down;<br/>
                        &emsp;&emsp;&emsp;return a1;<br/><br/>
                                    
                        &emsp;&emsp;&emsp;default: return nop;<br/>
                        &emsp;&#125;<br/>
                        
                        &#125;<br/><br/>

                        static gdc_outputs_t handleMovingDown(gdc_inputs_t x) &#123;<br/><br/>
                            
                        &emsp;switch ( x ) &#123;<br/><br/>
                                    
                        &emsp;&emsp;case e1: myState = suspended_down;<br/>
                        &emsp;&emsp;&emsp;return a3;<br/><br/>
                                    
                        &emsp;&emsp;case e2: myState = down;<br/>
                        &emsp;&emsp;&emsp;return a3;<br/><br/>
                                
                        &emsp;&emsp;case e4: myState = moving_up;<br/>
                        &emsp;&emsp;&emsp;return a4;<br/><br/>
                                    
                        &emsp;&emsp;&emsp;default: return nop;<br/><br/><br/>
                        &emsp;&emsp;&#125;<br/>
                        &#125;<br/><br/>

                        static gdc_outputs_t handleSuspendedDown(gdc_inputs_t x) &#123;<br/><br/>
                            
                        &emsp;switch ( x ) &#123;<br/><br/>
                                    
                        &emsp;&emsp;case e1: myState = moving_down;<br/>
                        &emsp;&emsp;&emsp;return a1;<br/><br/>
                                    
                        &emsp;&emsp;default: return nop;<br/>
                        &emsp;&#125;<br/>
                        &#125;<br/><br/>

                        static gdc_outputs_t handleDown(gdc_inputs_t x) &#123;<br/><br/>
                            
                        &emsp;switch ( x ) &#123;<br/><br/>
                                    
                        &emsp;&emsp;case e1: myState = moving_up;<br/>
                        &emsp;&emsp;&emsp;return a2;<br/><br/>
                                    
                        &emsp;&emsp;default: return nop;<br/>
                        &emsp;&#125;<br/>
                        &#125;<br/><br/>

                        static gdc_outputs_t handleMovingUp(gdc_inputs_t x) &#123;<br/><br/>
                            
                        &emsp;switch ( x ) &#123;<br/><br/>

                        &emsp;&emsp;case e1: myState = suspended_up;<br/>
                        &emsp;&emsp;&emsp;return a3;<br/><br/>
                                    
                        &emsp;&emsp;case e3: myState = up;<br/>
                        &emsp;&emsp;&emsp;return a3;<br/><br/>
                                    
                        &emsp;&emsp;default: return nop;<br/>
                        &emsp;&#125;<br/><br/>

                        &#125;<br/><br/>

                        static gdc_outputs_t handleSuspendedUp(gdc_inputs_t x) &#123;<br/><br/>
                            
                        &emsp;switch ( x ) &#123;<br/><br/>
                                    
                        &emsp;&emsp;case e1: myState = moving_up;<br/>
                        &emsp;&emsp;&emsp;return a2;<br/><br/>
                                    
                        &emsp;&emsp;default: return nop;<br/>
                        &emsp;&#125;<br/><br/>

                        &#125;<br/><br/>

                        void gdc_reset() &#123;<br/>
                        &emsp;myState = up;<br/>
                        &#125;<br/><br/>

                        gdc_outputs_t gdc(gdc_inputs_t x) &#123;<br/><br/>
                                
                        &emsp;switch ( myState ) &#123;<br/><br/>
                                    
                        &emsp;&emsp;case up: return handleUp(x);<br/>
                        &emsp;&emsp;case moving_down: return handleMovingDown(x);<br/>
                        &emsp;&emsp;case suspended_down: return handleSuspendedDown(x);<br/>
                        &emsp;&emsp;case down: return handleDown(x);<br/>
                        &emsp;&emsp;case moving_up: return handleMovingUp(x);<br/>
                        &emsp;&emsp;case suspended_up: return handleSuspendedUp(x);<br/><br/>
                                    
                        &emsp;&emsp;default: return nop;<br/><br/>
                        &emsp;&#125;<br/>
                            
                        &#125;
                        </code>
                    </pre>
                    
                </Column>

                    { /* ==================================================================================================== */ }
                    <p align="justify">The content of the <code>sut_wrapper.cpp</code>, that serves as a wrapper of the SUT implementation for the harness, is described in the listing below</p>

                    <Column className="col-12">
                        <pre>
                            <code>
                                #include &lt;string&gt;<br/>
                                #include &lt;map&gt;<br/><br/>

                                using namespace std;<br/><br/> { /* eslint-disable-line react/jsx-no-comment-textnodes */ }

                                // Include header files of library to be tested<br/>
                                #include "gdclib.hpp"<br/><br/> { /* eslint-disable-line react/jsx-no-comment-textnodes */ }

                                /**<br/>
                                &ensp;*   Helper data structures and functions for<br/>
                                &ensp;*   SUT test wrapper<br/>
                                &ensp;*/<br/><br/>

                                map&lt;string,gdc_inputs_t&gt; fsmIn2gdcIn = &#123;<br/>
                                &emsp;&#123;"e1",e1&#125;, &#123;"e2",e2&#125;, &#123;"e3",e3&#125;, &#123;"e4",e4&#125;<br/>
                                &#125;;<br/><br/>
                                map&lt;gdc_outputs_t,string&gt; gdcOut2fsmOut = &#123;<br/>
                                &emsp;&#123;nop,"null"&#125;, &#123;a1,"a1"&#125;, &#123;a2,"a2"&#125;, &#123;a3,"a3"&#125;, &#123;a4,"a4"&#125;<br/>
                                &#125;;<br/><br/><br/>


                                using namespace std;<br/><br/>


                                void sut_init() &#123;<br/><br/>

                                &emsp;// Maps are already defined above<br/><br/>

                                &emsp;// Initialise SUT, if required, by calling<br/>
                                &emsp;// initialisation functions or initialising<br/>
                                &emsp;// global SUT variables<br/>
                                &emsp;gdc_reset();<br/><br/>

                                &#125;<br/><br/>

                                void sut_reset() &#123;<br/>
                                &emsp;gdc_reset();<br/>
                                &#125;<br/><br/><br/>


                                const string sut(const string& input) &#123;<br/><br/>

                                &emsp;string fsmOutputEvent;<br/><br/>

                                &emsp;map&lt;string,gdc_inputs_t&gt;::iterator inputIte = fsmIn2gdcIn.find(input);<br/>
                                &emsp;if ( inputIte == fsmIn2gdcIn.end() ) return fsmOutputEvent;<br/><br/>

                                &emsp;gdc_outputs_t y = gdc( inputIte-&gt;second );<br/><br/>

                                &emsp;map&lt;gdc_outputs_t,string&gt;::iterator outputIte = gdcOut2fsmOut.find(y);<br/>
                                &emsp;if ( outputIte == gdcOut2fsmOut.end() ) return fsmOutputEvent;<br/><br/>

                                &emsp;fsmOutputEvent = outputIte-&gt;second;<br/><br/>

                                &emsp;return fsmOutputEvent;<br/>

                                &#125;
                            </code>
                        </pre>
                    </Column>

                    <p align="justify">
                        The project includes a Makefile, which is only for FSM-Based harness. The <code>INCLUDE_LIBFSMTEST</code> is special for including the libfsmtest
                        source files for compiling the harness and the <code>LINK_LIBFSMTEST</code> is for linking the libfsmtest used by the harness. Both paths are 
                        static in the harness-builder-container. The Makefile to build the harness has the content as described below. The Makefile defines a target called 
                        <code>all</code> that calls the compiler to build all C++-Files in the current directory and creates a binary called <i>harness</i>.
                        The command also links the libfsmtest to the binary.
                    </p>

                    <Column className="col-12">
                        <pre>
                            <code>
                                # Special paths in the harness-builder-container.<br/>
                                # DO NOT MODIFY<br/>
                                INCLUDE_LIBFSMTEST=-I/libs/libfsmtest/libfsmtest<br/>
                                LINK_LIBFSMTEST=/libs/libfsmtest/libfsmtest.a<br/>
                                CPP_STD=--std=c++17<br/>
                                CC=g++<br/><br/>

                                # Project-Specific paths and names. <br/>
                                # Must be modified by the user.<br/>
                                INCLUDE_FOLDER=-I.      # Project include-path<br/>
                                BIN_NAME="harness"      # name of the final executable<br/>
                                SOURCES=*.cpp<br/><br/>

                                all:<br/>
                                &emsp;$(CC) $(CPP_STD) -o $(BIN_NAME) $(INCLUDE_FOLDER) $(INCLUDE_LIBFSMTEST) $(SOURCES) $(LINK_LIBFSMTEST)<br/>
                            </code>
                        </pre>
                    </Column>

                    <p align="justify">
                        Please modify the Makefile for your project. In this Makefile, all .cpp-Files in the directory of the Makefile are compiled and a harness 
                        called <code>harness</code> will be compiled. 
                        To compile a harness, you can download the docker image from the Docker-Hub Docker Registry, as described below to build a test-cloud compatible harness-binary. 
                    </p>

                    <p>The following steps demonstrates the workflow:</p>
                    <ol>
                    <li>
                        <p align="justify">
                            The fist step is to pull the latest docker-image of the harness-builder with the following command:
                        </p>
                        <pre><code>$ docker pull agbstestcloud/harness-builder:latest</code></pre>
                    </li>

                    <li>
                        <p align="justify">
                            After that, you can compile your harness. The first step to build a harness is that you have to map your SUT-Folder to the docker-container in 
                            <code>--volume &lt;path-to-sut-folder&gt;:/workspace:rw</code>. After that, you have to specify the 
                            Makefile (<code>&lt;path-to-makefile&gt;</code>) relative to the <code>&lt;path-to-sut-folder&gt;</code>-directory. If the mapped SUT-Folder 
                            is the GDC-Folder above, the Makefile is directly inside the SUT-Folder, so <code>&lt;path-to-makefile&gt;</code> is <code>'.'</code> (dot). 
                            Now, the docker-container will compile the harness. When the compilation process is finished, the compiled binary exists inside the SUT-Folder 
                            (as specified in the Makefile). If the gdc-directory is in <code>/tmp/gdc</code>, the compile-command is:
                        </p>
                        <pre><code>$ docker run --volume /tmp/gdc:/workspace:rw agbstestcloud/harness-builder:latest .</code></pre>
                    </li>
                    </ol>

                    { /* ==================================================================================================== */ }
                    <h4 id="ex3">Example of Executing a Test-Suite Against a FSM-Based Test Harness</h4>
                    <p align="justify">
                        When a Test-Cloud-Compatible harness exists and was also uploaded to the Cluster and a test suite was generated, we can Execute
                        the test-harness. When assuming that the harness-building and FSM-Test-Case-generation step have been performed, we now can press 
                        the 'Execute Tests' Button on the top line. Now the Test-Execution is performed and after a certain time, a summarising verdict is 
                        shown in the centre log-output:
                    </p>

                    <Column className="col-12">
                        <pre>
                            <code>
                                Harness-Execution finished. Summarised verdict: <br/>
                                Model: '/tmp/model-files/GDC'<br/>
                                Test-Suite: '/tmp/model-files/38c1d72f-8b69-4c76-a0be-e9ea256c9cef'<br/>
                                Harness: '/tmp/model-files/gdc-harness'<br/><br/>

                                PASSED: 8 Testcases<br/>
                                FAILED: 5 Testcases<br/>
                            </code>
                        </pre>
                    </Column>
                    
                    <p align="justify">
                        One can see, that not all test cases were passed. To further inspect the verdict, we download it by clicking 'Download Verdict' to
                        download the current test-verdict to inspect which test case was failed. The full verdict is described in the listing below.
                    </p>

                    <Column className="col-12">
                        <pre>
                            <code>
                                PASS: e1/a1, e1/a3, e1/a1<br/>
                                FAIL after I/O trace: e1/a1, e2/a3, e1/a2, e1/a3, e1/ &lt;ERROR&gt;a3<br/>
                                PASS: e1/a1, e2/a3, e2/null, e1/a2<br/>
                                PASS: e1/a1, e2/a3, e3/null, e1/a2<br/>
                                PASS: e1/a1, e2/a3, e4/null, e1/a2<br/>
                                PASS: e1/a1, e3/null, e1/a3, e1/a1<br/>
                                FAIL after I/O trace: e1/a1, e4/a4, e1/a3, e1/ &lt;ERROR&gt;a1<br/>
                                FAIL after I/O trace: e1/a1, e4/a4, e2/ &lt;ERROR&gt;a3<br/>
                                FAIL after I/O trace: e1/a1, e4/a4, e3/ &lt;ERROR&gt;null<br/>
                                FAIL after I/O trace: e1/a1, e4/a4, e4/ &lt;ERROR&gt;a4<br/>
                                PASS: e2/null, e1/a1<br/>
                                PASS: e3/null, e1/a1<br/>
                                PASS: e4/null, e1/a1<br/>
                            </code>
                        </pre>
                    </Column>

                    { /* ==================================================================================================== */ }
                    <h4 id="sfsm-tc-gen">Example of Generating a Test-Suite for Symbolic Finite State Machines</h4>
                    <p align="justify">
                        To Generate a Test-Suite for symbolic Finite State Machines, just switch to the Tab 'SFSM-Test-generation'. On the right-hand-side, 
                        in the examples tab is a 'brake-controller' model, which is depicted in the following image:
                    </p>

                    <Row className="row brake-model-row">
                        <Column className="col-12">
                            <img src="resources/brake.png" className="img-fluid" alt="Responsive image"></img> { /* eslint-disable-line jsx-a11y/img-redundant-alt */ }
                        </Column>
                    </Row>

                    <p align="justify">
                        To generate tests for this model, press the 'Use' Button to use it. On the left-hand-side one can select the number of additional 
                        state that the implemented SUT has in comparison to the model. In this case '0' is chosen. When pressing the 'Generate Tests' 
                        Button, tests will be generated for that model. The verdict-execution-output is displayed in real-time on the output-log in the 
                        centre of the page. When the Test-Case-Generation-Process is finished, one can download the Test-Suite by pressing 'Download Test-Suite'. 
                        The downloaded file has the following content:
                    </p>

                    <Column className="col-12">
                        <pre>
                            <code>
                                Test suite (size: 13):<br/>
                                (= x 1.0).(= x 200.0)<br/>
                                (= x 190.0).(= x 200.0)<br/>
                                (= x 191.0).(= x 200.0)<br/>
                                (= x 200.0).(= x 1.0).(= x 200.0)<br/>
                                (= x 200.0).(= x 190.0).(= x 200.0)<br/>
                                (= x 200.0).(= x 191.0).(= x 200.0)<br/>
                                (= x 200.0).(= x 200.0).(= x 200.0)<br/>
                                (= x 200.0).(= x 202.0).(= x 200.0)<br/>
                                (= x 202.0).(= x 1.0).(= x 200.0)<br/>
                                (= x 202.0).(= x 190.0).(= x 200.0)<br/>
                                (= x 202.0).(= x 191.0).(= x 200.0)<br/>
                                (= x 202.0).(= x 200.0).(= x 200.0)<br/>
                                (= x 202.0).(= x 202.0).(= x 200.0)<br/>
                            </code>
                        </pre>
                    </Column>
            
                    { /* ==================================================================================================== */ }
                    <h4 id="sfsm-harness-gen">Example of Generating a Test Harness From C++-Sources for a Symbolic FSM-Implementation</h4>
                        <p align="justify">
                            To build a harness for Symbolic-FSM-Based harness, you need a valid <code>harness.cpp</code> for executing SFSM-Based test-Suites against a System under Test and 
                            a <code>sut_wrapper.cpp</code> that serves as a wrapper for the SUT to the harness. Moreover you have to implement a SFSM-Model in C++ an store that in the directory of 
                            the harness.cpp and the sut_wrapper.cpp. To build the harness, a Makefile must be given that must link the libsfsmtest, included in the harness-builder docker container,
                            to the harness. A example project for the <a href="examples/brake-model.zip">Brake-Controller</a> can be downloaded 
                            <a href="examples/brake.zip">here</a>. The Project includes the following files.
                        </p>
                        <pre>
                            <code>
                                .<br/>
                                ├── Brake.hpp<br/>
                                ├── harness.cpp<br/>
                                ├── Makefile<br/>
                                └── sut_wrapper.cpp<br/>
                            </code>
                        </pre>
                
                        <p align="justify">
                            The <code>Brake.hpp</code> file is the implementation of the brake-controller in C++. The <code>harness.cpp</code> file is the harness that is compatible with SFSM models and 
                            the corresponding test-suites. This harness uses the <a href="https://gitlab.informatik.uni-bremen.de/s_kpvazy/libsfsmtest">libsfsmtest</a>. The <code>sut_wrapper.cpp</code>
                            serves as the interface between the harness and the SUT. The main SUT implementation <code>Brake.hpp</code> is described in the listing below.
                        </p>
                        <pre>
                            <code> { /* eslint-disable-line react/jsx-no-comment-textnodes */ }
                            /**<br/>
                                &ensp;* @brief<br/>
                                &ensp;*  This class represents the 'brake' sample model.<br/>
                                &ensp;*/<br/>
                                #pragma once<br/><br/>

                                #include &lt;iostream&gt;<br/><br/>

                                namespace brake &#123;<br/> { /* eslint-disable-line react/jsx-no-comment-textnodes */ }
                                /*-- global typedefs */<br/>
                                typedef double input;<br/>
                                typedef double output;<br/><br/> { /* eslint-disable-line react/jsx-no-comment-textnodes */ } 

                                /*-- constants */<br/>
                                static const double B0 = 0.9;<br/>
                                static const double B1 = 1.1;<br/>
                                static const double B2 = 2;<br/>
                                static const double c = 100;<br/>
                                static const double max = 200;<br/>
                                static const double delta = 10;<br/><br/> { /* eslint-disable-line react/jsx-no-comment-textnodes */ }

                                /*-- I/O */<br/>
                                input x;<br/>
                                output y;<br/><br/> { /* eslint-disable-line react/jsx-no-comment-textnodes */ }

                                /*-- states */<br/>
                                typedef enum BrakeState &#123;<br/>
                                &emsp;S0,<br/>
                                &emsp;S1,<br/>
                                &emsp;S2<br/>
                                &#125; BrakeState_t;<br/><br/><br/> { /* eslint-disable-line react/jsx-no-comment-textnodes */ }


                                /** Class, implementing the SM */<br/>
                                class BrakeSM &#123;<br/>
                                public:<br/>
                                &emsp;BrakeSM() &#123;<br/>
                                &emsp;&emsp;currentState = BrakeState::S0;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;void reset() &#123;<br/>
                                &emsp;&emsp;currentState = BrakeState::S0;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;void cycle() &#123;<br/>
                                &emsp;&emsp;evaluate();<br/>
                                &emsp;&#125;<br/><br/>

                                private:<br/>
                                &emsp;BrakeState_t currentState;<br/><br/>

                                &emsp;void evaluate() &#123;<br/>
                                &emsp;&emsp;if (currentState == BrakeState::S0) &#123;<br/>
                                &emsp;&emsp;&emsp;if (guardCnd1()) &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;effect1();<br/>
                                &emsp;&emsp;&emsp;&emsp;currentState = BrakeState::S0;<br/>
                                &emsp;&emsp;&emsp;&#125; else if (guardCnd5()) &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;effect2();<br/>
                                &emsp;&emsp;&emsp;&emsp;currentState = BrakeState::S1;<br/>
                                &emsp;&emsp;&emsp;&#125; else if (guardCnd6()) &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;effect3();<br/>
                                &emsp;&emsp;&emsp;&emsp;currentState = BrakeState::S2;<br/>
                                &emsp;&emsp;&emsp;&#125; else &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;std::cerr &lt;&lt; "[SUT] Error, no guard from S0" &lt;&lt; std::endl;<br/>
                                &emsp;&emsp;&emsp;&#125;<br/>
                                &emsp;&emsp;&#125; else if (currentState == BrakeState::S1) &#123;<br/>
                                &emsp;&emsp;&emsp;if (guardCnd2()) &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;effect1();<br/>
                                &emsp;&emsp;&emsp;&emsp;currentState = BrakeState::S0;<br/>
                                &emsp;&emsp;&emsp;&#125; else if (guardCnd5()) &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;effect2();<br/>
                                &emsp;&emsp;&emsp;&emsp;currentState = BrakeState::S1;<br/>
                                &emsp;&emsp;&emsp;&#125; else if (guardCnd6()) &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;effect3();<br/>
                                &emsp;&emsp;&emsp;&emsp;currentState = BrakeState::S2;<br/>
                                &emsp;&emsp;&emsp;&#125; else &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;std::cerr &lt;&lt; "[SUT] Error, no guard from S1" &lt;&lt; std::endl;<br/>
                                &emsp;&emsp;&emsp;&#125;<br/><br/>

                                &emsp;&emsp;&#125; else if (currentState == BrakeState::S2) &#123;<br/>
                                &emsp;&emsp;&emsp;if (guardCnd3()) &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;effect1();<br/>
                                &emsp;&emsp;&emsp;&emsp;currentState = BrakeState::S0;<br/>
                                &emsp;&emsp;&emsp;&#125; else if (guardCnd4()) &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;effect3();<br/>
                                &emsp;&emsp;&emsp;&emsp;currentState = BrakeState::S2;<br/>
                                &emsp;&emsp;&emsp;&#125; else &#123;<br/>
                                &emsp;&emsp;&emsp;&emsp;std::cerr &lt;&lt; "[SUT] Error, no guard from S2" &lt;&lt; std::endl;<br/>
                                &emsp;&emsp;&emsp;&#125;<br/>
                                &emsp;&emsp;&#125;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;/*-- Guards */<br/>
                                &emsp;static bool guardCnd1() &#123;<br/>
                                &emsp;&emsp;return x &lt;= max;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;static bool guardCnd2() &#123;<br/>
                                &emsp;&emsp;return x &lt; max;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;static bool guardCnd3() &#123;<br/>
                                &emsp;&emsp;return x &lt; (max - delta);<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;static bool guardCnd4() &#123;<br/>
                                &emsp;&emsp;return x &gt;= (max - delta);<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;static bool guardCnd5() &#123;<br/>
                                &emsp;&emsp;return x == max;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;static bool guardCnd6() &#123;<br/>
                                &emsp;&emsp;return x &gt; max;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;/*-- Effects */<br/>
                                &emsp;static void effect1() &#123;<br/>
                                &emsp;&emsp;y = 0;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;static void effect2() &#123;<br/>
                                &emsp;&emsp;y = 1;<br/>
                                &emsp;&#125;<br/><br/>

                                &emsp;static void effect3() &#123;<br/>
                                &emsp;&emsp;y = B2 + ((x - max) / c);<br/>
                                &emsp;&#125;<br/><br/>

                            &emsp;&#125;<br/>
                            &#125;<br/>
                            </code>
                        </pre>
            
                        <p align="justify">
                            To now connect the SUT-Code to the harness, the <code>sut_wrapper.cpp</code> is used to connect the harness to the SUT interfaces. The user has to 
                            implement such functions in the <code>sut_wrapper.cpp</code> if interfaces to the SUT are different or were modified. In the listing below, the 
                            <code>sut_wrapper.cpp</code> is described.
                        </p>
                        <pre>
                            <code> { /* eslint-disable-line react/jsx-no-comment-textnodes */ }
                            /**<br/>
                                &ensp;* Example implementation of the sut_wrapper.cpp for<br/>
                                &ensp;* the brake-controller<br/>
                                &ensp;*/<br/>
                                #include &lt;map&gt;<br/><br/>

                                #include "Brake.hpp"<br/><br/>

                                typedef std::string SutVariable;<br/>
                                typedef std::string Value;<br/><br/>

                                brake::BrakeSM brakeSM;<br/><br/>

                                void sut_init() &#123;<br/>
                                &emsp;brakeSM.reset();<br/>
                                &#125;<br/><br/>

                                void sut_reset() &#123;<br/>
                                &emsp;brakeSM.reset();<br/>
                                &#125;<br/><br/>

                                std::map&lt;SutVariable, Value&gt; sut(std::map&lt;SutVariable, Value&gt;& input) &#123;<br/><br/>

                                &emsp;brake::x = std::stod(input["x"]);<br/><br/>

                                &emsp;brakeSM.cycle();<br/><br/>

                                &emsp;std::map&lt;SutVariable, Value&gt; sfsmOutputs;<br/>
                                &emsp;sfsmOutputs["y"] = std::to_string(brake::y);<br/><br/>

                                &emsp;return sfsmOutputs;<br/>
                                &#125;<br/>
                            </code>
                        </pre>
                
                        <p align="justify">
                            The <code>harness.cpp</code> can also be fetched from the example-project. To finally build the test-harness, a Makefile is provided that links
                            the harness, the sut_wrapper and the SUT with the libsfsmtest. The Makefile is listed below. The variable <code>INCLUDE_LIBSFSMTEST</code> stores
                            the path to the libsfsmtest source folder inside the docker-container. This file path is static and has to set in each SFSM-Based harness project.
                            The <code>LINK_LIBSFSMTEST</code> and <code>LINK_EXPRESSIONLIB</code> variables stores the path to the libraries inside the docker-container
                            that must be linked to the harness. All the three mentioned paths are static and must not be modified, otherwise the harness will not build. The
                            sections under <code>INCLUDE_FOLDER</code> specifies project-specific dependencies. The <code>INCLUDE_FOLDER</code> specifies the path in the
                            project, where the compiler searches for header files. If sub-directories with needed header-files exists inside the main-project-directory,
                            the path must be added here. In this case, the search-path for header files in only in the main project directory. The variable
                            <code>BIN_NAME</code> stores the name of the final executable name that the compiler generates. And finally, the variable <code>SOURCES</code>
                            specifies all sources that must be compiled. In this case, all cpp files, containing in the main project directory are compiled.
                        </p>
                        <pre>
                            <code>
                                ############################################<br/>
                                # Makefile for Building SFSM-Based Harness<br/>
                                #<br/>
                                # AGBS, University of Bremen, 2022<br/>
                                ############################################<br/><br/>

                                # Special paths in the harness-builder-container.<br/>
                                # DO NOT MODIFY<br/>
                                INCLUDE_LIBSFSMTEST=-I/libs/libsfsmtest/libsfsmtest<br/>
                                LINK_LIBSFSMTEST=/libs/libsfsmtest/libsfsmtest.a<br/>
                                LINK_EXPRESSIONLIB=/libs/libsfsmtest/libexpressions.a<br/>
                                CPP_STD=--std=c++17<br/>
                                CC=g++<br/><br/>

                                # Project-Specific paths and names. <br/>
                                # Must be modified by the user.<br/>
                                INCLUDE_FOLDER=-I.      # Project include-path<br/>
                                BIN_NAME="harness"      # name of the final executable<br/>
                                SOURCES=*.cpp<br/><br/>

                                all:<br/>
                                &emsp;$(CC) $(CPP_STD) -o $(BIN_NAME) $(PROJECT_INCLUDE_DIR) $(INCLUDE_LIBSFSMTEST) $(SOURCES) $(LINK_LIBSFSMTEST) $(LINK_EXPRESSIONLIB)<br/>
                            </code>
                        </pre>
                
                        <p align="justify">
                            To compile a test-cloud compatible harness, you have to do a few steps beforehand. First, you have to install <a href="https://www.docker.com/products/docker-desktop/">Docker Desktop</a>
                            to your computer to run the harness-builder docker container. After that, you have to follow this steps:
                        </p>
                        <ol>
                            <li>
                                <p align="justify">
                                    Pull the latest docker image from docker hub of the harness-builder
                                </p>
                            <pre><code>$ docker pull agbstestcloud/harness-builder:latest</code></pre>
                            </li>
                            <li>
                                <p align="justify">
                                    Execute the harness-builder to compile the harness. This command maps the SUT-Directory inside the docker-container and
                                    supplies read and write access for the docker-container to the directory that the docker container is able to store 
                                    the compiled binary in the hosts directory. 
                                </p>
                                <pre><code>$ docker run --volume &lt;path-to-directory&gt;:/workspace:rw agbstestcloud/harness-builder:latest &lt;path-to-makefile&gt;</code></pre>
                                <p align="justify">
                                    Assume the folder of the brake-example is under <code>/tmp/brake</code>, the following command compiles the harness.
                                </p>
                                <pre><code>$ docker run --volume /tmp/brake:/workspace:rw agbstestcloud/harness-builder:latest .</code></pre>
                            </li>
                        </ol>
                
                        <p align="justify"> 
                            If the compilation was success, the SUT directory contains a harness binary called "harness" as specified in the Makefile. The harness
                            is now ready for upload to the Test-Cloud.
                        </p>

                { /* ==================================================================================================== */ }
                <h4 id="sfsm-tcexe">Example of Executing a Test-Suite Against a Symbolic FSM-Based Test Harness</h4>
                <p align="justify">
                    If a SFSM-Based harness was successfully compiled with the harness-builder, it can be uploaded to the Test-Cloud to run it. But first one has to upload a 
                    model and has to generate a complete test-suite. If a model was uploaded and a complete test-suite was generated, the test-suite can now executed against
                    the compiled and uploaded SUT. For symbolic FSMs one has to change the tab to <i>FSM-MBT</i> where the harness-binary is also uploaded. On the left hand side
                    is each name for Model, Test-Suite and Harness displayed. Now, by pressing the <i>Execute Harness</i> Button, the test-suite is executed against the SUT and the 
                    harness-binary is executed. If finished, a short summary of the verdict is shown, as described below.
                </p>
                <pre>
                    <code>
                        Harness-Execution finished. Summarised verdict: <br/>
                        Model: '/tmp/model-files/brake'<br/>
                        Test-Suite: '/tmp/model-files/adc4f38c-bf66-47f9-9c32-6d961f0e44ad'<br/>
                        Harness: '/tmp/model-files/0c2b1e3d-25ec-48df-b03b-fff310ea5709-harness'<br/><br/>

                        PASSED: 13 Testcases<br/>
                        FAILED: 0 Testcases<br/>
                    </code>
                </pre>

                <p align="justify">
                The short verdict shows that all 13 testcases were passed by the SUT. Now to look at the entire verdict, one can download the full verdict by pressing the 
                <i>Download Verdict</i> Button. The verdict-Download starts immediately. The verdict of the brake-example is shown in the listing below.
                </p>
                <pre>
                    <code>
                        PASS: ((= x 1.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000))<br/>
                        PASS: ((= x 190.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000))<br/>
                        PASS: ((= x 191.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000))<br/>
                        PASS: ((= x 200.000000)/(= y 0.000000)).((= x 1.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000))<br/>
                        PASS: ((= x 200.000000)/(= y 0.000000)).((= x 190.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000))<br/>
                        PASS: ((= x 200.000000)/(= y 0.000000)).((= x 191.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000))<br/>
                        PASS: ((= x 200.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000))<br/>
                        PASS: ((= x 200.000000)/(= y 0.000000)).((= x 202.000000)/(= y 2.020000)).((= x 200.000000)/(= y 2.000000))<br/>
                        PASS: ((= x 202.000000)/(= y 2.020000)).((= x 1.000000)/(= y 0.000000)).((= x 200.000000)/(= y 0.000000))<br/>
                        PASS: ((= x 202.000000)/(= y 2.020000)).((= x 190.000000)/(= y 1.900000)).((= x 200.000000)/(= y 2.000000))<br/>
                        PASS: ((= x 202.000000)/(= y 2.020000)).((= x 191.000000)/(= y 1.910000)).((= x 200.000000)/(= y 2.000000))<br/>
                        PASS: ((= x 202.000000)/(= y 2.020000)).((= x 200.000000)/(= y 2.000000)).((= x 200.000000)/(= y 2.000000))<br/>
                        PASS: ((= x 202.000000)/(= y 2.020000)).((= x 202.000000)/(= y 2.020000)).((= x 200.000000)/(= y 2.000000))<br/>
                    </code>
                </pre>
                
                { /* ==================================================================================================== */ }
                <h3 id="ref">References</h3>
                <Row>
                    <Row>
                        <Column className="col-1 ref-col-idx">
                            <Row className="row-1">
                                [1]
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <a href="http://www.informatik.uni-bremen.de/agbs/jp/papers/test-automation-huang-peleska.pdf">http://www.informatik.uni-bremen.de/agbs/jp/papers/test-automation-huang-peleska.pdf</a><br/>
                            </Row>
                        </Column>
                    </Row>
                    <Row>
                        <Column className="col-1 ref-col-idx">
                            <Row className="row-1">
                                [2]
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <a href="https://zenodo.org/record/7267975">https://zenodo.org/record/7267975</a><br/>
                            </Row>
                        </Column>
                    </Row>
                    <Row>
                        <Column className="col-1 ref-col-idx">
                            <Row className="row-1">
                                [3]
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <a href="https://link.springer.com/chapter/10.1007/978-3-031-04673-5_1">https://link.springer.com/chapter/10.1007/978-3-031-04673-5_1</a><br/>
                            </Row>
                        </Column>
                    </Row>
                    <Row>
                        <Column className="col-1 ref-col-idx">
                            <Row className="row-1">
                                [4]
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <p className='p-class-text-ref'>Peled, D., Vardi, M.Y., Yannakakis, M. (2002) Black b ox checking. Journal of Automata, Languages and Combinatorics</p>
                            </Row>
                        </Column>
                    </Row>
                    <Row>
                        <Column className="col-1 ref-col-idx">
                            <Row className="row-1">
                                [5]
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <p className='p-class-text-ref'>Vaandrager, F.W., Garhewal, B., Rot, J., Wißmann, T. (2022) A new approach for active automata learning based on apartnessn, In Fisman, D., Rosu, G. (eds.) Tools and Algorithms for the Construction and Analysis of Systems - 28th International Conference, TACAS 2022, Held as Part of the Europ ean Joint Conferences on Theory and Practice of Software, ETAPS 2022, Munich, Germany, April 2-7, 2022, Proceedings, Part I, Lecture Notes in Computer Science, vol. 13243, pp. 223-243.Springer, 2022.</p>
                            </Row>
                        </Column>
                    </Row>
                    <Row>
                        <Column className="col-1 ref-col-idx">
                            <Row className="row-1">
                                [6]
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <p className='p-class-text-ref'>Brüning, F., Gleirscher, M., Huang, W.-ling, Krafczyk, Niklas, Peleska, J., & Sachtleben, R. (2023) Complete Prop erty-Oriented Mo dule Testing. Testing Software and Systems - 35th IFIP WG 6.1 International Conference, ICTSS 2023, Bergamo, Italy, September 18-20, 2023, Proceedings</p>
                            </Row>
                        </Column>
                    </Row>
                    <Row>
                        <Column className="col-1 ref-col-idx">
                            <Row className="row-1">
                                [7]
                            </Row>
                        </Column>
                        <Column>
                            <Row>
                                <p >Brüning, F., Gleirscher, M., Huang, W.-ling, Krafczyk, Niklas, Peleska, J., & Sachtleben, R. (2024). Efficient Gray Box Checking for C/C ++ Modules - Technical Report. Zenodo. <a href='https://doi.org/10.5281/zenodo.10867923'>https://doi.org/10.5281/zenodo.10867923</a></p>
                            </Row>
                        </Column>
                    </Row>
                </Row>
                </Column>
            </Row>

        </div>
        <Footer>
        </Footer>
    </div>
    )
}

export default Documentation;