import React, { useContext } from "react";
import Column from "../Components/Column";
import Row from "../Components/Row";
import Button from 'react-bootstrap/Button';
import Card from "../Components/Card";
import Dropdown from "../Components/Dropdown";
import Select from "../Components/Select"
import Input from "../Components/Input";
import SelectOption from "../Components/SelectOption";
import Label from "../Components/Label";
import ParameterColumn from "../Components/ParameterColumn";

import { FsmContext } from "./Context";
import { GlobalContext } from "../GlobalContext";

function FSMParametersPage({ setMethod, setHandle, setAdditionalStates, storeFsmTestSuiteToPv }) {

    const ctxt = useContext(FsmContext);
    const globalContext = useContext(GlobalContext)

    /**
     * Additional states functions
     */
    function onInputChangeAdditionalStatesField(typedStates) {
        setAdditionalStates(typedStates);
    }

    /**
     * Callback to increase additional states
     */
    function increaseAdditionalStates() {
        let x = 0;

        if ( ctxt.additionalStates !== '' ) {
            x = parseInt(ctxt.additionalStates);
        }
        x += 1;

        setAdditionalStates(x);
    }

    /**
     * Callback to decrease additional states
     */
    function decreaseAdditionalStates() {
        let x = 1;

        if ( ctxt.additionalStates !== '' ) {
            x = parseInt(ctxt.additionalStates);
        }

        if ( x > 0 ) {
            x -= 1;
            setAdditionalStates(x);
        }
    }

    /**
     * On Change handler for Test-Case Generation Method select 
     */
    function onTestGenerationMethodChanged(selectedOption) {
        if ( selectedOption !== "" ) {
            if ( selectedOption === "W-Method" ) {
                setMethod("w");
            }
            else if ( selectedOption === "Wp-Method" ) {
                setMethod("wp");
            }
            else if ( selectedOption === "H-Method" ) {
                setMethod("h");
            }
            else {
                setMethod("spyh");
            }
            
        }
    }

    /**
     * On Change handler for handling non full specified FSMs select. 
     */
    function onHandleNonFullSpecifiedChange(selectedOption) {
        if ( selectedOption !== "" ) {
            if ( selectedOption === "Selfloop" ) {
                setHandle("cself");
            }
            else {
                setHandle("cerror");
            }
        }
    }

    /**
     * Return page state in JSX syntax
     */
    return (
        <>
            <Row>
                <ParameterColumn>
                    <h4>FSM-Parameters</h4>
                </ParameterColumn>
            </Row>
            <Row>
                <ParameterColumn>
                    {/* Parameters Column */}
                    <Card headerName="Parameter">
                        {/* Row for Header */}
                        <Row style={{ height: '30px'}}>
                            <Column className="col-6">
                                <h6>Method</h6>
                            </Column>
                            <Column className="col-6">
                                <h6>Handle</h6>
                            </Column>
                        </Row>

                        {/* Row for Selectors */}
                        <Row style={{ height: '45px' }}>
                            <Column className="col-6">
                                <Dropdown id="methodDropDown">
                                    <Select 
                                        className="form-select form-select-sm" 
                                        id="generationMethodSelectPicker"  
                                        onChange={ (value) => onTestGenerationMethodChanged(value.target.value) }>
                                        <SelectOption value="W-Method">W-Method</SelectOption>
                                        <SelectOption value="Wp-Method">Wp-Method</SelectOption>
                                        <SelectOption value="H-Method">H-Method</SelectOption>
                                        <SelectOption value="SPYH-Method">SPYH-Method</SelectOption>
                                    </Select>
                                </Dropdown>
                            </Column>
                            <Column className="col-6">
                                <Dropdown id="handleDropDown">
                                    <Select 
                                        className="form-select form-select-sm" 
                                        id="nonFullSpecifiedSelectPicker"
                                        onChange={ (value) => onHandleNonFullSpecifiedChange(value.target.value) }>
                                        <SelectOption value="Selfloop">Selfloop</SelectOption>
                                        <SelectOption value="Error">Error</SelectOption>
                                    </Select>
                                </Dropdown>
                            </Column>
                        </Row>

                        {/* Row for additional states */}
                        <Row>
                            <Column className="col-6">
                                <h6>Additional-States</h6>
                            </Column>
                        </Row>
                        <Row className="row align-items-center">
                            <Column className="col-2 text-left">
                                <Button
                                    className="additional-states-picker"
                                    variant="secondary"
                                    onClick={() => decreaseAdditionalStates() }>
                                    -
                                </Button>
                            </Column>
                            <Column className="col-2 text-left">
                                <Input 
                                    id="addStatesPicker" 
                                    type="text" 
                                    className="form-control text-center additional-states-picker"
                                    value={ ctxt.additionalStates }
                                    onChange={ (value) => onInputChangeAdditionalStatesField(value.target.value) }>
                                </Input>
                            </Column>
                            <Column className="col-2 text-left">
                                <Button
                                    className="additional-states-picker"
                                    variant="secondary"
                                    onClick={() => increaseAdditionalStates() }>
                                    +
                                </Button>
                            </Column>
                        </Row>
                    </Card>
                </ParameterColumn>
            </Row>

            <Row>
                <ParameterColumn>
                    <Card headerName="Using Model">
                        <Label id="currentUsingModelFsm" className="ParameterLabel"> 
                            { globalContext.fsmUploadedModel ? globalContext.fsmUploadedModel : "No model selected."} 
                        </Label>
                    </Card>
                </ParameterColumn>
            </Row>

            <Row>
                <ParameterColumn>
                    <Card headerName="Using Test Suite">
                        <Label id="currentUsingTestSuiteSFsm" className="ParameterLabel"> 
                        { globalContext.fsmTestSuiteName ? globalContext.fsmTestSuiteName : "No test-suite available."} 
                        </Label>
                    </Card>
                </ParameterColumn>
            </Row>
            <Row>
                <ParameterColumn>
                    <Card headerName="Using Harness">
                        <Label id="textHarnessNameFsm" className="ParameterLabel">
                            { globalContext.fsmHarness ? globalContext.fsmHarness : ctxt.uploadedHarness ? ctxt.uploadedHarness : "No harness uploaded." }
                        </Label>
                    </Card>
                </ParameterColumn>
            </Row>
            <Row>
                <ParameterColumn>
                    <Card headerName="Store Artifacts">
                        <Button 
                            id="btnStoreFsmTestSuite" 
                            className="btn btn-success" 
                            onClick={ () => storeFsmTestSuiteToPv() }>
                            Store Test-Suite to PV
                        </Button>
                    </Card>
                </ParameterColumn>
            </Row>
        </>
    );
}

export default FSMParametersPage;
