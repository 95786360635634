import React from "react";
import Column from "./Column";


const ParameterColumn = ({ id, children }) => {
    return (
        <>
            <Column className="col-1"></Column>
            <Column className="col-9">
                { children }
            </Column>
        </>
        
    );
}

export default ParameterColumn;