import React from "react";

const Label = ({ id, className, children }) => {
    return (
        <label id={ id } className={ className? className : "" }>
            { children }
        </label>
    );
}

export default Label;