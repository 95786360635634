import React, { useContext } from "react";
import Column from "../Components/Column";
import Row from "../Components/Row";
import Button from 'react-bootstrap/Button';
import Card from "../Components/Card";
import Input from "../Components/Input";
import Label from "../Components/Label";
import ParameterColumn from "../Components/ParameterColumn";

import { SFsmContext } from "./Context";
import { GlobalContext } from "../GlobalContext";

function SFSMParametersPage({ setAdditionalStates, storeSfsmTestSuiteToPv, setFormulaFile }) {

    const ctxt = useContext(SFsmContext);
    const globalContext = useContext(GlobalContext)

    /**
     * Additional states functions
     */
    function onInputChangeAdditionalStatesField(typedStates) {
        setAdditionalStates(typedStates);
    }

    /**
     * Callback to increase additional states
     */
    function increaseAdditionalStates() {
        let x = 0;

        if ( ctxt.additionalStates !== '' ) {
            x = parseInt(ctxt.additionalStates);
        }
        x += 1;

        setAdditionalStates(x);
    }

    /**
     * Callback to decrease additional states
     */
    function decreaseAdditionalStates() {
        let x = 1;

        if ( ctxt.additionalStates !== '' ) {
            x = parseInt(ctxt.additionalStates);
        }

        if ( x > 0 ) {
            x -= 1;
            setAdditionalStates(x);
        }
    }

    /**
     * Return page state in JSX syntax
     */
    return (
        <>
            <Row>
                <ParameterColumn>
                    <h4>SFSM-Parameters</h4>
                </ParameterColumn>
            </Row>
            <Row>
                <ParameterColumn>
                    {/* Parameters Column */}
                    <Card headerName="Parameter">
                        {/* Row for additional states */}
                        <Row>
                            <Column className="col-6">
                                <h6>Additional-States</h6>
                            </Column>
                        </Row>
                        <Row className="row align-items-center">
                            <Column className="col-2 text-left">
                                <Button
                                    className="additional-states-picker"
                                    variant="secondary"
                                    onClick={() => decreaseAdditionalStates() }>
                                    -
                                </Button>
                            </Column>
                            <Column className="col-2 text-left">
                                <Input 
                                    id="sfsmAddStatesPicker" 
                                    type="text" 
                                    className="form-control text-center additional-states-picker"
                                    value={ ctxt.additionalStates }
                                    onChange={ (value) => onInputChangeAdditionalStatesField(value.target.value) }>
                                </Input>
                            </Column>
                            <Column className="col-2 text-left">
                                <Button
                                    className="additional-states-picker"
                                    variant="secondary"
                                    onClick={() => increaseAdditionalStates() }>
                                    +
                                </Button>
                            </Column>
                        </Row>
                    </Card>
                </ParameterColumn>
            </Row>

            <Row>
                <ParameterColumn>
                    <Card headerName="Using Model">
                        <Label id="currentUsingModelSFsm" className="ParameterLabel"> 
                            { globalContext.sfsmUploadedModel ? globalContext.sfsmUploadedModel : "No model selected."} 
                        </Label>
                    </Card>
                </ParameterColumn>
            </Row>

            <Row>
                <ParameterColumn>
                    <Card headerName="Using Formula-File">
                        {
                            (globalContext.sfsmFormulaFile 
                            ? <>
                                <Row>
                                    <Column className="col-md-2" >
                                        <Button
                                            className="btn btn-danger"
                                            onClick={ () => setFormulaFile("", "") }>
                                            x
                                        </Button>
                                    </Column>
                                    <Column>
                                        <Label id="textFormulaSfsm" className="ParameterLabel">
                                            { globalContext.sfsmFormulaFile }
                                        </Label>
                                    </Column>
                                </Row>
                              </>
                            : 
                                <Label id="textFormulaSfsm" className="ParameterLabel">
                                    { "No formula uploaded." }
                                </Label>)    
                        }
                    </Card>
                </ParameterColumn>
            </Row>

            <Row>
                <ParameterColumn>
                    <Card headerName="Store Artifacts">
                        <Button 
                            id="btnStoreTestSuite" 
                            className="btn btn-success" 
                            onClick={ () => storeSfsmTestSuiteToPv() }>
                            Store Test-Suite to PV
                        </Button>
                    </Card>
                </ParameterColumn>
            </Row>
        </>
    );
}

export default SFSMParametersPage;