import { getFsmTcgenBaseUri } from "./Configuration"

var theTimer = 0;

/**
 * Get Status of test generation
 */
function getStatus(fsmPageRef, modelName, testSuiteName, enableControls, setTestSuiteGenerated) {
    fetch(getFsmTcgenBaseUri() + modelName + "/status/" + testSuiteName, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        if ( data.terminated ) {
            fsmPageRef.addLog(data.message);

            clearTimeout(theTimer);
            enableControls();
            setTestSuiteGenerated();
        } else {
            fsmPageRef.addLog(data.message);
        }
    })
    .catch(error => {
        fsmPageRef.addLog(error)
        enableControls();
    });
}

/**
 * Generate tests for FSMs
 */
function FSMGenerateTests (fsmPageRef, modelName, testSuiteName, disableControls, enableControls, setTestSuiteGenerated) {

    /* The Data-Package */
    var jsonPackage = {
        "version": 1,
        "tcgen-method": fsmPageRef.getState().method,
        "additional-states": parseInt(fsmPageRef.getState().additionalStates),
        "handle-non-full-specified": fsmPageRef.getState().handle
    }

    /* Fetch resource */
    fetch(getFsmTcgenBaseUri() + modelName + '/generate/' + testSuiteName, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonPackage)
    })
    .then(response => response.json())
    .then(data => {
        if ( ! data.error) {
            disableControls();
            fsmPageRef.addLog('Test Case Generation job running ...');
            
            theTimer = setInterval(getStatus, 500, fsmPageRef, modelName, testSuiteName, enableControls, setTestSuiteGenerated);
        } else {
            fsmPageRef.addLog(data.message);
        }
    })
    .catch(error => fsmPageRef.addLog(error));
}

/**
 * Function to cancel Test Case Generation
 */
function FSMCancelTcGenJob(fsmPageRef, modelName, testSuiteName) {
    fetch(getFsmTcgenBaseUri() + modelName + "/cancel/" + testSuiteName, {
        method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
        if ( data.terminated && ! data.error ) {
            fsmPageRef.addLog(data.message);
            clearTimeout(theTimer);
        }
    })
    .catch(error => {
        fsmPageRef.addLog(error)
        clearTimeout(theTimer);
    });
}

/**
 * Function to store test suite to persisten volume
 */
function FSMStoreTestSuiteToPersistentVolume(fsmPageRef, modelName, testSuiteName) {
    /* Copy test-suite.txt to PV */
    fetch(getFsmTcgenBaseUri() + modelName + '/test-suite-to-pv/' + testSuiteName, {
        method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
        fsmPageRef.addLog(data.message);
    })
    .catch(error => fsmPageRef.addLog(error));
}

function FSMDownloadTestSuite(fsmPageRef, modelName, testSuiteName) {
    fetch(getFsmTcgenBaseUri() + modelName + '/test-suite/' + testSuiteName, {
        mathod: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        if ( ! data.error ) {
            if ( data.message === "" ) {
                fsmPageRef.addLog("Error: Test-Suite does not exist!");
            } else {
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(String(data.message)));
                element.setAttribute('download', modelName + "_test-suite.txt");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        } else {
            fsmPageRef.addLog(data.message);
        }
    })
    .catch(error => fsmPageRef.addLog(error));
}

// Export functions to other JavaScript modules
export { FSMDownloadTestSuite, FSMStoreTestSuiteToPersistentVolume, FSMCancelTcGenJob, FSMGenerateTests };