import React, { useState } from "react";
import Row from "../Components/Row";
import FileUploadColumn from "../Components/FileUploadColumn"
import TabNavBar from "../Components/TabNavBar";
import TabButton from "../Components/TabButton";
import TabContent from "../Components/TabContent";
import TabPane from "../Components/TabPane";
import Card from "../Components/Card";
import Column from "../Components/Column";
import Label from "../Components/Label";
import Button from "../Components/Button";
import { uploadHarness } from "../Actions/FileUpload";
import { getDateString } from "../Actions/Utils";

function SFSMMbtFileUploadPage({ setHarness, addLog }) {
    const harnessInputRef = React.createRef();

    const [state, setState] = useState({
        tab: 0, // 1: Harness, 2: Examples
        selectedHarness: ""
    });

    /*
     * Update the current tab num in class state
     * to `tabNum` 
     */
    function updateTabStateTo(tabNum) {
        setState({
            ...state,
            tab: tabNum
        })
    }

    function onChangeSelectHarness(event) {
        let file = event.target.files[0];
        var uniqueName = file.name + "_" + getDateString();
        uniqueName = uniqueName.replaceAll('/', '-').replaceAll(',', '').replaceAll(' ', '_').replaceAll(':', '-').replaceAll('\n', '');

        setState({
            ...state,
            selectedHarness: uniqueName
        })
    }

    function onClickUploadHarness() {
        uploadHarness(state.selectedHarness, harnessInputRef, setHarness, addLog);
    }

    /*
     * Update the example model selected 
     */
    function updateExampleHarnessTo(selectedExampleHarness) {
        setState({
            ...state,
            selectedHarness: selectedExampleHarness
        })

        setHarness(selectedExampleHarness, "Using Harness '" + selectedExampleHarness + "'")
    }

    return (
        <> 
            <Row>
                <FileUploadColumn>
                    <h4>File Upload</h4>
                </FileUploadColumn>
            </Row>
            <Row>
                <FileUploadColumn>
                    <TabNavBar className="nav nav-tabs" id="sfsm-nav-tab-files">
                        <TabButton
                            className="nav-link active"
                            id="sfsm-mbt-nav-harness-tab"
                            target="#sfsm-mbt-upload-harness-tab-pane"
                            ariaControl="sfsm-mbt-upload-harness-tab-pane"
                            onClick={ () => updateTabStateTo(1) }>
                            Harness
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="sfsm-nav-example-tab"
                            target="#sfsm-mbt-examples-tab-pane"
                            ariaControl="sfsm-mbt-examples-tab-pane"
                            onClick={ () => updateTabStateTo(2) }>
                            Examples
                        </TabButton>
                    </TabNavBar>

                    <TabContent id="sfsm-mbt-rightTabContent">
                        <TabPane id="sfsm-mbt-upload-harness-tab-pane" className="tab-pane active">
                            {/* Select Harness binary */}
                            <Card headerName="Upload Harness Binary">
                                <Row>
                                    <form id="uploadFormFsm"  
                                          encType="multipart/form-data"
                                          ref={ harnessInputRef }>
                                        <input 
                                            className="form-control harness-file-select"
                                            type="file" 
                                            name="uploadFile" 
                                            onChange={ onChangeSelectHarness }
                                            id="uploadFile"></input>
                                    </form>
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Label className="harness-file-name-header">Using harness:</Label><br></br>
                                        <Label className="harness-file-name">{ state.selectedHarness }</Label>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Button 
                                            bootstrapClass="btn btn-primary btn-gradient"
                                            id="uploadHarnessSFsm"
                                            onClick={() => onClickUploadHarness() }>
                                            Upload Harness
                                        </Button>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Button
                                            bootstrapClass="btn btn-primary btn-gradient get-harness-builder"
                                            onClick={ () => window.open('https://hub.docker.com/r/agbstestcloud/harness-builder', '_blank')}
                                            id="FsmGetHarnessBuilder">
                                            Get Harness-Builder
                                        </Button>
                                    </Column>
                                </Row>
                            </Card>
                        </TabPane>

                        <TabPane id="sfsm-mbt-examples-tab-pane" className="tab-pane">

                            {/* Example Harness */}
                            <Card headerName="Example Harness">
                                <Row>
                                    <Column className="col-12">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Use</th>
                                                    <th scope="col">Download</th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>Brake-Controller Harness</td>
                                                    <td>
                                                        <Button
                                                            bootstrapClass="btn btn-success bg-gradient"
                                                            id="btnUseExampleharnessSFsm"
                                                            onClick={() => updateExampleHarnessTo("brake-harness") }>
                                                            Use
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn btn-primary bg-gradient" 
                                                            type="button" 
                                                            href="resources/brake-harness.zip">
                                                            Download
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Column>
                                </Row>
                            </Card>
                        </TabPane>
                    </TabContent>
                </FileUploadColumn>
            </Row>
        </>
    );
}

export default SFSMMbtFileUploadPage;
