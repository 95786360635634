import React from "react";

const Pre = ({ id, className, children }) => {
    return ( 
        <pre id={ id } className={ className }>
            { children }
        </pre>
    );
};

export default Pre;