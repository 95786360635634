import React from "react"


const TabNavBar = ({className, id, children}) => {
    return (
        <div className={ className ? className : "nav" } id={ id ? id : "123" } role="tablist">
            { children }
        </div>
    );
};

export default TabNavBar;