import './ContentPage.css';

import Row from './Components/Row';
import './ContentPage.css';
import Footer from './Footer';
import Column from './Components/Column';

function About() {
    return(
        <div className='ContentPage'>
            <Row className="row header-row">
                <Column className='col-1'>
                    <img src="unilogo.jpg" width="100px" alt="Uni HB Logo"/>
                </Column>
                <Column className='col-md-11'>
                    <h2>AGBS Test Cloud - About</h2>
                </Column>
            </Row>
            <Row className="AboutRow">
                <pre className="address-pre">
                    IMPRINT <br /> <br />
                    University of Bremen<br/>
                    Research Group Operating Systems, Distributed Systems<br />
                    Bibliothekstraße 1<br />
                    D-28359 Bremen<br />
                    <a href="http://www.informatik.uni-bremen.de/agbs/index_e.html">AGBS Website</a>
                </pre>
            </Row>
            <Footer>

            </Footer>
      </div>
    )
}

export default About;