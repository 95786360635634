import { getSFsmTcgenBaseUri } from "./Configuration"

var theTimer = 0;

function getTcGenStatus (sfsmPageRef, modelName, testSuiteName, disableControls, enableControls, setTestSuiteGenerated) {
    // https://testcloud-service.informatik.uni-bremen.de/test-cloud-api/job-status/<job-id>
    fetch(getSFsmTcgenBaseUri() + modelName + '/status/' + testSuiteName, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        /* Request data success, present data to client */
        if ( data.terminated ) {
            clearTimeout(theTimer);

            enableControls();
            setTestSuiteGenerated();
        } else {
            disableControls();
        }
        if ( data.message !== "" ) {
            sfsmPageRef.addLog(data.message);
        }
    })
    .catch(error => sfsmPageRef.addLog(error));
}

function SFSMGenerateTests(sfsmPageRef, modelName, formulaFile, testSuiteName, disableControls, enableControls, setTestSuiteGenerated) {
    // The Data-Package to POST 
    var jsonPackage = {
        "version": "1",
        "additional-states": parseInt(sfsmPageRef.getState().additionalStates),
        "formula-filename": formulaFile
    }

    fetch(getSFsmTcgenBaseUri() + modelName + '/generate/' + testSuiteName, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonPackage)
    })
    .then(response => response.json())
    .then(data => {
        if ( ! data.error ) {
            theTimer = setInterval(getTcGenStatus, 1000, sfsmPageRef, modelName, testSuiteName, disableControls, enableControls, setTestSuiteGenerated);
            disableControls();

            sfsmPageRef.addLog('Test Case Generation job running for SFSM ...');
        } else {
            sfsmPageRef.addLog(data.message)
        }
    })
    .catch(error => sfsmPageRef.addLog(error));
}


/**
 * Function to cancel Test Case Generation
 */
function SFSMCancelTcGenJob(sfsmPageRef, modelName, testSuiteName) {
    fetch(getSFsmTcgenBaseUri() + modelName + "/cancel/" + testSuiteName, {
        method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
        if ( data.terminated && ! data.error ) {
            sfsmPageRef.addLog(data.message);
            clearTimeout(theTimer);
        }
    })
    .catch(error => {
        sfsmPageRef.addLog(error)
        clearTimeout(theTimer);
    });
}

/**
 * Function to store test suite to persisten volume
 */
function SFSMStoreTestSuiteToPersistentVolume(sfsmPageRef, modelName, testSuiteName, formulaFile) {
    /* Copy test-suite.txt to PV */
    console.log("Request: " + getSFsmTcgenBaseUri() + modelName + '/test-suite-to-pv/' + testSuiteName + '/' + formulaFile);

    fetch(getSFsmTcgenBaseUri() + modelName + '/test-suite-to-pv/' + testSuiteName + '/' + formulaFile, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    })
    .then(response => response.json())
    .then(data => {
        sfsmPageRef.addLog(data.message);
    })
    .catch(error => {
        sfsmPageRef.addLog("Error during request to store test-suite: " + error);
    });
}

function SFSMDownloadTestSuite(sfsmPageRef, modelName, testSuiteName) {
    fetch(getSFsmTcgenBaseUri() + modelName + '/test-suite/' + testSuiteName, {
        mathod: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        if ( ! data.error ) {
            if ( data.message === "" ) {
                sfsmPageRef.addLog("Error: Test-Suite does not exist!");
            } else {
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(String(data.message)));
                element.setAttribute('download', modelName + "_test-suite.txt");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        } else {
            sfsmPageRef.addLog(data.message);
        }
    })
    .catch(error => sfsmPageRef.addLog(error));
}

export { SFSMGenerateTests, SFSMCancelTcGenJob, SFSMStoreTestSuiteToPersistentVolume, SFSMDownloadTestSuite }