import React from "react"

/*
 * A immutable Paragraph component
 */
const Button = ({bootstrapClass, id, onClick, children}) => {
    return <button 
                className={ bootstrapClass ? bootstrapClass : "btn" }
                id={ id ? id : "" }
                onClick={ onClick }>
                    { children }
                </button>
};

export default Button;