/**
 * @brief
 *   This is the Tab-Page that is responsible for everything underneath the 
 *   navigation-bar
 */
import "./TabPage.css"

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import React, { useEffect, useRef, useState } from "react";
import Column from "./Components/Column";
import Row from "./Components/Row";
import TabNavBar from "./Components/TabNavBar";
import TabContent from "./Components/TabContent";
import TabButton from "./Components/TabButton";
import TabPane from "./Components/TabPane";
import FSMPage from "./FSMPage/FSMPage";
import SFSMPage from "./SFSMPage/SFSMPage";

import { GetServerStatus } from "./Actions/GetServerStatus";
import { FSMGenerateTests, FSMCancelTcGenJob, FSMStoreTestSuiteToPersistentVolume, FSMDownloadTestSuite } from "./Actions/FSMGenerateTests";
import { ExecuteTests, CancelHarnessExecutionJob, DownloadTestExecutionVerdict, StoreVerdictToPersistentVolume } from "./Actions/ExecuteTests";
import { CancelPotExecutionJob, POTDownloadVerdict, POTExecution } from "./Actions/POTExecution";
import { TestCloudModal } from "./Components/Modal";
import { generateTestSuiteName, generateTestVerdictName, getDateString, getLogText } from "./Actions/Utils";
import TestStoreCloudModal from "./Components/StoreModal";
import { GlobalContext } from "./GlobalContext"

import { modelWithNameExists, isSFSMCsvFile, fileWithSuffixExists, isCsvFile, isPartOfFsmFile, getNameOfFile, getSuffixOfFile } from "./Utils"
import { SFSMCancelTcGenJob, SFSMDownloadTestSuite, SFSMGenerateTests, SFSMStoreTestSuiteToPersistentVolume } from "./Actions/SFSMGenerateTests";
import SFSMMbtPage from "./SFSM-MBT-Page/SFSMMbtPage";
import TestManagementPage from "./Test-Management-Page/TestManagement";
import { getNeo4JToken, getNeo4JUrl } from "./Actions/Configuration";
import POTPage from "./POT/POTPage";

/**
 * Enumeration of the tabs
 */
const TAB = {
    FSM_TAB: 1,
    SFSM_TAB: 2,
    SFSM_MBT_TAB: 3,
    TEST_MANAGEMENT_TAB: 4,
    POT_TAB: 5
}

const JOB_TYPE = {
    INVALID: -1,
    TEST_GENERATION: 0,
    TEST_EXECUTION: 1
}

/**
 * This is the implementation of the tab-page
 */
function TabPage() {

    /**
     * This attributes are used for 'useEffect()' hooks:
     * every 'useEffect()' is already executed while the page
     * is rendered. Because we only want to react on
     * special conditions and not during init-phase, we
     * create variables that stores the current render state.
     * If it was not rendered, the variables are false.
     */
    const generateTestsRendered = useRef(false);
    const generateSfsmTestsRendered = useRef(false);
    const executeTestsRendered = useRef(false);
    const executeSfsmTestsRendered = useRef(false);
    const executePotTestsRendered = useRef(false);
    const cancelJobRenderd = useRef(false);
    const showSaveTestSuiteModalRendered = useRef(false);
    const showSaveSfsmTestSuiteModalRendered = useRef(false);
    const showSaveTestVerdictRendered = useRef(false);
    const showSaveSfsmTestVerdictRendered = useRef(false);

    // Reference to the FSM Page
    const fsmRef = React.createRef();

    // Reference to the SFSM Page
    const sfsmRef = React.createRef();

    // Reference to the SFSM-MBT Page
    const sfsmMbtRef = React.createRef();

    // Reference to the POT Page
    const potRef = React.createRef();

    // Reference to the test management page
    const tmPageRef = React.createRef();

    var processModelsLog = "";

    /*
     * This is the current UI state-object of the 'TabPage'
     * so that all child-components have access to it. The property 
     * 'fsmLogText' stores the current text of the log window. The Functions
     * 'addToFsmLog()' is called and changes the state of the ui.
     */
    const [state, setState] = useState({
        tab: TAB.FSM_TAB,
        noModelModalActive: false,
        noFaultDomainModalActive: false,
        noPropertyModalActive: false,
        wrongTabForSfsmTcGenActive: false,
        wrongTabForSfsmTcExecutionActive: false,
        wrongTabTcExecutionActive: false,
        errorToManyFilesOpenActive: false,
        noHarnessModalActive: false,
        noTestSuiteModalActive: false,
        noTestExecutionVerdictModalActive: false,
        storeTestSuiteModalActive: false,
        storeTestVerdictModalActive: false,
        noTcGenerationInPotPageModalActive: false,
        potWrongFileFormatModalActive: false,
        spinnerOn: false,
        controlsDisabled: false,
        fsmModels: [],
        fsmUploadedModel: "",
        fsmTestSuiteName: "",
        fsmTestSuiteNameManualSet: false,
        fsmTestSuiteGenerated: false,
        fsmHarness: "",
        fsmHarnessExecuted: false,
        fsmTestExecutionVerdictName: "",
        fsmCurrentJobType: JOB_TYPE.INVALID,              // 0: TC-Gen, 1: TC-Exe
        sfsmCurrentJobType: JOB_TYPE.INVALID,              // 0: TC-Gen, 1: TC-Exe
        cancelJob: false,
        sfsmModels: [],
        sfsmUploadedModel: "",
        sfsmTestSuiteName: "",
        sfsmTestSuiteNameManualSet: false,
        sfsmTestSuiteGenerated: false,
        sfsmHarness: "",
        sfsmFormulaFile: "",
        sfsmHarnessExecuted: false,
        sfsmTestExecutionVerdictName: "",
        potHarness: "",
        potFaultDomain: "",
        potSelectedProperty: "",
        potVerdictName: "",
        potHarnessExecuted: false
    });

    const [downloadArtifactFile, setDownloadArtifactFile] = useState({
        start: false,
        finished: false,
        name: "",
        class: "",
        text: "",
    });

    useEffect(() => {
        if ( downloadArtifactFile.start ) {
            if ( downloadArtifactFile.name === "" ) {
                showNoTestSuiteModal();
                return;
            }

            state.fileContent = "";
            
            // The request Package
            const jsonPackage = {
                "statements": [
                    {
                        "statement": "MATCH(x:" + downloadArtifactFile.class + ") where x.name = \"" + downloadArtifactFile.name + "\" RETURN x;",
                        "parameters": {
                            "props": {
                            }
                        }
                    }
                ]
            }

            // Fetch Data
            fetch(getNeo4JUrl(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + getNeo4JToken()
                },
                body: JSON.stringify(jsonPackage)
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error('While fetching data.');
                }
            }).then(data => {
                return data;
            })
            .then((content) => {
                fetch(content["commit"], {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Basic ' + getNeo4JToken()
                    },
                })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else {
                        throw new Error("During commit");
                    }
                });
                
                content["results"].forEach((result) => {
                    if ( ! result["data"].length ) {
                        setDownloadArtifactFile({
                            ...downloadArtifactFile,
                            finished: true,
                            text: "Error, file not found"
                        })

                        return;
                    }
                    result["data"].forEach((element) => {
                        if ( ! element["row"].length ) {
                            setDownloadArtifactFile({
                                ...downloadArtifactFile,
                                finished: true,
                                text: "Error, file not found"
                            })

                            return;
                        }

                        element["row"].forEach((object) => {
                            setDownloadArtifactFile({
                                ...downloadArtifactFile,
                                finished: true,
                                text: object["content"]
                            })
                        })
                    })
                })
            })
            .catch(error => {
                setDownloadArtifactFile({
                    ...downloadArtifactFile,
                    finished: true,
                    text: "Error during fetch"
                })
            });
        }
    }, [downloadArtifactFile.start]);  // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if ( downloadArtifactFile.finished ) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(downloadArtifactFile.text));
            element.setAttribute('download', downloadArtifactFile.name);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);

            setDownloadArtifactFile({
                ...downloadArtifactFile,
                start: false,
                name: "",
                class: "",
                finished: false
            })
        }
    }, [downloadArtifactFile.finished]); // eslint-disable-line react-hooks/exhaustive-deps

    // -------------------------------------------------------------
    // Event-Based Execution
    // =====================
    // 
    // Subscribe to event in state. If this 
    // event changes, execute the function inside 'useEffect'.
    // ------------------------------------------------------------- 

    /**
     * Generate Tests if state.fsmTestSuiteName is set
     */
    useEffect(() => {
        if ( generateTestsRendered.current ) {
            if ( state.tab === TAB.FSM_TAB ) {
                if ( ! state.fsmTestSuiteNameManualSet ) {
                    if ( fsmRef.current.getState().model === "" ) {
                        showNoModelModal();
                        return;
                    }

                    if ( state.fsmTestSuiteName !== "" && ! state.fsmTestSuiteGenerated ) {
                        FSMGenerateTests(fsmRef.current, state.fsmUploadedModel, state.fsmTestSuiteName, disableControls, enableControls, setTestSuiteGenerated);
                    }
                }
            }
        }
        else {
            generateTestsRendered.current = true;
        }
    }, [state.fsmTestSuiteName]);  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Listen to event to generate Tests for SFSMs
     */
    useEffect(() => {
        if ( generateSfsmTestsRendered.current ) {
            if ( state.tab === TAB.SFSM_TAB ) {
                if ( ! state.sfsmTestSuiteNameManualSet ) {
                    if ( sfsmRef.current.getState().model === "" ) {
                        showNoModelModal();
                        return;
                    }

                    if ( state.sfsmTestSuiteName !== "" && ! state.sfsmTestSuiteGenerated ) {
                        SFSMGenerateTests(sfsmRef.current, state.sfsmUploadedModel, state.sfsmFormulaFile, state.sfsmTestSuiteName, disableControls, enableControls, setSfsmTestSuiteGenerated)
                    }
                }
            }
        } 
        else {
            generateSfsmTestsRendered.current = true;
        }
    }, [state.sfsmTestSuiteName]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Cancel Job listener
     */
    useEffect(() => {
        if ( cancelJobRenderd.current ) {
            switch ( state.tab ) {
                case TAB.FSM_TAB: {
                    if ( state.fsmCurrentJobType === JOB_TYPE.INVALID ) {
                        return;
                    }
                    
                    // Cancel Test-Case Generation
                    if ( state.fsmCurrentJobType === JOB_TYPE.TEST_GENERATION && state.cancelJob ) {
                        FSMCancelTcGenJob(fsmRef.current, state.fsmUploadedModel, state.fsmTestSuiteName);

                        setState({
                            ...state,
                            cancelJob: false,
                            controlsDisabled: false,
                            fsmCurrentJobType: JOB_TYPE.INVALID
                        })
                    }

                    // Cancel Harness-Execution
                    if ( state.fsmCurrentJobType === JOB_TYPE.TEST_EXECUTION && state.cancelJob  ) {
                        CancelHarnessExecutionJob(fsmRef.current, state.fsmTestExecutionVerdictName);

                        setState({
                            ...state,
                            cancelJob: false,
                            controlsDisabled: false,
                            fsmCurrentJobType: JOB_TYPE.INVALID
                        })
                    }
                    break;
                }
                case TAB.SFSM_TAB: {
                    if ( state.sfsmCurrentJobType === JOB_TYPE.INVALID ) {
                        return;
                    }
                    
                    // Cancel Test-Case Generation
                    if ( state.sfsmCurrentJobType === JOB_TYPE.TEST_GENERATION && state.cancelJob  ) {
                        SFSMCancelTcGenJob(sfsmRef.current, state.sfsmUploadedModel, state.sfsmTestSuiteName);

                        setState({
                            ...state,
                            cancelJob: false,
                            controlsDisabled: false,
                            sfsmCurrentJobType: JOB_TYPE.INVALID
                        })
                    }

                    // Cancel Harness-Execution
                    if ( state.sfsmCurrentJobType === JOB_TYPE.TEST_EXECUTION && state.cancelJob  ) {
                        CancelHarnessExecutionJob(sfsmRef.current, state.sfsmTestExecutionVerdictName);

                        setState({
                            ...state,
                            cancelJob: false,
                            controlsDisabled: false,
                            sfsmCurrentJobType: JOB_TYPE.INVALID
                        })
                    }

                    break;
                }

                case TAB.POT_TAB: {
                    if ( state.potVerdictName === "" ) {
                        return;
                    }

                    if ( state.cancelJob ) {
                        CancelPotExecutionJob(potRef.current, state.potVerdictName);

                        setState({
                            ...state,
                            cancelJob: false,
                            controlsDisabled: false,
                        })
                    }

                    break;
                }
                default:
                    break;
            }
        } 
        else {
            cancelJobRenderd.current = true;
        }
    }, [state.cancelJob]);  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Listener for the FSM 'execute Tests' events.
     */
    useEffect(() => {
        if ( executeTestsRendered.current ) {
            if ( state.tab === TAB.FSM_TAB ) {
                if ( state.fsmUploadedModel === "" ) {
                    showNoModelModal();
                    return;
                }
        
                if ( state.fsmTestSuiteName === "" ) {
                    showNoTestSuiteModal();
                    return;
                }
        
                if ( state.fsmHarness === "" ) {
                    showNoHarnessModal();
                    return;
                }

                if ( state.fsmTestExecutionVerdictName !== "" && ! state.fsmHarnessExecuted ) {
                    ExecuteTests(fsmRef.current, state.fsmUploadedModel, state.fsmHarness, state.fsmTestExecutionVerdictName, state.fsmTestSuiteName, 
                                    disableControls, enableControls, setHarnessExecuted);
                }
            }
        }
        else {
            executeTestsRendered.current = true;
        }
    }, [state.fsmTestExecutionVerdictName]);  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Listener for the SFSM 'execute Tests' events.
     */
    useEffect(() => {
        if ( executeSfsmTestsRendered.current ) {
            if ( state.tab === TAB.SFSM_MBT_TAB ) {
                if ( state.sfsmUploadedModel === "" ) {
                    showNoModelModal();
                    return;
                }
        
                if ( state.sfsmTestSuiteName === "" ) {
                    showNoTestSuiteModal();
                    return;
                }
        
                if ( state.sfsmHarness === "" ) {
                    showNoHarnessModal();
                    return;
                }

                if ( state.sfsmTestExecutionVerdictName !== "" && ! state.sfsmHarnessExecuted ) {
                    ExecuteTests(sfsmMbtRef.current, state.sfsmUploadedModel, state.sfsmHarness, state.sfsmTestExecutionVerdictName, state.sfsmTestSuiteName, 
                                    disableControls, enableControls, setSfsmHarnessExecuted);
                }
            }
        }
        else {
            executeSfsmTestsRendered.current = true;
        }
    }, [state.sfsmTestExecutionVerdictName]);  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Listener for the SFSM 'execute Tests' events.
     */
    useEffect(() => {
        if ( executePotTestsRendered.current ) {
            if ( state.tab === TAB.POT_TAB ) {
                if ( state.potFaultDomain === "" ) {
                    showNoFaultDomain();
                    return;
                }
        
                if ( state.potSelectedProperty === "" ) {
                    showNoProperty()
                    return;
                }
        
                if ( state.potHarness === "" ) {
                    showNoHarnessModal();
                    return;
                }

                if ( state.potVerdictName !== "" && ! state.potHarnessExecuted ) {
                    POTExecution(potRef.current, state.potFaultDomain, state.potHarness, state.potVerdictName, state.potSelectedProperty, 
                                    disableControls, enableControls, setPotHarnessExecuted);
                }
            }
        }
        else {
            executePotTestsRendered.current = true;
        }
    }, [state.potVerdictName]);  // eslint-disable-line react-hooks/exhaustive-deps


    /**
     * Listener for the Modal for storing the Test Suite.
     */
    useEffect(() => {
        if ( showSaveTestSuiteModalRendered.current ) {
            if ( state.fsmTestSuiteGenerated ) {
                setState({
                    ...state,
                    storeTestSuiteModalActive: true,
                })
            }
        } 
        else {
            showSaveTestSuiteModalRendered.current = true;
        }
    }, [state.fsmTestSuiteGenerated]);  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Listener for the Modal for storing the Test Suite of SFSM.
     */
    useEffect(() => {
        if ( showSaveSfsmTestSuiteModalRendered.current ) {
            if ( state.sfsmTestSuiteGenerated ) {
                setState({
                    ...state,
                    storeTestSuiteModalActive: true,
                })
            }
        } 
        else {
            showSaveSfsmTestSuiteModalRendered.current = true;
        }
    }, [state.sfsmTestSuiteGenerated]);  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Listener for the event, that the fsm harness was executed.
     */
    useEffect(() => {
        if ( showSaveTestVerdictRendered.current ) {
            if ( state.fsmHarnessExecuted ) {
                setState({
                    ...state,
                    storeTestVerdictModalActive: true
                })
            }
        } else {
            showSaveTestVerdictRendered.current = true;
        }
    }, [state.fsmHarnessExecuted]);  // eslint-disable-line react-hooks/exhaustive-deps


    /**
     * Listener for the event, that the sfsmharness was executed.
     */
    useEffect(() => {
        if ( showSaveSfsmTestVerdictRendered.current ) {
            if ( state.sfsmHarnessExecuted ) {
                setState({
                    ...state,
                    storeTestVerdictModalActive: true
                })
            }
        } else {
            showSaveSfsmTestVerdictRendered.current = true;
        }
    }, [state.sfsmHarnessExecuted]);  // eslint-disable-line react-hooks/exhaustive-deps

    // ------------------------------------------------
    // Change state functions
    // ------------------------------------------------
    /**
     * Change tab callback
     */
    function changeTab(id) {
        setState({
            ...state,
            tab: id
        })
    }

    /**
     * Function to disable all controls 
     */
    function disableControls() {
        setState({
            ...state,
            spinnerOn: true,
            controlsDisabled: true
        })
    }

    /**
     * Function to enable all controls
     */
    function enableControls() {
        setState({
            ...state,
            spinnerOn: false,
            controlsDisabled: false
        })
    }

    /**
     * Function to set the state that the test suite was generated
     */
    function setTestSuiteGenerated() {
        setState({
            ...state,
            fsmTestSuiteGenerated: true,
            spinnerOn: false
        })
    }

    /**
     * Function to set the state that the test suite was generated
     */
    function setSfsmTestSuiteGenerated() {
        setState({
            ...state,
            sfsmTestSuiteGenerated: true,
            spinnerOn: false
        })
    }

    /**
     * Function to set harness executed to true
     */
    function setHarnessExecuted() {
        setState({
            ...state,
            fsmHarnessExecuted: true,
            spinnerOn: false
        })
    }

    /**
     * Function to set harness executed to true
     */
    function setSfsmHarnessExecuted() {
        setState({
            ...state,
            sfsmHarnessExecuted: true,
            spinnerOn: false
        })
    }

    /**
     * Function to set harness executed to true
     */
    function setPotHarnessExecuted() {
        setState({
            ...state,
            potHarnessExecuted: true,
            spinnerOn: false
        })
    }

    /**
     * Sets globally the name of the uploaded sfsm model.
     */
    function setSfsmModel(name) {
        setState({
            ...state,
            sfsmUploadedModel: name
        })
    }

    /**
     * Sets globally the SFSM Formula File.
     */
    function setSfsmFormulaFile(name) {
        setState({
            ...state,
            sfsmFormulaFile: name
        })
    }

    function setPotPropertyFile(name) {
        setState({
            ...state,
            potSelectedProperty: name
        })
    }

    /**
     * Sets globally the name of the uploaded fsm model.
     */
    function setfsmModel(name) {
        setState({
            ...state,
            fsmUploadedModel: name
        })
    }

    /**
     * Sets globally the name of the uploaded fsm and sfsm model.
     */
    function setModels(fsmModelName, sfsmModelName) {
        setState({
            ...state,
            fsmUploadedModel: fsmModelName,
            sfsmUploadedModel: sfsmModelName
        })
    }

    function setTestSuite(fsmTestSuiteName, sfsmTestSuiteName) {
        setState({
            ...state,
            fsmTestSuiteName: fsmTestSuiteName,
            fsmTestSuiteNameManualSet: true,
            sfsmTestSuiteName: sfsmTestSuiteName,
            sfsmTestSuiteNameManualSet: true
        })
    }

    function setHarness(fsmHarness, sfsmHarness) {
        setState({
            ...state, 
            fsmHarness: fsmHarness,
            sfsmHarness: sfsmHarness
        })
    }

    async function clearValues() {
        setState({
            ...state,
            fsmUploadedModel: "",
            sfsmUploadedModel: "",
            sfsmFormulaFile: "",
            fsmHarness: "",
            sfsmHarness: "",
            sfsmTestSuiteName: "",
            fsmTestSuiteName: ""
        })
    }

    function storeSfsmTestSuiteToPv() {
        if ( state.sfsmUploadedModel === "" ) {
            showNoModelModal();
            return;
        }

        if ( state.sfsmTestSuiteName === "" ) {
            showNoTestSuiteModal();
            return;
        }

        SFSMStoreTestSuiteToPersistentVolume(sfsmRef.current, state.sfsmUploadedModel, state.sfsmTestSuiteName, state.sfsmFormulaFile);
    }

    function storeFsmTestSuiteToPv() {
        if ( state.fsmUploadedModel === "" ) {
            showNoModelModal();
            return;
        }

        if ( state.fsmTestSuiteName === "" ) {
            showNoTestSuiteModal();
            return;
        }

        FSMStoreTestSuiteToPersistentVolume(fsmRef.current, state.fsmUploadedModel, state.fsmTestSuiteName);
    }

    // ------------------------------------------------
    // Parsing functions
    // ------------------------------------------------
    /**
     * Adds a CSV file to the imported list
     */
    function addCsvModel(name, isSFSM, CsvContent) {
        var uniqueName = name + "_" + getDateString();
        uniqueName = uniqueName.replaceAll('/', '-').replaceAll(',', '').replaceAll(' ', '_').replaceAll(':', '-').replaceAll('\n', '');

        if ( isSFSM ) {
            state.sfsmModels.push({ 
                name: name, 
                uniqueName: uniqueName,
                isFsmType: false, 
                fsmFileContent: "", 
                stateFileContent: "", 
                inFileContent: "", 
                outFileContent: "", 
                csvFileContent: CsvContent, 
                isSfsm: isSFSM,
                checked: false })
        }
        else {
            state.fsmModels.push({ 
                name: name, 
                uniqueName: uniqueName,
                isFsmType: false, 
                fsmFileContent: "", 
                stateFileContent: "", 
                inFileContent: "", 
                outFileContent: "", 
                csvFileContent: CsvContent, 
                isSfsm: isSFSM,
                checked: false })
        }

        setState({
            ...state
        })
    }

    /**
     * Add or updates a FSM model
     */
    function addOrUpdateFsmModel(name, content, suffix) {
        let exist = false;

        // search for existing model and update the 
        // object if exists.
        state.fsmModels.forEach((elem) => {
            if ( elem.name === name ) {
                exist = true;
                elem.isFsmType = true;
                elem.isSfsm = false;
                elem.name = name;

                if ( suffix === "fsm" ) {
                    elem.fsmFileContent = content;
                }

                if ( suffix === "state" ) {
                    elem.stateFileContent = content;    
                }

                if ( suffix === "in" ) {
                    elem.inFileContent = content;  
                } 

                if ( suffix === "out" ) {
                    elem.outFileContent = content;    
                }
            }
        });

        // if not exist, create new one
        if ( ! exist ) {

            var uniqueName = name +  "_" + getDateString();
            uniqueName = uniqueName.replaceAll('/', '-').replaceAll(',', '').replaceAll(' ', '_').replaceAll(':', '-').replaceAll('\n', '');

            if ( suffix === "fsm" ) {
                state.fsmModels.push({ 
                    name: name, 
                    uniqueName: uniqueName,
                    isFsmType: false, 
                    fsmFileContent: content, 
                    stateFileContent: "", 
                    inFileContent: "", 
                    outFileContent: "", 
                    csvFileContent: "", 
                    isSfsm: false,
                    checked: false });
            }

            if ( suffix === "state" ) {
                state.fsmModels.push({ 
                    name: name, 
                    uniqueName: uniqueName,
                    isFsmType: false, 
                    fsmFileContent: "", 
                    stateFileContent: content, 
                    inFileContent: "", 
                    outFileContent: "", 
                    csvFileContent: "", 
                    isSfsm: false,
                    checked: false });
            }

            if ( suffix === "in" ) {
                state.fsmModels.push({ 
                    name: name, 
                    uniqueName: uniqueName,
                    isFsmType: false, 
                    fsmFileContent: "", 
                    stateFileContent: "", 
                    inFileContent: content, 
                    outFileContent: "", 
                    csvFileContent: "", 
                    isSfsm: false,
                    checked: false })
            } 

            if ( suffix === "out" ) {   
                state.fsmModels.push({ 
                    name: name, 
                    uniqueName: uniqueName,
                    isFsmType: false, 
                    fsmFileContent: "", 
                    stateFileContent: "", 
                    inFileContent: "", 
                    outFileContent: content, 
                    csvFileContent: "", 
                    isSfsm: false,
                    checked: false })
            }
        }

        setState({
            ...state
        })

    }

    /**
     * Processes a CSV file
     */
    function processCsvFile(name, fileHandle, callback) {
        const reader = new FileReader();
        reader.readAsText(fileHandle);
        reader.onload = () => {
            var content = reader.result;
            callback(name, isSFSMCsvFile(content), reader.result);
        };
    }

    /**
     * Process a FSM File 
     */
    function processFsmFile(name, fileHandle, callback) {
        const reader = new FileReader();
        reader.readAsText(fileHandle);
        reader.onload = () => {
            callback(name, reader.result, getSuffixOfFile(fileHandle.name));
        };
    }

    async function parseElement(files, handleLog) {
        files.forEach( async (elem) => {
            if ( isCsvFile(elem.name) ) {
                let name = getNameOfFile(elem.name);
                if ( modelWithNameExists(state.fsmModels, name) ) {
                    console.log("Duplicate Model-File: ", elem.name);
                    await handleLog("Duplicate Model-File: ", elem.name)
                } else {
                    processCsvFile(name, elem, addCsvModel);
                }
            }

            if ( isPartOfFsmFile(elem.name) ) {
                let name = getNameOfFile(elem.name);
                if ( fileWithSuffixExists(files, name + ".fsm") && 
                        fileWithSuffixExists(files, name + ".state") && 
                        fileWithSuffixExists(files, name + ".in") && 
                        fileWithSuffixExists(files, name + ".out")) {
                    processFsmFile(name, elem, addOrUpdateFsmModel);
                } 
                else {
                    var text = "FSM Model file is not complete: " + elem.name;
                    await handleLog(text);
                }
            }
        });
    }

    async function handleLog(text) {
        processModelsLog = getLogText(processModelsLog, text);
    }

    /**
     * Process model files and parse them. The parameter
     * 'files' if a file-handle, received by a callback of
     * input (type: file)
     */
    async function processModelFiles(files) {
        if ( files.length > 1000 ) {
            setState({
                ...state,
                errorToManyFilesOpenActive: true
            })
            return;
        }

        await parseElement(files, handleLog);
        if ( processModelsLog !== "" ) {
            fsmRef.current.addLog(processModelsLog);
            sfsmRef.current.addLog(processModelsLog);
        }
    }

    // Set name of uploaded FSM Harness in global context
    function setFsmHarness(name) {
        setState({
            ...state,
            fsmHarness: name
        })
    }

    // Set name of uploaded SFSM Harness in global context
    function setSFsmHarness(name) {
        setState({
            ...state,
            sfsmHarness: name
        })
    }

    // Set name of uploaded POT Harness in global context
    function setPotHarness(name) {
        setState({
            ...state,
            potHarness: name
        })
    }
    
    // Set name of uploaded POT Harness in global context
    function setPotFaultDomain(name) {
        setState({
            ...state,
            potFaultDomain: name
        })
    }

    // ------------------------------------------------------------------
    // onClick-Callbacks
    // ------------------------------------------------------------------
    /**
     * Callback for Download TestSuite
     */
    function onClickDownloadTestSuite() {
        if ( state.tab === TAB.FSM_TAB ) {
            if ( ! state.fsmTestSuiteGenerated ) {
                showNoTestSuiteModal();
                return;
            }

            if ( state.fsmTestSuiteName === "" ) {
                showNoTestSuiteModal();
                return;
            }

            FSMDownloadTestSuite(fsmRef.current, state.fsmUploadedModel, state.fsmTestSuiteName)
            
        }

        if ( state.tab === TAB.SFSM_TAB || state.tab === TAB.SFSM_MBT_TAB ) {
            if ( ! state.sfsmTestSuiteGenerated && ! state.sfsmTestSuiteNameManualSet ) {
                showNoTestSuiteModal();
                return;
            }

            if ( state.sfsmUploadedModel === "" ) {
                showNoModelModal();
                return;
            }

            if ( state.sfsmTestSuiteName === "" ) {
                showNoTestSuiteModal();
                return;
            }

            if ( state.sfsmUploadedModel !== "" && state.sfsmTestSuiteNameManualSet ) {
                SFSMDownloadTestSuite(sfsmRef.current, state.sfsmUploadedModel, state.sfsmTestSuiteName.replace(state.sfsmUploadedModel + "_", ""));
                return;
            }

            SFSMDownloadTestSuite(sfsmRef.current, state.sfsmUploadedModel, state.sfsmTestSuiteName);
        }

        if ( state.tab === TAB.TEST_MANAGEMENT_TAB ) {

            setDownloadArtifactFile({
                class: "TestSuite",
                name: tmPageRef.current.getState().selectedTestSuite,
                start: true
            })
        }
    }

    /**
     * Callback function changes the state and the form reacts
     * reactively
     */
    function onClickGenerateTests() {
        if ( state.tab === TAB.FSM_TAB ) {

            if ( state.fsmUploadedModel === "" ) {
                setState({
                    ...state,
                    noModelModalActive: true
                })
            }
            else {
                setState({
                    ...state,
                    fsmTestSuiteName: generateTestSuiteName(),
                    fsmTestSuiteNameManualSet: false,
                    fsmCurrentJobType: JOB_TYPE.TEST_GENERATION,
                    fsmTestSuiteGenerated: false,
                    cancelJob: false
                })
            }
        }

        if ( state.tab === TAB.SFSM_TAB ) {
            if ( state.sfsmUploadedModel === "" ) {
                setState({
                    ...state,
                    noModelModalActive: true
                })
            }
            else {
                setState({
                    ...state,
                    sfsmTestSuiteName: generateTestSuiteName(),
                    sfsmTestSuiteNameManualSet: false,
                    sfsmCurrentJobType: JOB_TYPE.TEST_GENERATION,
                    sfsmTestSuiteGenerated: false,
                    cancelJob: false
                })
            }
        }

        if ( state.tab === TAB.SFSM_MBT_TAB ) {
            setState({
                ...state,
                wrongTabForSfsmTcGenActive: true
            })
        }

        if ( state.tab === TAB.TEST_MANAGEMENT_TAB ) {
            setState({
                ...state,
                wrongTabForSfsmTcGenActive: true
            })
        }

        if ( state.tab === TAB.POT_TAB ) {
            showNoTcGenerationInPotModal();
        }
    }

    function onClickKillJob() {
        setState({
            ...state,
            cancelJob: true,
            spinnerOn: false
        })
    }

    function onStoreTestSuiteToPV() {
        if ( state.tab === TAB.FSM_TAB ) {
            FSMStoreTestSuiteToPersistentVolume(fsmRef.current, state.fsmUploadedModel, state.fsmTestSuiteName);
        }

        if ( state.tab === TAB.SFSM_TAB ) {
            SFSMStoreTestSuiteToPersistentVolume(sfsmRef.current, state.sfsmUploadedModel, state.sfsmTestSuiteName, state.sfsmFormulaFile);
        }

        setState({
            ...state,
            storeTestSuiteModalActive: false
        })
    }

    function onStoreTestExecutionVerdictToPV() {

        if ( state.tab === TAB.FSM_TAB ) {
            StoreVerdictToPersistentVolume(fsmRef.current, state.fsmHarness, state.fsmUploadedModel, state.fsmTestSuiteName, state.fsmTestExecutionVerdictName);

            setState({
                ...state,
                storeTestVerdictModalActive: false
            })
        }
        if ( state.tab === TAB.SFSM_MBT_TAB ) {
            StoreVerdictToPersistentVolume(sfsmMbtRef.current, state.sfsmHarness, state.sfsmUploadedModel, state.sfsmTestSuiteName, state.sfsmTestExecutionVerdictName);

            setState({
                ...state,
                storeTestVerdictModalActive: false
            })
        }
    }

    function onClickExecuteHarness() {
        if ( state.tab === TAB.FSM_TAB ) {
            if ( state.fsmHarness === "" ) {
                setState({
                    ...state,
                    noHarnessModalActive: true
                })
            }
            else {
                setState({
                    ...state,
                    fsmCurrentJobType: JOB_TYPE.TEST_EXECUTION,
                    fsmHarnessExecuted: false,
                    fsmTestExecutionVerdictName: generateTestVerdictName(),
                    cancelJob: false
                });
            }
        }

        if ( state.tab === TAB.SFSM_TAB ) {
            setState({
                ...state,
                wrongTabForSfsmTcExecutionActive: true
            })
        }

        if ( state.tab === TAB.SFSM_MBT_TAB ) {
            if ( state.sfsmHarness === "" ) {
                setState({
                    ...state,
                    noHarnessModalActive: true
                })
            }
            else {
                setState({
                    ...state,
                    sfsmCurrentJobType: JOB_TYPE.TEST_EXECUTION,
                    sfsmHarnessExecuted: false,
                    sfsmTestExecutionVerdictName: generateTestVerdictName(),
                    cancelJob: false
                });
            }
        }

        if ( state.tab === TAB.POT_TAB ) {
            if ( state.potHarness === "" ) {
                setState({
                    ...state,
                    noHarnessModalActive: true
                })
            }
            else {
                setState({
                    ...state,
                    potHarnessExecuted: false,
                    potVerdictName: generateTestVerdictName(),
                    cancelJob: false
                });
            }
        }

        if ( state.tab === TAB.TEST_MANAGEMENT_TAB ) {
            setState({
                ...state,
                wrongTabTcExecutionActive: true
            })
        }
    }

    function onClickDownloadVerdict() {
        if ( state.tab === TAB.FSM_TAB ) {
            if ( ! state.fsmHarnessExecuted ) {
                showNoTestExecutionVerdictModal();
                return;
            }

            DownloadTestExecutionVerdict(fsmRef.current, state.fsmUploadedModel, state.fsmTestExecutionVerdictName);
        }

        if ( state.tab === TAB.SFSM_TAB || state.tab === TAB.SFSM_MBT_TAB ) {
            if ( ! state.sfsmHarnessExecuted) {
                showNoTestExecutionVerdictModal();
                return;
            }

            DownloadTestExecutionVerdict(sfsmMbtRef.current, state.sfsmUploadedModel, state.sfsmTestExecutionVerdictName);
        }

        if ( state.tab === TAB.TEST_MANAGEMENT_TAB ) {
            setDownloadArtifactFile({
                ...downloadArtifactFile,
                class: "Verdict",
                name: tmPageRef.current.getState().selectedVerdict,
                start: true
            })
        }

        if ( state.tab === TAB.POT_TAB ) {
            if ( state.potVerdictName === "" ) {
                showNoTestExecutionVerdictModal();
                return;
            }

            POTDownloadVerdict(potRef.current, state.potVerdictName);
        } 
    }


    // -------------------------------------------------------------
    // Modals 
    // -------------------------------------------------------------
    function showNoModelModal() {
        setState({
            ...state,
            noModelModalActive: true
        })
    }

    function hideNoModelModal() {
        setState({
            ...state,
            noModelModalActive: false
        })
    }
    
    function showNoFaultDomain() {
        setState({
            ...state,
            noFaultDomainModalActive: true
        })
    }

    function hideNoFaultDomain() {
        setState({
            ...state,
            noFaultDomainModalActive: false
        })
    }

    function showNoProperty() {
        setState({
            ...state,
            noPropertyModalActive: true
        })
    }

    function hideNoProperty() {
        setState({
            ...state,
            noPropertyModalActive: false
        })
    }

    function hideWrongTabForSfsmTcGenActive() {
        setState({
            ...state,
            wrongTabForSfsmTcGenActive: false
        })
    }

    function hideWrongTabForSfsmTcExecutionActive() {
        setState({
            ...state,
            wrongTabForSfsmTcExecutionActive: false
        })
    }
    
    function hideWrongTabTcExecutionActive() {
        setState({
            ...state,
            wrongTabTcExecutionActive: false
        })
    }

    function hideErrorToManyFilesOpen() {
        setState({
            ...state,
            errorToManyFilesOpenActive: false
        })
    }

    function showNoHarnessModal() {
        setState({
            ...state,
            noHarnessModalActive: true
        })
    }

    function hideNoHarnessModal() {
        setState({
            ...state,
            noHarnessModalActive: false
        })
    }

    function showNoTestSuiteModal() {
        setState({
            ...state,
            noTestSuiteModalActive: true
        })
    }

    function hideNoTestSuiteModal() {
        setState({
            ...state,
            noTestSuiteModalActive: false
        })
    }

    function showNoTestExecutionVerdictModal() {
        setState({
            ...state,
            noTestExecutionVerdictModalActive: true
        })
    }

    function hideNoTestExecutionVerdictModal() {
        setState({
            ...state,
            noTestExecutionVerdictModalActive: false
        })
    }

    function hideStoreTestSuiteModal() {
        setState({
            ...state,
            storeTestSuiteModalActive: false
        })
    }

    function hideStoreTestExecutionVerdictModal() {
        setState({
            ...state,
            storeTestVerdictModalActive: false
        })
    }

    function showNoTcGenerationInPotModal() {
        setState({
            ...state,
            noTcGenerationInPotPageModalActive: true
        })
    }

    function hideNoTcGenerationInPotModal() {
        setState({
            ...state,
            noTcGenerationInPotPageModalActive: false
        })
    }

    function showPotWrongFileFormatModal() {
        setState({
            ...state,
            potWrongFileFormatModalActive: true
        })
    }

    function hidePotWrongFileFormatModal() {
        setState({
            ...state,
            potWrongFileFormatModalActive: false
        })
    }

    /**
     * Return the page in JSX syntax
     */
    return (
        <>
            {/* Modal declaration to show error-messages */}
            <TestCloudModal
                show={ state.noModelModalActive }
                onClose={ hideNoModelModal }
                title="No Model uploaded or selected" 
                text="No model file was uploaded or selected. Please upload a model or select a model from the Test-Management Page first.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.noTcGenerationInPotPageModalActive }
                onClose={ hideNoTcGenerationInPotModal }
                title="No Test-Case Generation for POT-testing" 
                text="There is not Test-Generation functionality for POT - please execute a harness only!">
            </TestCloudModal>
            <TestCloudModal
                show={ state.potWrongFileFormatModalActive }
                onClose={ hidePotWrongFileFormatModal }
                title="Wrong File-Format" 
                text="Please use text-files only for fault-domain and .prop-files for properties!">
            </TestCloudModal>
            <TestCloudModal
                show={ state.noFaultDomainModalActive }
                onClose={ () => hideNoFaultDomain() }
                title="No fault domain uploaded or selected"
                text="No fault domain file was uploaded or selected. Please upload a fault domain or select a fault domain from the Test-Management Page first.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.noPropertyModalActive }
                onClose={ () => hideNoProperty() }
                title="No property uploaded or selected"
                text="No property file was uploaded or selected. Please upload a property or select a property from the Test-Management Page first.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.wrongTabForSfsmTcGenActive }
                onClose={ hideWrongTabForSfsmTcGenActive }
                title="Wrong Tab" 
                text="To Generate Tests for a SFSM, please change to Tab 'SFSM-Testing'.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.wrongTabForSfsmTcExecutionActive }
                onClose={ hideWrongTabForSfsmTcExecutionActive }
                title="Wrong Tab" 
                text="To Execute a Test-Harness for a SFSM, please change to Tab 'SFSM-MBT'.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.wrongTabTcExecutionActive }
                onClose={ hideWrongTabTcExecutionActive }
                title="Wrong Tab" 
                text="To Execute a Test-Harness, please change to Tab 'SFSM-MBT' or 'FSM-Testing'.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.errorToManyFilesOpenActive }
                onClose={ hideErrorToManyFilesOpen }
                title="Error, to many files opened." 
                text="Error: You opened to many file. Maxumum allowed: 1000.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.noHarnessModalActive }
                onClose={ hideNoHarnessModal }
                title="No Harness uploaded" 
                text="No harness file was uploaded. Please upload a harness first.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.noTestSuiteModalActive }
                onClose={ hideNoTestSuiteModal }
                title="No Test-Suite available" 
                text="No Test-Suite available, please generate one first.">
            </TestCloudModal>
            <TestCloudModal
                show={ state.noTestExecutionVerdictModalActive }
                onClose={ hideNoTestExecutionVerdictModal }
                title="No Test Execution verdict available."
                text="No verdict available: please execute a harness first.">
            </TestCloudModal>
            <TestStoreCloudModal
                show={ state.storeTestSuiteModalActive }
                onConfim={ () => onStoreTestSuiteToPV() }
                onReject={ () => hideStoreTestSuiteModal() }
                title="Store generated test suite"
                text="Do you want to store the generated test-suite on the persistent-volume?">
            </TestStoreCloudModal>
            <TestStoreCloudModal
                show={ state.storeTestVerdictModalActive }
                onConfim={ () => onStoreTestExecutionVerdictToPV() }
                onReject={ () => hideStoreTestExecutionVerdictModal() }
                title="Store Verdict of Harness Execution"
                text={"Do you want to store the Harness Execution verdict on the persistent volume?"}>
            </TestStoreCloudModal>
            

            {/* Main Content */}
            <Row>
                <Column className='col-xl-12 text-center'>
                    <Spinner 
                        className="test-cloud-spinner"
                        animation={ state.spinnerOn ? "border" : ""} 
                        size="md"
                        variant="primary"
                        role="status">
                        
                    </Spinner>
                    <Button 
                        id="buttonGetServerStatus"
                        variant="secondary"
                        disabled={ state.controlsDisabled }
                        onClick={() => {
                            GetServerStatus(fsmRef);
                            GetServerStatus(sfsmRef);
                            GetServerStatus(sfsmMbtRef);
                            GetServerStatus(potRef);
                        } }>
                        Get Server Status
                    </Button>
                    <Button 
                        id="buttonGenerateTests" 
                        disabled={ state.controlsDisabled }
                        onClick={ onClickGenerateTests } 
                        variant="primary">
                        Generate Tests
                    </Button>
                    <Button 
                        id="buttonExecuteTests" 
                        disabled={ state.controlsDisabled }
                        onClick={ () => onClickExecuteHarness() } 
                        variant="primary">
                        Execute Harness
                    </Button>
                    <Button 
                        id="buttonKillJob"
                        onClick={ () => onClickKillJob() } 
                        variant="danger">
                        Kill Job
                    </Button>
                    <Button 
                        id="buttonDownloadTestSuite" 
                        disabled={ state.controlsDisabled }
                        onClick={ () =>  onClickDownloadTestSuite() } 
                        variant="secondary">
                        Download Test-Suite
                    </Button>
                    <Button 
                        id="buttonDownloadVerdictFile" 
                        disabled={ state.controlsDisabled }
                        onClick={ () => onClickDownloadVerdict() } 
                        variant="secondary">
                        Download Verdict
                    </Button>
                </Column>
            </Row>
            <Row className="row main-tab-row">
                <Column className='col-xl-12'>
                    <TabNavBar className="nav nav-tabs" id="mainTabBar">
                        <TabButton
                            className="nav-link active"
                            id="nav-fsm-tab"
                            target="#fsm-tab-pane"
                            ariaControl="nav-fsm"
                            onClick={ () => changeTab(1) }>
                            FSM-Testing
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="nav-sfsm-tab"
                            target="#sfsm-tab-pane"
                            ariaControl="nav-sfsm"
                            onClick={ () => changeTab(2) }>
                            SFSM-Testing
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="nav-sfsm-mbt-tab"
                            target="#sfsm-mbt-tab-pane"
                            ariaControl="nav-sfsm-mbt"
                            onClick={ () => changeTab(3) }>
                            SFSM-MBT
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="nav-sfsm-pot-tab"
                            target="#sfsm-pot-tab-pane"
                            ariaControl="nav-sfsm-pot"
                            onClick={ () => changeTab(5) }>
                            POT-Testing
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="nav-tm-tab"
                            target="#tm-tab-pane"
                            ariaControl="nav-tm-tab"
                            onClick={ () => {
                                changeTab(4);
                                tmPageRef.current.setPageOpened();
                             }}>
                            Test-Management
                        </TabButton>
                    </TabNavBar>
                    
                    <TabContent id="MainTabContent">
                        <GlobalContext.Provider value={ state }>
                            <TabPane id="fsm-tab-pane" className="tab-pane active">
                                <FSMPage
                                    parseModelFiles={ processModelFiles }
                                    setFsmHarness={ setFsmHarness }
                                    setfsmModel={ setfsmModel }
                                    storeFsmTestSuiteToPv={ storeFsmTestSuiteToPv }
                                    showNoModelModal={ showNoModelModal }
                                    ref={ fsmRef }>
                                </FSMPage>
                            </TabPane>

                            <TabPane id="sfsm-tab-pane" className="tab-pane">
                                <SFSMPage
                                    parseModelFiles={ processModelFiles }
                                    setSfsmModel={ setSfsmModel }
                                    setSfsmFormulaFile={ setSfsmFormulaFile }
                                    storeSfsmTestSuiteToPv={ storeSfsmTestSuiteToPv }
                                    showNoModelModal={ showNoModelModal }
                                    ref={ sfsmRef }>
                                </SFSMPage>
                            </TabPane>

                            <TabPane id="sfsm-mbt-tab-pane" className="tab-pane">
                                <SFSMMbtPage
                                    ref={ sfsmMbtRef } 
                                    setSFsmHarness={ setSFsmHarness }>
                                </SFSMMbtPage>
                            </TabPane>

                            <TabPane id="sfsm-pot-tab-pane" className="tab-pane">
                                <POTPage
                                    showNoFaultDomainModal={ showNoFaultDomain }
                                    showNoProperty={ showNoProperty }
                                    showNoHarnessModal={ showNoHarnessModal }
                                    setPotHarness={ setPotHarness }
                                    setPotFaultDomain={ setPotFaultDomain }
                                    setPotPropertyFile={ setPotPropertyFile }
                                    showPotWrongFileFormatModal={ showPotWrongFileFormatModal }
                                    ref={potRef}>

                                </POTPage>
                            </TabPane>
                            
                            <TabPane id="tm-tab-pane" className="tab-pane">
                                <TestManagementPage
                                    ref={ tmPageRef }
                                    setModels={ setModels }
                                    setHarness={ setHarness }
                                    setTestSuite={ setTestSuite }
                                    setSfsmFormulaFile={ setSfsmFormulaFile }
                                    clearValues={ clearValues }>

                                </TestManagementPage>
                            </TabPane>
                        </GlobalContext.Provider>
                    </TabContent>
                </Column>
            </Row>
        </>
    );
}

export default TabPage;
