import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TestStoreCloudModal = ({ text, show, title, onConfim, onReject }) => {
    return (
        <Modal show={ show } onHide={ onReject }>
            <Modal.Header closeButton>
            <Modal.Title>{ title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>{ text }</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={ onConfim }>
                Store
            </Button>
            <Button variant="Secondary" onClick={ onReject }>
                Discard
            </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TestStoreCloudModal;