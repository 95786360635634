var API_PATH = 'api';
var BASE_URI = 'https://testcloud-service.informatik.uni-bremen.de/';
var FSM_TCGEN_BASE = BASE_URI + API_PATH + '/fsm-tcgen/';
var SFSM_TCGEN_BASE = BASE_URI + API_PATH + '/sfsm-tcgen/';
var HARNESS_EXE_BASE = BASE_URI + API_PATH + '/harness-executor/';
var SFSM_MODEL2SVG_BASE = BASE_URI + API_PATH + '/sfsm-model2svg/';
var FSM_MODEL2SVG_BASE = BASE_URI + API_PATH + '/fsm-model2svg/';
var POT_BASE = BASE_URI + API_PATH + '/pot/';
var NEO4J_URL = 'https://testcloud-fileserver.informatik.uni-bremen.de/db/neo4j/tx';
var NEO4J_TOKEN = "bmVvNGo6bkF0SEVtYVI=";

const getFsmTcgenBaseUri = () => {
    return FSM_TCGEN_BASE;
}

const getSFsmTcgenBaseUri = () => {
    return SFSM_TCGEN_BASE;
}

const getHarnessExeBaseUri = () => {
    return HARNESS_EXE_BASE;
}

const getSfsmModelToSvgBaseUri = () => {
    return SFSM_MODEL2SVG_BASE;
}

const getFsmModelToSvgBaseUri = () => {
    return FSM_MODEL2SVG_BASE;
}

const getNeo4JUrl = () => {
    return NEO4J_URL;
}

const getNeo4JToken = () => {
    return NEO4J_TOKEN;
}

const getPotUrl = () => {
    return POT_BASE;
}

export { getFsmTcgenBaseUri, getPotUrl, getSFsmTcgenBaseUri, getHarnessExeBaseUri, getSfsmModelToSvgBaseUri, getNeo4JUrl, getNeo4JToken, getFsmModelToSvgBaseUri }