import React from "react"

const Row = ({className, style, children}) => {
    return (
        <div className={ className ? className : "row" } style={ style }>
            { children }
        </div>
    );
};

export default Row;