import "./style.css"
import React, { forwardRef, useState, useImperativeHandle, useContext, useEffect }  from "react";
import Column from "../Components/Column";
import Row from "../Components/Row";
import SFSMParametersPage from "./SFSMParametersPage";
import SFSMCenterPage from "./SFSMCenterPage";
import SFSMFileUploadPage from "./SFSMFileUploadPage";

import { SFsmContext } from "./Context";
import { getDateString, getLogText } from "../Actions/Utils";
import { getNameOfFile } from "../Utils";
import Button from "../Components/Button";
import { GlobalContext } from "../GlobalContext";
import Card from "../Components/Card";
import { getSfsmModelToSvgBaseUri } from "../Actions/Configuration";
import Spinner from "react-bootstrap/esm/Spinner";

function SFSMPage(props, ref) {

    const globalContext = useContext(GlobalContext);

    var theTimer = 0;

    /** 
     * Expose the page-state and 
     * the function to add text to the log
     * to the layer above
     */ 
    useImperativeHandle(ref, 
        () => ({
            /**
             * Function to return the current page state
             */
            getState: () => {
                return state;
            },

            /**
             * Function to add a text to the log.
             * (change page state from DOM node above)
             */
            addLog: async (text) => {
                let x = getLogText(state.log, text);

                setState({
                    ...state,
                    log: x
                })
            }
        })
    );

    /**
     * The main state-object of the FSM Page
     */
    const [state, setState] = useState({
        log: "",
        additionalStates: "0",
        formulas: []
    })

    /**
     * State for generating the SVG file
     */
    const [svgFileState, setSvgFileState] = useState({
        modelName: "",
        start: false,
        finished: false
    });

    useEffect(() => {
        if ( svgFileState.start ) {
            generateSvgFile();
        }
    }, [svgFileState.start]);   // eslint-disable-line react-hooks/exhaustive-deps


    /**
     * Get status of SVG file generation
     */
    function getStatus() {
        fetch(getSfsmModelToSvgBaseUri() + globalContext.sfsmUploadedModel + "/status", {
            method: 'GET'
        })
        .then(response => response.json())
        .then(data => {
            if ( data.terminated ) {
                clearTimeout(theTimer);

                fetch(getSfsmModelToSvgBaseUri() + globalContext.sfsmUploadedModel + "/svg-file", {
                    method: 'GET'
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.terminated ) {
                        clearTimeout(theTimer);
                        var sfsm_svg = document.getElementById('sfsm_svg');
                        var svgString = data.message;
                        var decoded = unescape(encodeURIComponent(svgString));
                        var base64 = btoa(decoded);
                        var imgSource = `data:image/svg+xml;base64,${base64}`;
                        sfsm_svg.src = imgSource;
                        sfsm_svg.style = "width: 100%; height: 100%;";
                        
                        addLog("Picture of Model '" + globalContext.sfsmUploadedModel + "' created. Picture is shown below.");
                        setSvgFileState({
                            ...svgFileState,
                            start: false,
                            finished: true,
                            spinnerOn: false
                        })
                    }
                    else {
                        addLog(data.message);
                    }
            
                })
                .catch(error => {
                    addLog(error);
                    setSvgFileState({
                        ...svgFileState,
                        start: false,
                        finished: true,
                        spinnerOn: false
                    })
                });
            }
            else {
                addLog(data.message);
            }

        })
        .catch(error => {
            addLog(error);
            setSvgFileState({
                ...svgFileState,
                start: false,
                finished: true,
                spinnerOn: false
            })
        });
    }

    /**
     * Invoke SVG-File generation.
     */
    function generateSvgFile() {
        fetch(getSfsmModelToSvgBaseUri() + globalContext.sfsmUploadedModel + "/generate", {
            method: 'POST'
        })
        .then(response => response.json())
        .then(data => {
            addLog(data.message);
            theTimer = setInterval(getStatus, 500);
        })
        .catch(error => {
            addLog(error);
        });
    }

    /**
     * Adds a text to the log-output
     */
    function addLog(text) {
        let x = getLogText(state.log, text);

        setState({
            ...state, 
            log: x
        })
    }

    /**
     * Clears the log
     */
    function clearLog() {
        setState({
            ...state,
            log: ""
        })
    }

    /**
     * Sets the Model name of the uploaded model
     */
    function setModel(modelName, text) {
        let x = getLogText(state.log, text);

        props.setSfsmModel(modelName);

        setState({
            ...state,
            log: x
        })
    }

    /**
     * Setter for formula file
     */
    function setFormulaFile(formulaFile, text) {
        let x = getLogText(state.log, text);

        if ( text === "" ) {
            setState({
                ...state,
            })
    
        }
        else {
            setState({
                ...state,
                log: x
            })   
        }

        props.setSfsmFormulaFile(formulaFile);
    }

    /**
     * Setter for additional states
     */
    function setAdditionalStates(states) {
        setState({
            ...state,
            additionalStates: states
        })
    }

    /**
     * Adds a formula file to memory
     */
    function addFormulaFile(name, content) {
        var uniqueName = name + "-" + getDateString();
        uniqueName = uniqueName.replaceAll('/', '-').replaceAll(',', '').replaceAll(' ', '_').replaceAll(':', '-').replaceAll('\n', '');

        state.formulas.forEach((elem) => {
            if ( elem.name === name ) {
                let errorText = "Error, formula with name " + name + " already exists.";
                let x = getLogText(state.log, errorText);
                setState({
                    ...state,
                    log: x
                })
                return;
            }
        });

        state.formulas.push({ name: name, uniqueName: uniqueName, content: content, checked: false });

        setState({
            ...state
        })
    }

    /**
     * Processes a formula file
     */
    function processFormulaFile(name, fileHandle, callback) {
        const reader = new FileReader();
        reader.readAsText(fileHandle);
        reader.onload = () => {
            callback(name, reader.result);
        };
    }

    /**
     * Parse all formula files
     */
    function parseFormulaFiles(files) {
        if ( files.length > 1000 ) {
            console.log("Error, to many files open");
            return;
        }

        files.forEach((elem) => {
            let name = getNameOfFile(elem.name);
            processFormulaFile(name, elem, addFormulaFile);
        });
    }

    /**
     * Returnes the page-state in JSX Syntax
     */
    return (
        <div className="SFSMPage">
            <SFsmContext.Provider value={ state }>
                <Row>
                    <Column className="col-md-3">
                        {/* The left column shows the parameters of the TC-Generation */}
                        <SFSMParametersPage 
                            setAdditionalStates={ setAdditionalStates }
                            storeSfsmTestSuiteToPv={ props.storeSfsmTestSuiteToPv }
                            setFormulaFile={ setFormulaFile }>
                        </SFSMParametersPage>
                    </Column>
                    
                    <Column className="col-md-6">
                        {/* The center column is for showing the output */}
                        <SFSMCenterPage
                            clearLog = { clearLog }>
                        </SFSMCenterPage>
                    </Column>

                    <Column className="col-md-3">
                        {/* The right column is for uplaoding files */}
                        <SFSMFileUploadPage
                            addLog={ addLog }
                            setModel={ setModel }
                            parseFormulaFiles={ parseFormulaFiles }
                            parseModelFiles={ props.parseModelFiles }
                            setFormula={ setFormulaFile }>
                        </SFSMFileUploadPage>
                    </Column>
                </Row>
                <Row className="row show-picture-row">
                    <Column className="col-md-12">
                        <Card headerName="Show Picture of the S-FSM">
                            <Button
                                bootstrapClass="btn btn-primary btn-generate-picture"
                                onClick={() => {
                                        if ( globalContext.sfsmUploadedModel === "" ) {
                                            props.showNoModelModal();
                                        }
                                        else {
                                            setSvgFileState({
                                                ...svgFileState,
                                                start: true,
                                                finished: false,
                                                spinnerOn: true
                                            })
                                        }
                                    }
                                }>
                                Generate Picture
                            </Button>
                            <Spinner 
                                className="sfsm-spinner"
                                animation={ svgFileState.spinnerOn ? "border" : ""} 
                                size="md"
                                variant="primary"
                                role="status" />

                            <div id="svgContainer">
                                <img id="sfsm_svg" src="" alt=""></img>
                            </div>
                        </Card>
                    </Column>
                </Row>
            </SFsmContext.Provider>
        </div>
    );
}


export default forwardRef(SFSMPage);
