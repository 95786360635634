import { getPotUrl } from "./Configuration";

var theTimer = 0;

function getStatus(pageReference, testExecutionVerdictName, enableControls, setHarnessExecuted) {
    fetch(getPotUrl() + testExecutionVerdictName + "/status", {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        if ( data.terminated ) {
            clearTimeout(theTimer);
            enableControls();
            setHarnessExecuted();
        } 

        pageReference.addLog(data.message);
    })
    .catch(error => {
        pageReference.addLog(error);
        enableControls();
    });
}

function POTExecution(pageReference, faultDomainName, harnessName, testExecutionVerdictName, propertyName, disableControls, enableControls, setHarnessExecuted) {
    // call fetch to POST FSM-Harness-Execution
    fetch(getPotUrl() 
          + faultDomainName 
          + '/execute/' 
          + harnessName
          + '/' + propertyName
          + '/' + testExecutionVerdictName, {
        method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
        // if we received a valid job_id, than the job was accepted
        if ( ! data.error ) {
            pageReference.addLog(data.message);
            disableControls();
            theTimer = setInterval(getStatus, 500, pageReference, testExecutionVerdictName, enableControls, setHarnessExecuted);
        } else {
            // An error occurred
            enableControls();
            pageReference.addLog(data.message);
        }
    })
    .catch(error => {
        pageReference.addLog(error);
        enableControls();
    });

}

function CancelPotExecutionJob(pageReference, testExecutionVerdictName) {
    // POST a cancel-job resource
    fetch(getPotUrl() + testExecutionVerdictName + "/cancel", {
        method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
        if ( ! data.error && data.terminated ) {
            pageReference.addLog(data.message);
        }
    })
    .catch(error => {
        pageReference.addLog(error)
    });

    clearTimeout(theTimer);
}

function POTDownloadVerdict(potPageRef, verdictName) {
    fetch(getPotUrl() + verdictName + "/verdict", {
        mathod: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        if ( ! data.error ) {
            if ( data.message === "" ) {
                potPageRef.addLog("Error: Verdict does not exist!");
            } else {
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(String(data.message)));
                element.setAttribute('download', verdictName + "_verdict.txt");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        } else {
            potPageRef.addLog(data.message);
        }
    })
    .catch(error => potPageRef.addLog(error));
}

// Export functions
export { POTExecution, CancelPotExecutionJob, POTDownloadVerdict }