import "./style.css"
import React, { forwardRef, useState, useImperativeHandle }  from "react";
import Column from "../Components/Column";
import Row from "../Components/Row";
import SFSMMbtParametersPage from "./SFSMMbtParametersPage";
import SFSMMbtCenterPage from "./SFSMMbtCenterPage";
import SFSMMbtFileUploadPage from "./SFSMMbtFileUploadPage";

import { SFsmMbtContext } from "./Context";
import { getLogText } from "../Actions/Utils";

function SFSMMbtPage(props, ref) {

    /** 
     * Expose the page-state and 
     * the function to add text to the log
     * to the layer above
     */ 
    useImperativeHandle(ref, 
        () => ({
            /**
             * Function to return the current page state
             */
            getState: () => {
                return state;
            },

            /**
             * Function to add a text to the log.
             * (change page state from DOM node above)
             */
            addLog: async (text) => {
                let x = getLogText(state.log, text);
                
                setState({
                    ...state,
                    log: x
                })
            }
        })
    );

    /**
     * The main state-object of the FSM Page
     */
    const [state, setState] = useState({
        log: "",
    })
    /**
     * Adds a text to the log-output
     */
    function addLog(text) {
        let x = getLogText(state.log, text);

        setState({
            ...state, 
            log: x
        })
    }
    
    /**
     * Clears the log
     */
    function clearLog() {
        setState({
            ...state,
            log: ""
        })
    }

    /**
     * Sets the name of the uploaded Harness
     */
    function setHarness(harnessName) {
        if ( harnessName.includes("error") ) {
            let x = getLogText(state.log, ("Error setting harness: error during harness upload: " + harnessName) );
            setState({
                ...state,
                uploadedHarness: "error",
                log: x
            })

            props.setSFsmHarness("error");
            return;
        }

        let x = getLogText(state.log, "Using harness '" + harnessName + "'");

        setState({
            ...state,
            uploadedHarness: harnessName,
            log: x
        })

        props.setSFsmHarness(harnessName);
    }

    /**
     * Returnes the page-state in JSX Syntax
     */
    return (
        <div className="SFSMMbtPage">
            <SFsmMbtContext.Provider value={ state }>
                <Row>
                    <Column className="col-md-3">
                        {/* The left column shows the parameters of the TC-Generation */}
                        <SFSMMbtParametersPage>
                        </SFSMMbtParametersPage>
                    </Column>
                    
                    <Column className="col-md-6">
                        {/* The center column is for showing the output */}
                        <SFSMMbtCenterPage
                            clearLog = { clearLog }>
                        </SFSMMbtCenterPage>
                    </Column>

                    <Column className="col-md-3">
                        {/* The right column is for uplaoding files */}
                        <SFSMMbtFileUploadPage
                            setHarness={ setHarness }
                            addLog={addLog}>
                        </SFSMMbtFileUploadPage>
                    </Column>
                </Row>
            </SFsmMbtContext.Provider>
        </div>
    );
}


export default forwardRef(SFSMMbtPage);
